import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import "../../App.css";
import {
  Container,
  MenuItem,
  Menu,
  Button,
  Typography,
  Box,
  Link,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import { faChevronDown, faTrash, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddMacrosDialogComponent from "./AddMacrosDialogComponent";

export default function BasicProjectInformationComponent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          padding: 0,
        }}
        style={{ padding: 0, marginTop: 30 }}
      >
        <Box style={{ width: "33.3%", marginRight: 20 }}>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ marginBottom: 20 }}
            variant="contained"
          >
            Church Captioning
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ marginLeft: 5 }}
            ></FontAwesomeIcon>
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleClose}>Church Captioning</MenuItem>
          </Menu>
          <Typography variant="body1" style={{ marginTop: 15 }}>
            BASIC PROJECT INFORMATION
          </Typography>
          <FormControlLabel
            control={<Switch />}
            label="Rush:"
            labelPlacement="start"
            style={{
              minWidth: 400,
              margin: 0,
              alignItems: "center",
              justifyContent: "start",
            }}
          />
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Split Audio:
          </Typography>
          <Typography variant="body2">No</Typography>
          <FormControlLabel
            control={<Switch />}
            label="Auto-assign:"
            labelPlacement="start"
            style={{
              minWidth: 400,
              margin: 0,
              alignItems: "center",
              justifyContent: "start",
            }}
          />
          <FormControlLabel
            control={<Switch />}
            label="Automatically split and assign files droped in the File manager:"
            labelPlacement="start"
            style={{
              minWidth: 400,
              margin: 0,
              alignItems: "center",
              justifyContent: "start",
            }}
          />
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Number of people to auto-assign:
          </Typography>
          <Typography variant="body2">1</Typography>
          <Divider></Divider>
          <FormControlLabel
            control={<Switch />}
            label="US Transcribers Only:"
            labelPlacement="start"
            style={{
              minWidth: 400,
              margin: 0,
              alignItems: "center",
              justifyContent: "start",
            }}
          />
          <FormControlLabel
            control={<Switch />}
            label="Checked Transcribers Only:"
            labelPlacement="start"
            style={{
              minWidth: 400,
              margin: 0,
              alignItems: "center",
              justifyContent: "start",
            }}
          />
          <FormControlLabel
            control={<Switch defaultChecked />}
            label="Transcribe within TOPS:"
            labelPlacement="start"
            style={{
              minWidth: 400,
              margin: 0,
              alignItems: "center",
              justifyContent: "start",
            }}
          />
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Transcriber rate/minute:
          </Typography>
          <Typography variant="body2">$0.50/min</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Billing rounding:
          </Typography>
          <Typography variant="body2">Not set</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Automatically Reassign:
          </Typography>
          <Typography variant="body2">No</Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: 800, marginBottom: 20, marginTop: 100 }}
          >
            OUTPUT INFORMATION:
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Location:
          </Typography>
          <Typography variant="body2">Not set</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Word Template:
          </Typography>
          <Link>
            BareTemplate
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faTrash} />
          </Link>
          <Typography
            variant="body1"
            style={{ fontWeight: 800, marginBottom: 0, marginTop: 100 }}
          >
            Macros:
          </Typography>
          <AddMacrosDialogComponent />
        </Box>
        <Box style={{ width: "33.3%" }}>
          <Typography
            variant="body1"
            style={{ fontWeight: 800, marginBottom: 20 }}
          >
            Billable Hours:
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            Contact Information
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Primary Contact:
          </Typography>
          <Typography variant="body2">Ron Snipes</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            End Clien Contact:
          </Typography>
          <Typography variant="body2">Ron Snipes</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Send Invoice To:
          </Typography>
          <Typography variant="body2">jetranger3@suddenlink.net</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Send Transcript To:
          </Typography>
          <Typography variant="body2">jetranger3@suddenlink.net</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            CC:
          </Typography>
          <Typography variant="body2">No Set</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            PO Number:
          </Typography>
          <Typography variant="body2">No Set</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Users in project:
          </Typography>
          <Link>Add User To Project</Link>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Date:
          </Typography>
          <Typography variant="body2">No Set</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Attachments:
          </Typography>
          <Typography variant="body2">
            This project does not have any attachments. Drag and drop files here
            to attach.
          </Typography>
        </Box>
        <Box style={{ width: "33.3%" }}>
          <Typography
            variant="body1"
            style={{ fontWeight: 800, marginBottom: 20 }}
          >
            Start Date:<Typography variant="caption"> 08/01/2022</Typography>
          </Typography>
          <Typography
            variant="body1"
            style={{ fontWeight: 800, marginBottom: 20 }}
          >
            Notes To Project Manager:
          </Typography>
          <p style={{ border: "1px dashed", padding: 10 }}>
            This is Embedded Captioning and Transcription each week for a 30
            minute video. Video provided via emailed WeTransfer Link.
          </p>
          <p style={{ border: "1px dashed", padding: 10 }}>
            In an effort to keep content in a regional base we have a new direct
            link for uploading. We have moved our site for uploading commercials
            and content for WCTI and WYDO TV. Please note that the other ftp is
            not in service anymore. Please use the following link and
            instructions for uploading and submitting confirmations that your
            upload is complete to our traffic dept. Please go to:
            https://bristol-sbgtv.mediashuttle.com Please make sure your
            filename is in the format: (description hyphen todays date) example:
            samplespot-10272021.mpg You will then receive an email from the
            system when complete. Please FORWARD THAT EMAIL TO
            SASO-WCTI-Traffic@sbgtv.com (otherwise we do not know about your
            upload)
          </p>
          <Typography
            variant="body1"
            style={{ fontWeight: 800, marginBottom: 20 }}
          >
            Notes To Transcriber:
          </Typography>
          <p style={{ border: "1px dashed", padding: 10 }}>
            Do not transcribe the singing or music. Please use [SINGING] and
            [MUSIC] tags.
          </p>
          <p style={{ border: "1px dashed", padding: 10 }}>
            If the audience/congregation responds to the pastor in unison,
            please place this speech on it's own line with the ID tag RESPONSE:
          </p>
        </Box>
      </Container>
      <Typography
        variant="body1"
        style={{ fontWeight: 800, marginBottom: 20, marginTop: 50 }}
      >
        ATTRIBUTES
      </Typography>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "start",
          padding: 0,
        }}
        style={{ padding: 0, marginTop: 30 }}
      >
        <Container style={{
          border: "1px solid",
          width: 'auto',
          margin: 0,
          backgroundColor: "#eee",
          paddingLeft: '5px',
          paddingRight: 5,
          marginRight: 10
        }}
        color="primary"
        >
          Captioning
          <FontAwesomeIcon style={{marginLeft: 10}} icon={faClose}/>
        </Container>
        <Container style={{
          border: "1px solid",
          width: 'auto',
          margin: 0,
          backgroundColor: "#eee",
          paddingLeft: '5px',
          paddingRight: 5,
          marginRight: 10
        }}
        color="primary"
        >
          Please read ALL TOPS COMMENTS
          <FontAwesomeIcon style={{marginLeft: 10}} icon={faClose}/>
        </Container>
        <Container style={{
          border: "1px solid",
          width: 'auto',
          margin: 0,
          backgroundColor: "#eee",
          paddingLeft: '5px',
          paddingRight: 5,
          marginRight: 10
        }}
        color="primary"
        >
          True Verbatim
          <FontAwesomeIcon style={{marginLeft: 10}} icon={faClose}/>
        </Container>
        <Container style={{
          border: "1px solid",
          width: 'auto',
          margin: 0,
          backgroundColor: "#eee",
          paddingLeft: '5px',
          paddingRight: 5,
          marginRight: 10
        }}
        color="primary"
        >
          Please timestamp all inaudible and crosstalk notations
          <FontAwesomeIcon style={{marginLeft: 10}} icon={faClose}/>
        </Container>
      </Container>
      <Typography variant="body1" style={{ fontWeight: 600, marginTop: 20 }}>
            Project Status:
          </Typography>
          <Typography variant="body2">Estimated Files: 4</Typography>
          <Typography variant="body2">Received Files: 2</Typography>
    </ThemeProvider>
  );
}
