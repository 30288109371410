import React from "react";
import { IconButton } from "@mui/material";
import "../App.css";
import ClearIcon from "@mui/icons-material/Clear";
import EmailInput from "../components/EmailInput";
import LastNameInput from "./LastNameInput";
import FirstNameInput from "./FirstNameInput";
import theme from "../theme";

const inputStyle = {
  marginTop: theme.spacing(3),
  position: "relative",
  borderRadius: "4px",
  backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
  borderBottom: "none",
  border: "1px solid #D3D3D3",
  fontSize: 18,
  padding: "5px 6px",
  height: "40px",

  "&::before": {
    display: "none",
  },
};

function FormUser(props) {
  const { index, data, removeItem, handleChange } = props;

  return (
    <div className="inviteUserForm">
      <FirstNameInput
        name={data.first_name}
        index={index}
        handleChange={handleChange}
        inputStyle={inputStyle}
      />

      <LastNameInput
        name={data.last_name}
        index={index}
        handleChange={handleChange}
        inputStyle={inputStyle}
      />

      <EmailInput
        email={data.email}
        handleChange={handleChange}
        index={index}
        inputStyle={inputStyle}
      />

      {index > 0 ? (
        <IconButton
          sx={{
            height: "30px",
            width: "30px",
            marginTop: "6%",
            marginBottom: "auto",
          }}
          onClick={() => removeItem(index)}
        >
          <ClearIcon />
        </IconButton>
      ) : (
        <IconButton>
          <ClearIcon sx={{ color: "transparent" }} />
        </IconButton>
      )}
    </div>
  );
}

export default React.memo(FormUser);
