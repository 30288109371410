import React from "react";
import "../index.css";
import App from "../App";
import Login from "../views/Login";
import ProjectDetail from "../views/ProjectDetail";
import TranscriberQueue from "../views/TranscriberQueue";
import ProjectQA from "../views/ProjectQA";
import FileManagement from "../views/FileManagement";
import Dashboard from "../views/Dashboard";
import People from "../views/People";
import Clients from "../views/Clients";
import Reports from "../views/Reports";
import Unit from "../views/Unit";
import Knowledge from "../views/Knowledge";
import { Routes, Route } from "react-router-dom";
import OneBoard from "../views/OneBoard";
import Profile from "../views/Profile";
import AdminPMPrivateRoute from "./AdminPMPrivateRoute";
import QATranscriberAdminPMPrivateRoute from "./QATranscriberAdminPMPrivateRoute";
import ClientAdminPMPrivateRoute from "./ClientAdminPMPrivateRoute";
import TranscriberClientPrivateRoute from "./TranscriberClientPrivateRoute";
import ClientPrivateRoute from "./ClientPrivateRoute";
import TranscriberPrivateRoute from "./TranscriberPrivateRoute";
import QAPrivateRoute from "./QAPrivateRoute";
import LoggedRoutes from "./LoggedRoutes";
import { useEffect, useLayoutEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import UploadFiles from "../views/uploadFiles";
import SearchFiles from "../views/SearchFiles";
import OrderHistory from "../views/OrderHistory";
import InviteTeammates from "../views/InviteTeammates";
import TransactionHistory from "../views/TransactionHistory";
import PaymentDetails from "../views/PaymentDetails";

import MyAccount from "../views/MyAccount";
import AccountInvitation from "../views/MyFiles/AccountInvitation";
import PrivacyPolicy from "../views/PrivacyPolicy";
import TermsOfService from "../views/TermsOfService";
import ClientBasicInformation from "../views/ClientBasicInformation";
const PURE_CHAT_DOM_ID = "PureChatWidget";

export default function AppRoutes() {
  const { getUser, user, clearUser } = useZustant();

  const [pureChatSession, setPureChatSession] = useState(false);

  const [pureChatDOMObject, setPureChatDOMObject] = useState(null);

  useEffect(() => {
    loadPureChat();
    const waitForUser = async () => {
      await getUser();
    };
    waitForUser();
  }, []);

  const checkIfUserCanUseChat = () => user && ((user.rolename === "QA" ) || user.rolename === "Transcriber");

  const togglePureChatVisibility = () => {
    let display = !checkIfUserCanUseChat() ? "none" : "block";

    if (pureChatDOMObject) pureChatDOMObject.style.display = display;
  };

  const initializePureChat = () => {
    const pureChat = document.getElementById(PURE_CHAT_DOM_ID);
    setPureChatDOMObject(pureChat);
    togglePureChatVisibility();

    if (checkIfUserCanUseChat()) {
      if (!pureChatSession) loadPureChatUserData();
      togglePureChatVisibility();
    }
  };

  function loadPureChatUserData() {
    if (user && typeof window.purechatApi.set === "function") {
      window.purechatApi.set(
        "visitor.name",
        user.firstname + " " + user.lastname
      );
      window.purechatApi.set("visitor.firstName", user.firstname);
      window.purechatApi.set("visitor.lastName", user.lastname);
      window.purechatApi.set("visitor.email", user.mail);
    }
  }

  const loadPureChat = () => {
    window.purechatApi.on("chatbox:ready", () => {
      initializePureChat();
    });

    window.purechatApi.on("chat:start", () => {
      setPureChatSession(true);
      togglePureChatVisibility();
    });

    window.purechatApi.on("chat:end", () => {
      setPureChatSession(false);
      loadPureChatUserData();
      togglePureChatVisibility();
    });

    window.purechatApi.on("chatbox:restart", () => {
      initializePureChat();
    });
  };

  useEffect(() => {
    initializePureChat();
  }, [user, pureChatDOMObject, pureChatSession]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <LoggedRoutes>
            <Login />
          </LoggedRoutes>
        }
      />
      <Route
        path="/account/auth/login/accountInvitationToken=:accountInvitationToken"
        element={<AccountInvitation />}
      />
      <Route
        path="/account/auth/register"
        element={<AccountInvitation />}
      />
      <Route path="/clients/legal/privacy" element={<PrivacyPolicy />}/>
      <Route path="/clients/legal/terms" element={<TermsOfService />}/>
      <Route path="/uploadFiles" element={<UploadFiles />} />
      <Route
        path="/"
        element={
          <AdminPMPrivateRoute>
            <App />
          </AdminPMPrivateRoute>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/people" element={<People />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/projects/:idProject" element={<ProjectDetail />} />
      </Route>
      <Route
        path="/"
        element={
          <QATranscriberAdminPMPrivateRoute>
            <App />
          </QATranscriberAdminPMPrivateRoute>
        }
      >
        <Route path="/knowledge" element={<Knowledge />} />
        <Route
          path="/projects/:idProject/transcript/:idTranscript/unit/:idUnit"
          element={<Unit />}
        />
      </Route>
      <Route
        path="/"
        element={
          <ClientAdminPMPrivateRoute>
            <App />
          </ClientAdminPMPrivateRoute>
        }
      >
        <Route path="/filemanagement" element={<FileManagement />} />
        
        <Route path="/searchfiles" element={<SearchFiles />} />
        <Route path="/orderhistory" element={<OrderHistory />} />
        <Route path="/inviteteammates" element={<InviteTeammates />} />
        <Route path="/transactionhistory" element={<TransactionHistory />} />
        <Route path="/paymentdetails" element={<PaymentDetails />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/accountdetails" element={<ClientBasicInformation />} />
      </Route>

      <Route
        path="/"
        element={
          <TranscriberClientPrivateRoute>
            <App />
          </TranscriberClientPrivateRoute>
        }
      >
        <Route path="/profile/:idUser" element={<Profile />} />
      </Route>
      <Route
        path="/"
        element={
          <ClientPrivateRoute>
            <App />
          </ClientPrivateRoute>
        }
      >
        <Route path="/clientDashboard" element={<OneBoard />} />
      </Route>
      <Route
        path="/"
        element={
          <TranscriberPrivateRoute>
            <App />
          </TranscriberPrivateRoute>
        }
      >
        <Route path="/projects/queue/:idUser" element={<TranscriberQueue />} />
        <Route path="/mywork/:idUser" element={<TranscriberQueue />} />
      </Route>
      <Route
        path="/"
        element={
          <QAPrivateRoute>
            <App />
          </QAPrivateRoute>
        }
      >
        <Route path="/projects/qa" element={<ProjectQA />} />
      </Route>
    </Routes>
  );
}
