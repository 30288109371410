import { React, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import ProjectActionsComponent from "../components/project/ProjectActionsComponent";
import ProjectSummaryComponent from "../components/project/ProjectSummaryComponent";
import BasicProjectInformationComponent from "../components/project/BasicProjectInformationComponent";
import ProjectInvoicePreviewComponent from "../components/project/ProjectInvoicePreviewComponent";
import theme from "../theme";
import { Card, Divider, Box } from "@mui/material";

export default function ProjectDetail() {
  useEffect(() => {
    document.title = "Project Details - Focus Forward Tops";
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <ProjectActionsComponent />
      <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
        <Card component="main">
          <ProjectSummaryComponent />
          <Divider />
          <ProjectInvoicePreviewComponent />
          <BasicProjectInformationComponent />
        </Card>
      </Box>
    </ThemeProvider>
  );
}
