import { React, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import {
  Card,
  Chip,
  Grid,
  Typography,
  Container,
  Link,
  Input,
  Tooltip,
  Button,
} from "@mui/material";
import useShow from "../../hooks/useShow";
import EditIcon from '@mui/icons-material/Edit';
import CustomSnackBarAlert from "../global/CustomSnackBarAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../utils";
import moment from "moment";
import Requests from "../../logic/Requests";

export default function UnitDetails(props) {
  const { user,data, unit, transcriberNotes, rejectedReasons } = props.data;
  const [showDueDateEditor, setShowDueDateEditor] = useState(false);
  const [due, setDue] = useState(null);
  const [dueTime, setDueTime] = useState(null);
  const [changedDueTime, setChangedDueTime] = useState(null);
  const [alertType, setAlertType] = useState("error");
  const [alertContent, setAlertContent] = useState("");
  const { show: showAlert, open: openAlert, close: closeAlert } = useShow();

  function download(event) {
    event.preventDefault();
    event.stopPropagation();
    let id = event.target.getAttribute("data-id");
    const url = Requests.getFileURL(id);
    window.location = url;
    return false;
  }
  const handleAlert = (type, content) => {
    setAlertType(type);
    setAlertContent(content);
    openAlert();
  };

  async function handleSaveUnitDueDate() {
    try {
      const trueDue = due
        ? due
        : moment(unit.assignments[0].due).format("YYYY-MM-DD");
        
      const trueDueTime = dueTime
        ? dueTime
        : moment(unit.assignments[0].due).format("HH:mm");
        const dueDate = moment(`${trueDue} ${trueDueTime}`).utc().format();

      let doc = {
        due: dueDate,
      };
      const res = await Requests.saveUnitDueDate(unit._id, doc);
      setChangedDueTime(dueDate);
      setShowDueDateEditor(false);
      handleAlert("success", "Due date changed.")
    } catch (err) {
      handleAlert("error", "Error changing due date.");
    }
  }
  return (
    <ThemeProvider theme={theme}>
        <CustomSnackBarAlert show={showAlert} close={closeAlert} type={alertType}>
        {alertContent}
      </CustomSnackBarAlert>
      <Container
        style={{
          paddingTop: 0,
          marginRight: 10,
          marginLeft: 10,
          marginBottom: 30,
        }}
      >
        <div className="unitNameContainer">
          <h3>Unit - {unit._id.slice(-8)}</h3>
        </div>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: 15 }}
        >
          <Grid item md={3} lg={6} xs={12}>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Audio Length
            </Typography>
            <Typography variant="body3">
              {Utils.numberToSeconds(unit.length)}
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, marginTop: 10 }}
            >
              Research Type
            </Typography>
            <Typography variant="body3">{data.research_type}</Typography>
          </Grid>
          <Grid item md={3} lg={6} xs={12}>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Due (Local Time)
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body3">
                {changedDueTime
                  ?  moment(changedDueTime).format("MM/DD/YYYY hh:mm a")
                  : unit.assignments[0].due
                  ? moment(unit.assignments[0].due).format("MM/DD/YYYY hh:mm a")
                  : ""}
              </Typography>
              {(user.rolename !== "Transcriber" && user.rolename !== "QA") && (
                <div style={{ position: "relative" }}>
                  <Tooltip
                    title="Edit due date & time"
                    style={{ borderRadius: "100px" }}
                  >
                    <Button
                      onClick={() => setShowDueDateEditor(!showDueDateEditor)}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  {showDueDateEditor && (
                    <div className="editDueTimeContainer">
                      <Typography variant="body2" style={{ fontWeight: 600 }}>
                        Edit Due (Local Time)
                      </Typography>
                      <div className="editDueTimeSubContainer">
                        <span>Date:</span>
                        <Input
                          type="date"
                          onChange={(e) => setDue(e.target.value)}
                          value={
                            due
                              ? due
                              : moment(unit.assignments[0].due)
                                  .format("YYYY-MM-DD")
                                  .toString()
                          }
                        />
                      </div>
                      <div className="editDueTimeSubContainer">
                        <span>Time:</span>
                        <Input
                          type="time"
                          onChange={(e) => setDueTime(e.target.value)}
                          value={
                            dueTime
                              ? dueTime
                              : moment(unit.assignments[0].due).format("HH:mm")
                          }
                        />
                      </div>

                      <div className="editDueTimeSubContainer">
                        <Button
                          color="red"
                          onClick={() => setShowDueDateEditor(false)}
                          sx={{ color: "white !important" }}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSaveUnitDueDate}
                          sx={{ color: "white !important" }}
                          variant="contained"
                          color="success"
                          // disabled={disabled}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Typography
              variant="body2"
              style={{ fontWeight: 600, marginTop: 10 }}
            >
              Timestamps
            </Typography>
            <Typography variant="body3">{data.timestamps ?? "None"}</Typography>
          </Grid>
          <Grid item md={3} lg={6} xs={12}>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
            Transcription Format
            </Typography>
            <Typography variant="body3">{data.format}</Typography>
          </Grid>
          <Grid item md={3} lg={6} xs={12}>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Language
            </Typography>
            <Typography variant="body3">{data.type}</Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <h5 style={{ marginBottom: 10, marginTop: 10 }}>Attributes</h5>
            {data.tags.map((data, key) => {
              return (
                <Chip
                  key={key}
                  label={data.text}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 10, marginBottom: 10 }}
                />
              );
            })}
          </Grid>
          <Grid item lg={6} xs={12}>
            {data.project.attachments.length > 0 ? (
              <Container
                style={{
                  padding: 15,
                  border: "2px dashed #1A336E",
                  marginTop: 10,
                }}
              >
                {data.project.attachments.map((data, key) => {
                  return (
                    <div key={key}>
                      <Link
                        to="#"
                        onClick={download}
                        data-id={data.id}
                        data-name={data.filename}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          style={{
                            color: "#64E83E",
                            fontSize: 20,
                            marginTop: 10,
                            marginRight: 10,
                          }}
                        />{" "}
                        {data.filename}
                      </Link>
                      <br></br>
                    </div>
                  );
                })}
              </Container>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid item lg={6} xs={12} style={{ marginTop: 30 }}>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Notes to Transcriber
            </Typography>

            {transcriberNotes.length === 0 ? (
              <Card
                style={{
                  padding: 10,
                  minHeight: 120,
                  marginTop: 15,
                  paddingRight: 20,
                  paddingLeft: 20,
                  backgroundColor: "#1A336E",
                }}
                elevation={8}
              >
                <Typography variant="caption" style={{ color: "white" }}>
                  No notes
                </Typography>
              </Card>
            ) : (
              transcriberNotes.map((note, idx) => {
                return (
                  <Card
                    style={{
                      padding: 10,
                      paddingRight: 20,
                      paddingLeft: 20,
                      minHeight: 20,
                      marginTop: 15,
                      backgroundColor: theme.palette.gray.main,
                    }}
                    elevation={8}
                    key={idx}
                  >
                    {note.split("\n").map((item, key) => {
                      return (
                        <Typography key={key} variant="caption" color="primary">
                          {item.split(" ").map((word, index) => {
                            if (word.indexOf("http") !== -1) {
                              return (
                                <Link
                                  href={`${word}`}
                                  key={index}
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  {index !== 0 ? " " + word : word}
                                </Link>
                              );
                            } else {
                              let wordAux = index !== 0 ? " " + word : word;
                              return wordAux;
                            }
                          })}
                          <br />
                        </Typography>
                      );
                    })}
                  </Card>
                );
              })
            )}
          </Grid>
          <Grid item lg={6} xs={12} style={{ marginTop: 30 }}>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Feedback to Transcriber
            </Typography>
            {unit.reviewer_notes.length === 0 ? (
              <Card
                style={{
                  padding: 10,
                  minHeight: 20,
                  marginTop: 15,
                  backgroundColor: theme.palette.gray.main,
                }}
                elevation={8}
              >
                <Typography variant="caption" color="primary">
                  No notes
                </Typography>
              </Card>
            ) : (
              unit.reviewer_notes.map((note, idx) => {
                return (
                  <Card
                    style={{
                      padding: 10,
                      paddingRight: 20,
                      paddingLeft: 20,
                      minHeight: 20,
                      marginTop: 15,
                      backgroundColor: "#1A336E",
                    }}
                    elevation={8}
                    key={idx}
                  >
                    {note.split("\n").map((item, key) => {
                      return (
                        <Typography
                          key={key}
                          variant="caption"
                          style={{ color: "white" }}
                        >
                          {item.split(" ").map((word, index) => {
                            if (word.indexOf("http") !== -1) {
                              return (
                                <Link
                                  href={`${word}`}
                                  key={key}
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  {index !== 0 ? " " + word : word}
                                </Link>
                              );
                            } else {
                              let wordAux = index !== 0 ? " " + word : word;
                              return wordAux;
                            }
                          })}
                          <br />
                        </Typography>
                      );
                    })}
                  </Card>
                );
              })
            )}
          </Grid>

          {(user.rolename !== "Transcriber" && user.rolename !== "QA") && (
            <div style={{ width: "100%" }}>
              <Grid item lg={12} style={{ marginTop: 30 }}>
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  Unit's rejection reasons:
                </Typography>
              </Grid>
              {rejectedReasons.map((note, key) => {
                return (
                  <Grid item lg={4} xs={12}>
                    <Container
                      style={{
                        padding: 15,
                        border: "2px solid #1A336E",
                        marginTop: 10,
                      }}
                    >
                      <div key={key}>
                        Transcriber: {note.transcribers} <br />
                        Reason: {note.reject_reason} <br />
                        Times: {note.time}
                      </div>
                    </Container>
                  </Grid>
                );
              })}
            </div>
          )}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
