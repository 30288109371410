import AWS from "aws-sdk";
import Config from "./config";
import { basename, baseNameFolder } from "./utils";

AWS.config.update({
  region: Config.awsRegion,
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey,
});

const s3 = new AWS.S3();

const basePath = "Knowledge/";

async function emptyS3Directory(bucket, dir) {
  const listParams = {
    Bucket: bucket,
    Prefix: dir,
  };

  const listedObjects = await s3.listObjectsV2(listParams).promise();

  if (listedObjects.Contents.length === 0) return;

  const deleteParams = {
    Bucket: bucket,
    Delete: { Objects: [] },
  };

  listedObjects.Contents.forEach(({ Key }) => {
    deleteParams.Delete.Objects.push({ Key });
  });

  await s3.deleteObjects(deleteParams).promise();

  if (listedObjects.IsTruncated) await emptyS3Directory(bucket, dir);
}

const renameFolder = async (oldFolderName, newFolderName) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: Config.awsBucketName,
      Prefix: oldFolderName,
    };

    var tasks = [];

    s3.listObjectsV2(params, async function (err, data) {
      if (err) {
        reject(err);
      } else {
        for (const object of data.Contents) {
          var newKey = object.Key.replace(
            `${oldFolderName}`,
            `${newFolderName}`
          );

          var copyTask = await s3
            .copyObject({
              Bucket: Config.awsBucketName,
              CopySource: Config.awsBucketName + "/" + object.Key,
              Key: newKey,
            })
            .promise();

          var deleteTask = await s3
            .deleteObject({
              Bucket: Config.awsBucketName,
              Key: object.Key,
            })
            .promise();

          tasks.push(copyTask);
          tasks.push(deleteTask);
        }

        Promise.all(tasks).then(() => {
          resolve();
        });
      }
    });
  });
};

async function renameFile(oldFilename, newFilename) {
  await s3
    .copyObject({
      Bucket: Config.awsBucketName,
      CopySource: `${Config.awsBucketName}/${oldFilename}`,
      Key: newFilename,
    })
    .promise();

  await s3
    .deleteObject({
      Bucket: Config.awsBucketName,
      Key: oldFilename,
    })
    .promise();
}

async function deleteItem(bucket, key) {
  const params = { Bucket: bucket, Key: key };

  await s3.deleteObject(params).promise();
}

const moveItem = async (item, newPrefix) => {
  let oldPrefix = item.id.replace(item.name, "");

  if (item.isDir) {
    oldPrefix = oldPrefix.substring(0, oldPrefix.length - 1);
  }

  if (newPrefix !== oldPrefix) {
    const params = {
      Bucket: Config.awsBucketName,
      CopySource: Config.awsBucketName + "/" + item.id,
      Key: item.id.replace(oldPrefix, newPrefix),
    };

    const response = await s3
      .copyObject(params)
      .promise()
      .then(async (res) => {
        await s3
          .deleteObject({ Bucket: Config.awsBucketName, Key: item.id })
          .promise();

        return Promise.resolve(params.Key);
      });

    return response;
  }
};

const moveFolder = async (folder, newPrefix) => {
  const objects = await s3
    .listObjectsV2({
      Bucket: Config.awsBucketName,
      Prefix: folder.id,
    })
    .promise();

  for (const object of objects.Contents) {
    const isDir = object.Key.endsWith("/");
    if (isDir && object.Key !== folder.id) {
      await moveItem(
        {
          id: object.Key,
          name: object.Key.split("/").reverse()[1],
          isDir: true,
        },
        newPrefix
      ).then(async (newLocation) => {
        moveFolder(
          {
            id: object.Key,
            name: object.Key.split("/").reverse()[1],
            isDir: true,
          },
          newLocation
        );
      });
    } else {
      await moveItem(
        {
          id: object.Key,
          name: object.Key.split("/").reverse()[0],
          isDir: false,
        },
        newPrefix
      );
    }
  }

  await emptyS3Directory(Config.awsBucketName, folder.id);

  return Promise.resolve();
};

function getNameOfFolder(name) {
  return name.split("/").reverse()[1];
}

function getNameOfFile(fileName) {
  return fileName.replace(/^(.*[/\\])?/, "").replace(/(\.[^.]*)$/, "");
}

function listAllKeys(params) {
  return new Promise((done, reject) => {
    let data = [];
    list();
    function list() {
      s3.listObjectsV2({
        Bucket: Config.awsBucketName,
        Prefix: basePath,
      })
        .promise()
        .then((_data) => {
          data.push(
            ..._data.CommonPrefixes.map((prefix) => {
              return { Key: prefix.Prefix };
            })
          );
          data.push(..._data.Contents);
          if (_data.IsTruncated) {
            params.ContinuationToken = _data.NextContinuationToken;
            list();
          } else {
            done(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}

function parseInformation(object) {
  object.isFile =
    object.Key.slice(object.Key.length - 1, object.Key.length) === "/"
      ? false
      : true;
  object.name = !object.isFile
    ? object.Key.split("/").reverse()[1]
    : object.Key.replace(/^(.*[/\\])?/, ""); //.replace(/(\.[^.]*)$/, "");
  object.isPublic = !object.isFile && object.name === "Public";

  if (object.isFile) {
    object.url = `https://${Config.awsBucketName}.s3.amazonaws.com/${object.Key}`;
    object.ext = /[.]/.exec(object.Key)
      ? /[^.]+$/.exec(object.Key)[0]
      : undefined;
  }
  return object;
}

function listObjectS3(key = "", isGlobal = false, isSearch = false) {
  return new Promise((done, reject) => {
    let params = {
      MaxKeys: 2147483647,
      Prefix: isGlobal ? key : basePath + key,
    };
    if (!isSearch) {
      params.Delimiter = "/";
    }

    listAllKeys(params)
      .then((_data) => {
        let resu = _data.map((object) => parseInformation(object));
        let itemForRemove = resu.findIndex(
          (a) => a.isFile === false && a.Size === 0
        );
        if (itemForRemove >= 0) {
          if (!isSearch) {
            resu.splice(itemForRemove, 1);
          }
        }
        done(resu.sort((_data) => _data.name));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function siteMap(paths, onlyFolders = false) {
  let isArrayOfPaths = paths instanceof Array ? true : false;
  let folders = [];
  if (isArrayOfPaths) {
    for (let path of paths) {
      try {
        let folder = await listObjectS3(path, true, true);
        folders.push(...folder);
      } catch (err) {
        console.error("Unable to download the files", err);
      }
    }
  } else {
    try {
      folders = await listObjectS3("", false, true);
    } catch (err) {
      console.error("Unable to download the files", err);
    }
  }

  let tree_folder = [];
  let push_folder = (str) => {
    if (tree_folder.filter((item) => item.path === str.path).length === 0) {
      tree_folder.push(str);
    }
  };
  folders.forEach((item) => {
    let paths = item.Key.split("/");
    let path = "";
    paths.forEach((_item, index) => {
      if (_item !== "") {
        let isFile = false;
        if (
          item.isFile === true &&
          index === paths.length - 1 &&
          onlyFolders === true
        ) {
          return;
        } else if (item.isUrl === true) {
          return;
        } else if (
          item.isFile === true &&
          index === paths.length - 1 &&
          onlyFolders === false
        ) {
          path += _item;
          isFile = true;
        } else {
          path += _item + "/";
        }
        push_folder({ path, isFile });
      }
    });
  });
  let getParent = (str) => {
    str = str.split("/").filter((item) => item !== "");
    return str.slice(0, str.length - 1).join("/") + "/";
  };
  let tree = [];
  let i = 0;
  let detect_folders = (_path, isFile) => {
    let children = tree_folder.filter((item) => _path === getParent(item.path));
    let resu = { id: i, path: _path, isFile };
    if (i === 0 && !isArrayOfPaths) {
      resu.toggled = true;
      resu.name = "Home";
    } else {
      resu.name = isFile ? getNameOfFile(_path) : getNameOfFolder(_path);
    }
    i += 1;
    if (children.length > 0) {
      resu.children = children.map((item) =>
        detect_folders(item.path, item.isFile)
      );
    }
    return resu;
  };
  if (isArrayOfPaths) {
    for (let path of paths) {
      tree.push(detect_folders(path, false));
    }
  } else {
    tree.push(detect_folders(basePath, false));
  }

  return tree;
}

const awsUtils = {
  emptyS3Directory,
  deleteItem,
  moveItem,
  renameFolder,
  moveFolder,
  renameFile,
  siteMap,
};

export default awsUtils;
