import { FormControl, InputLabel, InputBase } from "@mui/material";
import { useState, useEffect } from "react";

export default function RenderPaymentInfo(props) {
  const { inputStyle, paymentMethod, verifyField, currentUser } = props;

  const [value, setValue] = useState("");
  const [payoneerEmail, setPayoneerEmail] = useState("");

  useEffect(() => {

    setValue(
      verifyField(
        paymentMethod === "PayPal"
          ? currentUser.second_email
          : currentUser.payoneerAccount
      )
    );

    setPayoneerEmail(verifyField(currentUser.payoneerMail));
  }, [paymentMethod, currentUser]);

  return paymentMethod === "PayPal" || paymentMethod === "Payoneer" ? (
    <div
      className="paymentInfo"
      style={
        paymentMethod === "Payoneer"
          ? {
              display: "flex",
              flexDirection: "row",
              width: "75%",
            }
          : { display: "flex", flexDirection: "row", width: "100%" }
      }
    >
      <FormControl fullWidth variant="standard" className="formControl">
        <>
          <InputLabel
            shrink
            htmlFor={paymentMethod === "PayPal" ? "paypal" : "payoneer"}
          >
            {paymentMethod === "PayPal" ? "PayPal Email" : "Payoneer Account ID"}
          </InputLabel>
          <InputBase
            id={paymentMethod === "PayPal" ? "paypal" : "payoneer"}
            name={
              paymentMethod === "PayPal" ? "second_email" : "payoneerAccount"
            }
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={inputStyle}
            required
          />
        </>
      </FormControl>

      {paymentMethod === "Payoneer" ? (
        <FormControl fullWidth variant="standard" className="formControl">
          <>
            <InputLabel
              shrink
              htmlFor={paymentMethod === "PayPal" ? "paypal" : "payoneer"}
            >
              Payoneer Email
            </InputLabel>
            <InputBase
              id={paymentMethod === "PayPal" ? "paypal" : "payoneer"}
              name = "payoneerMail"
              value={payoneerEmail}
              onChange={(e) => setPayoneerEmail(e.target.value)}
              style={inputStyle}
              required
            />
          </>
        </FormControl>
      ) : null}
    </div>
  ) : null;
}
