import { useState, useEffect } from "react";

import {
  IconButton,
  Box,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  AppBar,
  Divider,
  Tooltip,
  useRadioGroup,
} from "@mui/material";

import useZustant from "../hooks/useZustant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Requests from "../logic/Requests";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import { useLocation } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from '@mui/icons-material/Menu';
import RenderMenuItems from "./navbar/RenderMenuItems";

export default function NavbarComponent() {
  const { user, clearUser, isOpenSidebar, changeSidebarValue} = useZustant();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    try {
      Requests.logout().then((res) => {
        if (res.status === 200) {
          clearUser();
          navigate("/");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnterprise = () => {
    window.location.replace("https://www.focusfwd.com/");
  };

  const handleDrawerOpen = () => {
    changeSidebarValue(true);
  };

  const handleDrawerClose = () => {
    changeSidebarValue(false);
  };

  const userName = user.firstname;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position={user.rolename === "Client" ? "fixed" : "static"}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "white !important",
          }}
        >
          <Toolbar>
            {location.pathname === "/uploadFiles" ? null : (
              <>
                <Toolbar title="Menu">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: "none" }) }}
                  >
                    <MenuIcon color="primary"/>
                  </IconButton>
                </Toolbar>
                <div style={{ flexGrow: 1 }} />

                <Tooltip title="my Cart">
                  <IconButton>
                    <ShoppingCartIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Button
                  color="primary"
                  sx={{ textTransform: "none", mr: "20px" }}
                  onClick={handleEnterprise}
                >
                  Knowledge Center
                </Button>

                <Button
                  color="primary"
                  sx={{ textTransform: "none", mr: "20px" }}
                  onClick={handleEnterprise}
                >
                  Enterprise
                </Button>
                <Button
                  color="primary"
                  sx={{ textTransform: "none" }}
                  onClick={handleClick}
                >
                  Hi {userName} <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <RenderMenuItems user={user} handleClose={handleClose} />

                  <Divider sx={{ borderColor: "white !important" }} />
                  <MenuItem onClick={() => logout()}>Log Out</MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
