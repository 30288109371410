import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function RenderFile(props) {
  const { itemSelected, type_file } = props;

  if (itemSelected) {
    if (itemSelected.type === type_file.DOCUMENT) {
      return (
        <iframe
          title="file"
          src={`https://docs.google.com/viewer?url=${itemSelected.url}&embedded=true`}
          frameBorder="0"
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      );
    } else if (itemSelected.type === type_file.IMAGE) {
      return (
        <img
          src={itemSelected.url}
          alt={itemSelected.ext}
          className="content"
        />
      );
    } else if (itemSelected.type === type_file.VIDEO) {
      return <video src={itemSelected.url} controls className="content" />;
    } else if (itemSelected.type === type_file.AUDIO) {
      return <audio src={itemSelected.url} controls />;
    } else if (itemSelected.type === type_file.OTHER) {
      return (
        <a href={itemSelected.url} target="_blank" rel ="noreferrer">
          Download {itemSelected.name + "." + itemSelected.ext}{" "}
          <FontAwesomeIcon icon={faCloudArrowDown} />
        </a>
      );
    }
  }
}
