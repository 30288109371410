import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Button,
  InputBase,
} from "@mui/material";
import Config from "../../config";
import "../../App.css";
import theme from "../../theme";

export default function CreateFolderModal(props) {
  const { show, close, path, bucket, updateFiles, handleAlert } = props;

  const inputStyle = {
    marginTop: theme.spacing(3),
    position: "relative",
    borderRadius: "4px",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    borderBottom: "none",
    border: "1px solid black",
    fontSize: 18,
    padding: "5px 6px",
    height: "46px",
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.currentTarget);

      const name = formData.get("folderName");

      const trueName = name + "/";

      const params = {
        Key: path + trueName,
        Body: "",
        ACL: "private",
        Bucket: Config.awsBucketName,
      };

      const promise = bucket
        .upload(params, (err, data) => {
          if (err) {
            handleAlert("error", "There was an error creating the folder");
          }
        })
        .promise();

      promise.then((data) => {
        handleAlert("success", "Folder created successfully");
        close();
        updateFiles();
      });
    } catch (error) {
      handleAlert("error", "There was an error creating the folder");
    }
  };

  return (
    <Modal open={show}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <h2>Create Folder</h2>
          <FormControl fullWidth className="formControl" variant="standard">
            <InputLabel htmlFor="folderName" shrink>
              Name this folder
            </InputLabel>
            <InputBase
              sx={inputStyle}
              id="folderName"
              name="folderName"
              required
            />
          </FormControl>
          <div className="createFolderButtonContainer">
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{ marginRight: "7px" }}
            >
              Create
            </Button>
            <Button onClick={() => close()} color="red" variant="contained">
              Cancel
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
