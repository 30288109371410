import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import "../../App.css";
import Button from "@mui/material/Button";

const actions = ["Projects", "Action Items", "QA Queue", "Closed"];

export default function ProjectActionsComponent() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
        <Card component="main" className="Banner">
          <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
            {actions.map((action) => (
              <Button
                key={action}
                color="fourth"
                variant="contained"
                sx={{ mr: 4, color: "white", display: "block" }}
              >
                {action}
              </Button>
            ))}
          </Box>
        </Card>
      </Box>
    </ThemeProvider>
  );
}
