import React, { useEffect, useRef, useMemo } from "react";

import { FormControl, InputBase, InputLabel } from "@mui/material";

function FirstNameInput(props) {
  const input2Ref = useRef(null);
  const { first_name, handleChange, index , inputStyle} = props;

  const memoValue = useMemo(() => {
    return first_name;
  }, [first_name]);

  useEffect(() => {
    if (memoValue !== "") {
      input2Ref.current.focus();
    }
  }, [memoValue]);

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ marginRight: "15px !important", width: "500px" }}
    >
      <InputLabel shrink htmlFor="email">
        FIRST NAME
      </InputLabel>
      <InputBase
        sx={inputStyle}
        id="first_name"
        name="first_name"
        onChange={(e) => handleChange(index, "first_name", e.target.value)}
        value={memoValue}
        inputRef={input2Ref}
      />
    </FormControl>
  );
}

export default React.memo(FirstNameInput);
