import * as React from "react";
import {
  IconButton,
  DialogTitle,
  DialogActions,
  Dialog,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faClose } from "@fortawesome/free-solid-svg-icons";

export default function SettingsDialogComponent() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton color="fourth" size="large" onClick={handleClickOpen}>
        <FontAwesomeIcon icon={faGear} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          Additional Configuration
          <IconButton color="red" size="large" onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </DialogTitle>
        <DialogActions  style={{paddingLeft: 20, paddingRight: 20}}>
          <Button
            variant="contained"
            color="third"
            onClick={handleClose}
            style={{ color: "white" }}
          >
            Pause Project
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="fourth"
            style={{ color: "white" }}
          >
            Ready To Invoice
          </Button>
          <Button
            onClick={handleClose}
            color="fourth"
            variant="contained"
            style={{ color: "white" }}
          >
            Mark Complete
          </Button>
          <Button
            onClick={handleClose}
            color="red"
            variant="contained"
            style={{ color: "white" }}
          >
            Cancel Project
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
