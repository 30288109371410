const validElements =
  "" +
  "a[accesskey|charset|class|coords|dir<ltr?rtl|href|hreflang|id|lang|name" +
  "|onblur|onclick|ondblclick|onfocus|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|rel|rev" +
  "|shape<circle?default?poly?rect|style|tabindex|title|target|type]," +
  "abbr[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "acronym[class|dir<ltr?rtl|id|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "address[class|align|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "applet[align<bottom?left?middle?right?top|alt|archive|class|code|codebase" +
  "|height|hspace|id|name|object|style|title|vspace|width]," +
  "area[accesskey|alt|class|coords|dir<ltr?rtl|href|id|lang|nohref<nohref" +
  "|onblur|onclick|ondblclick|onfocus|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup" +
  "|shape<circle?default?poly?rect|style|tabindex|title|target]," +
  "base[href|target]," +
  "basefont[color|face|id|size]," +
  "bdo[class|dir<ltr?rtl|id|lang|style|title]," +
  "big[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "blockquote[cite|class|dir<ltr?rtl|id|lang|onclick|ondblclick" +
  "|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout" +
  "|onmouseover|onmouseup|style|title]," +
  "body[alink|background|bgcolor|class|dir<ltr?rtl|id|lang|link|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onload|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|onunload|style|title|text|vlink]," +
  "button[accesskey|class|dir<ltr?rtl|disabled<disabled|id|lang|name|onblur" +
  "|onclick|ondblclick|onfocus|onkeydown|onkeypress|onkeyup|onmousedown" +
  "|onmousemove|onmouseout|onmouseover|onmouseup|style|tabindex|title|type" +
  "|value]," +
  "caption[align<bottom?left?right?top|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "center[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "cite[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "code[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "col[align<center?char?justify?left?right|char|charoff|class|dir<ltr?rtl|id" +
  "|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown" +
  "|onmousemove|onmouseout|onmouseover|onmouseup|span|style|title" +
  "|valign<baseline?bottom?middle?top|width]," +
  "colgroup[align<center?char?justify?left?right|char|charoff|class|dir<ltr?rtl" +
  "|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown" +
  "|onmousemove|onmouseout|onmouseover|onmouseup|span|style|title" +
  "|valign<baseline?bottom?middle?top|width]," +
  "dd[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style|title]," +
  "del[cite|class|datetime|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "dfn[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "dir[class|compact<compact|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "div[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "dl[class|compact<compact|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "dt[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style|title]," +
  "em/i[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "fieldset[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "font[class|color|dir<ltr?rtl|face|id|lang|size|style|title]," +
  "form[accept|accept-charset|action|class|dir<ltr?rtl|enctype|id|lang" +
  "|method<get?post|name|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|onreset|onsubmit" +
  "|style|title|target]," +
  "frame[class|frameborder|id|longdesc|marginheight|marginwidth|name" +
  "|noresize<noresize|scrolling<auto?no?yes|src|style|title]," +
  "frameset[class|cols|id|onload|onunload|rows|style|title]," +
  "h1[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "h2[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "h3[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "h4[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "h5[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "h6[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "head[dir<ltr?rtl|lang|profile]," +
  "hr[align<center?left?right|class|dir<ltr?rtl|id|lang|noshade<noshade|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|size|style|title|width]," +
  "html[dir<ltr?rtl|lang|version]," +
  "ins[cite|class|datetime|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "isindex[class|dir<ltr?rtl|id|lang|prompt|style|title]," +
  "kbd[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "label[accesskey|class|dir<ltr?rtl|for|id|lang|onblur|onclick|ondblclick" +
  "|onfocus|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout" +
  "|onmouseover|onmouseup|style|title]," +
  "legend[align<bottom?left?right?top|accesskey|class|dir<ltr?rtl|id|lang" +
  "|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "li[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style|title|type" +
  "|value]," +
  "link[charset|class|dir<ltr?rtl|href|hreflang|id|lang|media|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|rel|rev|style|title|target|type]," +
  "map[class|dir<ltr?rtl|id|lang|name|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "menu[class|compact<compact|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "meta[content|dir<ltr?rtl|http-equiv|lang|name|scheme]," +
  "noframes[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "noscript[class|dir<ltr?rtl|id|lang|style|title]," +
  "object[align<bottom?left?middle?right?top|archive|border|class|classid" +
  "|codebase|codetype|data|declare|dir<ltr?rtl|height|hspace|id|lang|name" +
  "|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|standby|style|tabindex|title|type|usemap" +
  "|vspace|width]," +
  "ol[class|compact<compact|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|start|style|title|type]," +
  "optgroup[class|dir<ltr?rtl|disabled<disabled|id|label|lang|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "option[class|dir<ltr?rtl|disabled<disabled|id|label|lang|onclick|ondblclick" +
  "|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout" +
  "|onmouseover|onmouseup|selected<selected|style|title|value]," +
  "p[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|onclick|contenteditable" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|style|title]," +
  "param[id|name|type|value|valuetype<DATA?OBJECT?REF]," +
  "pre/listing/plaintext/xmp[align|class|dir<ltr?rtl|id|lang|onclick|ondblclick" +
  "|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout" +
  "|onmouseover|onmouseup|style|title|width]," +
  "q[cite|class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "s[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style|title]," +
  "samp[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "script[charset|defer|language|src|type]," +
  "select[class|dir<ltr?rtl|disabled<disabled|id|lang|multiple<multiple|name" +
  "|onblur|onchange|onclick|ondblclick|onfocus|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|size|style" +
  "|tabindex|title]," +
  "small[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "span[align<center?justify?left?right|class|dir<ltr?rtl|id|lang|contenteditable" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "strike[class|class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title]," +
  "strong/b[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "style[dir<ltr?rtl|lang|media|title|type]," +
  "sub[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "sup[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]," +
  "|dir<ltr?rtl|frame|height|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|rules" +
  "|style|summary|title|width]," +
  "tbody[align<center?char?justify?left?right|char|class|charoff|dir<ltr?rtl|id" +
  "|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown" +
  "|onmousemove|onmouseout|onmouseover|onmouseup|style|title" +
  "|valign<baseline?bottom?middle?top]," +
  "td[abbr|align<center?char?justify?left?right|axis|bgcolor|char|charoff|class" +
  "|colspan|dir<ltr?rtl|headers|height|id|lang|nowrap<nowrap|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|rowspan|scope<col?colgroup?row?rowgroup" +
  "|style|title|valign<baseline?bottom?middle?top|width]," +
  "textarea[accesskey|class|cols|dir<ltr?rtl|disabled<disabled|id|lang|name" +
  "|onblur|onclick|ondblclick|onfocus|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|onselect" +
  "|readonly<readonly|rows|style|tabindex|title]," +
  "tfoot[align<center?char?justify?left?right|char|charoff|class|dir<ltr?rtl|id" +
  "|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown" +
  "|onmousemove|onmouseout|onmouseover|onmouseup|style|title" +
  "|valign<baseline?bottom?middle?top]," +
  "th[abbr|align<center?char?justify?left?right|axis|bgcolor|char|charoff|class" +
  "|colspan|dir<ltr?rtl|headers|height|id|lang|nowrap<nowrap|onclick" +
  "|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove" +
  "|onmouseout|onmouseover|onmouseup|rowspan|scope<col?colgroup?row?rowgroup" +
  "|style|title|valign<baseline?bottom?middle?top|width]," +
  "thead[align<center?char?justify?left?right|char|charoff|class|dir<ltr?rtl|id" +
  "|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown" +
  "|onmousemove|onmouseout|onmouseover|onmouseup|style|title" +
  "|valign<baseline?bottom?middle?top]," +
  "title[dir<ltr?rtl|lang]," +
  "tr[abbr|align<center?char?justify?left?right|bgcolor|char|charoff|class" +
  "|rowspan|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title|valign<baseline?bottom?middle?top]," +
  "tt[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style|title]," +
  "u[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress|onkeyup" +
  "|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style|title]," +
  "ul[class|compact<compact|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown" +
  "|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover" +
  "|onmouseup|style|title|type]," +
  "var[class|dir<ltr?rtl|id|lang|onclick|ondblclick|onkeydown|onkeypress" +
  "|onkeyup|onmousedown|onmousemove|onmouseout|onmouseover|onmouseup|style" +
  "|title]";

const plugins =
  "tinymcespellchecker preview powerpaste casechange searchreplace autolink autosave save directionality  mediaembed charmap pagebreak nonbreaking anchor permanentpen pageembed charmap quickbars linkchecker emoticons export autocorrect insertdatetime wordcount a11ychecker editimage";
function displaySpeakers() {
  const pInputs = window.tinymce.activeEditor.dom.select("p");
  const inputs = pInputs.filter((p) => p.classList.contains("SpeakerInput"));
  for (let i = 0; i < inputs.length; i++) {
    let input = inputs[i];
    input.onclick = () => {
      const paragraphContent = input.parentElement.parentElement.children[1];
      const pLength = paragraphContent.firstChild.length;
      let lis = [];
      const speakerListExist =
        window.tinymce.activeEditor.dom.get("SpeakerList");
      if (!speakerListExist) {
        var ul = document.createElement("ul");
        ul.setAttribute("id", "SpeakerList");
        let speakerList = getSpeakerList();
        for (let j = 0; j < speakerList.length; j++) {
          var li = document.createElement("li");
          li.classList.add("SpeakerOption");
          li.innerHTML = speakerList[j];
          lis.push(li);
          ul.appendChild(li);
          input.parentElement.appendChild(ul);
        }

        for (let k = 0; k < lis.length; k++) {
          lis[k].onclick = () => {
            input.innerHTML = lis[k].innerHTML;
            window.tinymce.activeEditor.selection.setCursorLocation(
              paragraphContent.firstChild,
              pLength
            );
            window.tinymce.activeEditor.dom.remove(
              window.tinymce.activeEditor.dom.get("SpeakerList")
            );
          };
        }
      }
    };
  }
}

function getSpeakerList() {
  const pInputs = window.tinymce.activeEditor.dom.select("p");
  let dataArr = pInputs.filter((p) => p.classList.contains("SpeakerInput"));
  let dataArrAux = [];
  dataArr.forEach((element) => {
    if (
      element.innerHTML !== '<br data-mce-bogus="1">' ||
      element.innerHTML !== ""
    ) {
    dataArrAux.push(element.innerHTML);
    }
  });

  const inputsAux = new Set(dataArrAux);
  let inputs = [...inputsAux];
  inputs = inputs.filter((p) => p !== "Add speaker" && p !== "");
  return inputs;
}

function cleanInputs() {
  const pInputs = window.tinymce.activeEditor.dom.select("p");
  let dataArr = pInputs.filter((p) => p.classList.contains("SpeakerInput"));
  dataArr.forEach((element) => {
    element.innerHTML.trim();
    if (
      element.innerHTML === "" ||
      element.innerHTML === '<br data-mce-bogus="1">'
    ) {
      element.innerHTML = "Add speaker";
    }
  });
}


const EditorUtils = {
  validElements,
  plugins,
  getSpeakerList,
  displaySpeakers,
  cleanInputs,
};

export default EditorUtils;
