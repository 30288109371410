const Config = {
  api: "http://uat.focusfwd.com/api",
  oldUI: "http://uat-legacy.focusfwd.com",
  awsBucketName: "topsstorage",
  awsRegion: "us-east-1",
  accessKey: "AKIAQDR2ULSLODZ23OTE",
  secretAccessKey: "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",
  cookieDomain: ".focusfwd.com",
  editorApiKey: "pz17gdse0z3yw77rkpx3wco0y6vpvk2ycmroq3iv4u7pop93",
};

export default Config;
