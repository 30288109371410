import React, { useEffect, useState } from "react";
import { Dialog, Button, Box, Typography, Chip, Input } from "@mui/material";
import useShow from "../../hooks/useShow";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faKeyboard,
} from "@fortawesome/free-solid-svg-icons";
import { isMacOs } from "react-device-detect";
import InputhotKey from "../profile/InputHotkey";
import { Container } from "@mui/system";

function KeyboardUnitShortCuts(props) {
  const { show, open, close } = useShow();
  const { userHotKeys, unitHotKeys, useUnitHotkeys, saveHotKeys, handleAlert } =
    props;

  const [currentHotkeys, setCurrentHotkeys] = useState();

  const handleCurrentHotkeys = (hotKeys) => {
    setCurrentHotkeys(() => {
      return Object.entries(hotKeys).map(([key, value]) => {
        return {
          ...value,
          type: "String",
        };
      });
    });
  };

  function handleHotKeys(keyboardKey, updatedKeyCode) {
    setCurrentHotkeys((prevHotKeys) => {
      return Object.entries(prevHotKeys).map(([key, value]) => {
        if (value.cmd === keyboardKey) {
          return {
            keyCode: updatedKeyCode.keyCode,
            key: updatedKeyCode.key,
            ctrlKey: updatedKeyCode.ctrlKey,
            label: <label>keyboardKey</label>,
            cmd: keyboardKey,
            type: "String",
          };
        }
        return value;
      });
    });
  }

  const modifyCurrentValues = (cmdValue, cmdkey) => {
    let newValues = Object.entries(currentHotkeys).map(([key, value]) => {
      if (key === cmdkey) {
        value.cmd = cmdValue;
        if (value.type === "NewShortCut") {
          value.type = "String";
          return value;
        } else {
          return value;
        }
      }
      return value;
    });
    setCurrentHotkeys(newValues);
  };

  useEffect(() => {
    if (userHotKeys && unitHotKeys) {
      if (useUnitHotkeys) {
        handleCurrentHotkeys(unitHotKeys);
      } else {
        handleCurrentHotkeys(userHotKeys);
      }
    }
  }, [unitHotKeys, useUnitHotkeys, userHotKeys, show]);

  const addNewShorCutInput = () => {
    let aux = Object.values(currentHotkeys);
    aux.push({
      keyCode: "",
      key: "",
      ctrlKey: false,
      label: <label></label>,
      cmd: "NewShortCut" + aux.length,
      type: "NewShortCut",
    });
    setCurrentHotkeys({ ...aux });
  };

  const removeShortCut = (ElementKey) => {
    let aux = [];
    Object.values(currentHotkeys).map((hotkey, key) => {
      if (ElementKey != key) {
        aux.push(hotkey);
      }
    });
    setCurrentHotkeys({ ...aux });
  };

  const cleanHotKeys = () => {
    let aux = [];
    Object.values(currentHotkeys).map((hotkey, key) => {
      if (hotkey.type != "NewShortCut" && hotkey.keyCode != "") {
        aux.push(hotkey);
      }
    });
    setCurrentHotkeys({ ...aux });
    saveHotKeys(useUnitHotkeys, { ...aux }, close);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={open}
        style={{ borderColor: "#48494B", color: "#48494B" }}
      >
        Unit Shortcuts
        <FontAwesomeIcon icon={faKeyboard} style={{ marginLeft: 10 }} />
      </Button>
      {currentHotkeys ? (
        <Dialog open={show} onClose={close} sx={{ p: 3 }}>
          <Typography
            variant="h3"
            sx={{ ml: 3, mb: 3, fontWeight: 400, mt: 3 }}
          >
            Unit Keyboard Shortcuts
          </Typography>

          <Box className="unitHotkeys">
            {Object.entries(currentHotkeys).map(([key, value]) => {
              return value.cmd != "inaud" &&
                value.cmd != "sync" &&
                value.cmd != "moderator" &&
                value.cmd != "respondant" ? (
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "30%",
                      marginLeft: 10,
                    }}
                  >
                    <InputhotKey
                      isMacOs={isMacOs}
                      value={value}
                      name={value.cmd}
                      handleHotKeys={handleHotKeys}
                      userHotKeys={userHotKeys}
                      hotKeys={currentHotkeys}
                      key={key}
                      handleAlert={handleAlert}
                      disableLabel
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "70%",
                      marginLeft: 10,
                      textAlign: "center",
                    }}
                  >
                    <Input
                      variant="outlined"
                      size="small"
                      value={
                        value.cmd.includes("NewShortCut") ? null : value.cmd
                      }
                      placeholder={
                        value.cmd.includes("NewShortCut") || value.cmd == ""
                          ? "Write your command"
                          : value.cmd
                      }
                      onChange={(e) => {
                        modifyCurrentValues(e.target.value, key);
                      }}
                      className="gridItem"
                      style={{
                        border: "solid rgba(0, 0, 0, 0.5) 0.5px",
                        borderRadius: 5,
                        height: "2.4375em",
                        padding: "8.5px 14px",
                        textAlign: "center",
                      }}
                    ></Input>
                    <Button onClick={() => removeShortCut(key)}>
                      <FontAwesomeIcon icon={faTrash} color="gray" />
                    </Button>
                  </div>
                </div>
              ) : null;
            })}
          </Box>
          <Button
            onClick={addNewShorCutInput}
            color="red"
            style={{ width: "100%" }}
          >
            Add New Shortcut
          </Button>
          <Container
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}
          >
            <Button onClick={close} color="red">
              Close
            </Button>
            <Button
              onClick={() => {
                cleanHotKeys();
              }}
              color="primary"
            >
              Save
            </Button>
          </Container>
        </Dialog>
      ) : null}
    </>
  );
}

export default KeyboardUnitShortCuts;
