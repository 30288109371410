import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Card, Box, Typography } from "@mui/material";
import awsUtils from "../awsUtils";
import useShow from "../hooks/useShow";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Requests from "../logic/Requests";
import "../App.css";

import RenderFile from "../components/knowledge/RenderFile";

const type_file = {
  VIDEO: 1,
  IMAGE: 2,
  DOCUMENT: 3,
  AUDIO: 4,
  OTHER: 5,
};

export default function Knowledge() {
  const { siteMap } = awsUtils;

  const [data, setData] = useState([]);
  const [itemSelected, setItemSelected] = useState(null);
  const { show: ShowSection0, toggle: toggleSection0 } = useShow();
  const { show: ShowSection1, toggle: toggleSection1 } = useShow();
  const { show: ShowSection2, toggle: toggleSection2 } = useShow();
  const { show: ShowSection3, toggle: toggleSection3 } = useShow();
  const { show: ShowSection4, toggle: toggleSection4 } = useShow();

  function buildURL(path) {
    return Requests.downloadObjects([path]);
  }

  const handleClick = (path, name) => {
    let item = {};
    item.ext = /[.]/.exec(path) ? /[^.]+$/.exec(path)[0] : undefined;
    const fileUrl = encodeURIComponent(path);
    item.url = buildURL(fileUrl);
    item.ext = item.ext.toUpperCase();
    item.name = name;
    if (["JPEG", "JPG", "TIFF", "GIF", "BMP", "PNG"].indexOf(item.ext) >= 0) {
      item.type = type_file.IMAGE;
    } else if (["AVI", "WMV", "MP4"].indexOf(item.ext) >= 0) {
      item.type = type_file.VIDEO;
    } else if (["MP3", "OGG", "WAV"].indexOf(item.ext) >= 0) {
      item.type = type_file.AUDIO;
    } else if (["PDF"].indexOf(item.ext) >= 0) {
      item.type = type_file.DOCUMENT;
    } else {
      item.type = type_file.OTHER;
    } 

    const currentActive = document.querySelector(".activeKnow");

    if (currentActive) {
      currentActive.classList.remove("activeKnow");
    }

    const newElement = document.getElementById(name + path);

    if (newElement) {
      newElement.classList.add("activeKnow");
    }

    setItemSelected(item);
  };

  const toggle = (index) => {
    switch (index) {
      case 0:
        toggleSection0();
        break;
      case 1:
        toggleSection1();
        break;
      case 2:
        toggleSection2();
        break;
      case 3:
        toggleSection3();
        break;
      case 4:
        toggleSection4();
        break;
      default:
        break;
    }
  };

  const show = (index) => {
    switch (index) {
      case 0:
        return ShowSection0;
      case 1:
        return ShowSection1;
      case 2:
        return ShowSection2;
      case 3:
        return ShowSection3;
      case 4:
        return ShowSection4;
      default:
        break;
    }
  };

  useEffect(() => {
    document.title = "Knowledge- Focus Forward Tops";

    siteMap().then((data) => {
      setData(data[0].children);
    });
  }, []);

  if (data) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            px: 2,
            mb: 10,
          }}
        >
          <div component="main" className="viewContainer" id="knowledgePage">
            <div
              style={{
                display: "flex",
                height: "600px",
              }}
            >
              <List
                className="knowledgeList"
                sx={{
                  bgcolor: "background.paper",
                }}
                component="nav"
              >
                {data.map((item, index) => (
                  <>
                    <ListItemButton onClick={() => toggle(index)}>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontSize: 14,
                        }}
                      />
                      {show(index) ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={show(index)} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.children.map((child) => {
                          return child.name === "IndexerVolumeGuid" ? null : (
                            <ListItemButton
                              id={child.name + child.path}
                              sx={{ pl: 4 }}
                              onClick={() => {
                                handleClick(child.path, child.name);
                              }}
                            >
                              <ListItemText
                                primary={child.name}
                                primaryTypographyProps={{
                                  fontSize: 14,
                                }}
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                ))}
              </List>

              <div className="contentContainer">
                {itemSelected ? (
                  <RenderFile
                    itemSelected={itemSelected}
                    type_file={type_file}
                  />
                ) : (
                  <Typography variant="h6" sx={{ fontWeight: 300 }}>
                    There is nothing to see here yet, select one of the sections
                    from the menu on the left.
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Box>
      </ThemeProvider>
    );
  }
}
