import ConfigDev from "./development.config";
import ConfigLocal from "./local.config";
import ConfigStaging from "./staging.config";
import ConfigProduction from "./production.config";

const Config =
  process.env.REACT_APP_ENVIRONMENT === "local"
    ? ConfigLocal
    : process.env.REACT_APP_ENVIRONMENT === "development"
    ? ConfigDev
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? ConfigStaging 
    : ConfigProduction;

export default Config;