import {
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const drawerWidth = 250;

const linksList = [
  {
    name: "My Files",
    link: "/filemanagement",
  },
  {
    name: "Search Files",
    link: "/searchfiles",
  },
  {
    name: "Order History",
    link: "/orderhistory",
  },
  {
    name: "Invite Teammates",
    link: "/inviteteammates",
  },
];

const settingsList = [
  {
    name: "My Account",
    link: "/myaccount",
  },
  {
    name: "Account Details",
    link: "/accountdetails",
  },
];

export default function ClientSidebar() {
  const {
    user,
    currentClientPage,
    isOpenSidebar,
    changeSidebarValue,
    isInClientsSettings,
  } = useZustant();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:790px)");
  const [currentLinks, setCurrentLinks] = useState(linksList);

  useEffect(() => {
    const page = document.getElementById(currentClientPage);
    const currentActive = document.querySelector(".active");

    if (currentActive) {
      currentActive.classList.remove("active");
    }

    if (page) {
      page.classList.add("active");
    }
  }, [currentClientPage, isInClientsSettings, currentLinks]);

  useEffect(() => {
    if (isInClientsSettings) {
      setCurrentLinks(settingsList);
    } else {
      setCurrentLinks(linksList);
    }
  }, [isInClientsSettings]);

  const location = useLocation();

  const isInPayment = location.pathname === "/uploadFiles";

  return user.rolename === "Client" ? (
    <Drawer
      variant="persistent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor="left"
      open={isOpenSidebar}
    >
      <Toolbar sx={{ marginBottom: "30px" }} />

      {isInClientsSettings ? (
        <Button
          sx={{
            width: "170px",
            marginLeft: "10px",
            fontWeight: 600,
          }}
          onClick={() => navigate("/filemanagement")}
        >
          {" "}
          {"<"} Back to my files
        </Button>
      ) : null}

      <List>
        {currentLinks.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              id={item.name}
              className="listButton"
              onClick={() => navigate(item.link)}
            >
              <ListItemText
                primary={item.name}
                className="listText"
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {isInClientsSettings ? null : (
        <ListItem disablePadding>
          <ListItemButton
            className="listButton"
            onClick={() => navigate("/transactionhistory")}
            id="Transaction History"
          >
            <ListItemText
              primary={"Transaction History"}
              className="listText"
              disableTypography
            />
          </ListItemButton>
        </ListItem>
      )}
    </Drawer>
  ) : null;
}
