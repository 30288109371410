import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DownloadIcon from "@mui/icons-material/Download";
import { Box } from "@mui/system";
import "../../App.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState, useEffect } from "react";
import awsUtils from "../../awsUtils";
import Config from "../../config";
import MoveFilesModal from "./moveFilesModal";
import useShow from "../../hooks/useShow";

export default function FileManagementTableToolbar(props) {
  const {
    numSelected,
    searchQuery,
    clearSearch,
    downloadSelectedFiles,
    selectedFiles,
    open,
    updateFiles,
    clearSelected,
    folderPrefix,
    handleAlert,
  } = props;

  const { emptyS3Directory, deleteItem } = awsUtils;

  const [disableDownload, setDisableDownload] = useState(false);

  const { show: showModal, open: openModal, close: closeModal } = useShow();

  useEffect(() => {
    const dirs = selectedFiles.filter((file) => file.isDir);

    if (dirs.length > 0) {
      setDisableDownload(true);
    } else {
      setDisableDownload(false);
    }
  }, [selectedFiles]);

  const RenderSearchQuery = () => {
    if (searchQuery && searchQuery !== "") {
      return (
        <Typography
          variant="h3"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Results of "{searchQuery}"{" "}
          <IconButton onClick={() => clearSearch()}>
            <CancelIcon fontSize="inherit" sx={{ cursor: "pointer" }} />
          </IconButton>
        </Typography>
      );
    }
  };

  const deleteSelectedFiles = async () => {
    const aux = [];
    await selectedFiles.forEach(async (file) => {
      if (file.isDir) {
        aux.push(emptyS3Directory(Config.awsBucketName, file.id));
      } else {
        aux.push(deleteItem(Config.awsBucketName, file.id));
      }
    });

    await Promise.all(aux).then(() => {
      updateFiles();
      clearSelected();
    });
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <MoveFilesModal
        show={showModal}
        close={closeModal}
        folderPrefix={folderPrefix}
        currentFolder={folderPrefix[folderPrefix.length - 1]}
        handleAlert={handleAlert}
        updateFiles={updateFiles}
        listOfFiles={selectedFiles}
        clearSelected={clearSelected}
      />
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div
          style={{
            display: "flex ",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            sx={{
              fontSize: "15px",
            }}
            variant="text"
            startIcon={<CreateNewFolderIcon />}
            onClick={() => open()}
          >
            <b>New Folder</b>
          </Button>

          <RenderSearchQuery />
        </div>
      )}
      {numSelected > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: 200,
          }}
        >
          <Tooltip title="Delete">
            <IconButton
              size="large"
              id="deleteButton"
              onClick={() => deleteSelectedFiles()}
            >
              <DeleteIcon id="deleteIcon" fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Move">
            <IconButton
              size="large"
              id="moveButton"
              onClick={() => openModal()}
            >
              <DriveFileMoveIcon id="moveIcon" fontSize="inherit" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download" disabled={disableDownload}>
            <IconButton
              id="downloadButton"
              size="large"
              onClick={() => downloadSelectedFiles(selectedFiles)}
            >
              <DownloadIcon fontSize="inherit" id="downloadIcon" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}
    </Toolbar>
  );
}
