import React, { useState, useEffect } from 'react';

const ReadHtmlAsText = ({ htmlPath }) => {
  const [htmlText, setHtmlText] = useState('');

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(htmlPath);
        if (!response.ok) {
          throw new Error("Error fetching the HTML file");
        }
        const data = await response.text();
        setHtmlText(data);
      } catch (error) {
        console.error(error);
        setHtmlText('');
      }
    };

    fetchHtmlContent();
  }, [htmlPath]);

  return (
    <div>
        <div dangerouslySetInnerHTML={{__html: htmlText}} />     
    </div>
  );
};

export default ReadHtmlAsText;
