import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  Button,
} from "@mui/material";
import theme from "../theme";

export default function RecoverPasswordModal(props) {
  const { show, close, recoverPassword } = props;

  return (
    <Modal open={show}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={(e) => recoverPassword(e)}>
          <h2>Recover Password</h2>

          <FormControl fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" type="email" name="email" fullWidth />
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              sx={{ marginRight: "10px", color: theme.palette.white.main }}
              color="red"
              onClick={() => close()}
            >
              Exit
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ color: theme.palette.white.main }}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
