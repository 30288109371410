import theme from "../../theme";
import {
  FormControl,
  InputBase,
  InputLabel,
  Select,
  styled,
  MenuItem,
} from "@mui/material";
import "../../App.css";
export default function CustomFormControl(props) {
  const { label, fieldId, currentValue, type, list, register, required } =
    props;

  const verifyField = (value) => (value && value !== "undefined" ? value : "");

  const CustomInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      marginTop: theme.spacing(3),
      position: "relative",
      borderRadius: "4px",
      backgroundColor: theme.palette.white.main,
      borderBottom: "none",
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "5px 6px",
      height: "36px",
      boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.1)",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const BootstrapInput = styled(InputBase)(() => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.white.main,
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      boxShadow: "inset 0 0 2px rgba(0, 0, 0, 0.1)",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      height: "25px",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  return (
    <FormControl
      variant="standard"
      fullWidth
      className="formControl"
      sx={{ marginBottom: "5px" }}
    >
      <InputLabel shrink htmlFor={fieldId} sx={{ textTransform: "uppercase" }}>
        {label}
      </InputLabel>

      {type === "select" ? (
        <Select
          input={<BootstrapInput />}
          defaultValue={verifyField(currentValue)}
          id={fieldId}
          name={fieldId}
          className="input"
          {...register(fieldId)}
          required={required}
        >
          {list.map((itemList) => (
            <MenuItem key={itemList.value} value={itemList.value}>
              {itemList.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <CustomInput
          className="input"
          defaultValue={verifyField(currentValue)}
          id="lastName"
          name="lastname"
          {...register(fieldId)}
          required={required}
        />
      )}
    </FormControl>
  );
}
