import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

const RenderMenuItems = (props) => {
  const { user, handleClose } = props;
  const navigate = useNavigate();

  const adminPmPages = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Projects", path: "/projects" },
    { name: "People", path: "/people" },
    { name: "Clients", path: "/clients" },
    { name: "Reports", path: "/reports" },
    { name: "File Management", path: "/filemanagement" },
    { name: "Knowledge Center", path: "/knowledge" },
  ];

  const clientPages = [
    { name: "My Files", path: "/filemanagement" },
    { name: "My Account", path: "/myaccount" },
    { name: "Invite Teammates", path: "/inviteTeammates" },
    { name: "Search Files", path: "/searchFiles" },
    { name: "Order History", path: "/orderHistory" },
    { name: "Transaction History", path: "/transactionHistory" },
  ];

  const transcriberPages = [
    { name: "My Work", path: `/mywork/${user._id}` },
    { name: "Find Work", path: `/projects/queue/${user._id}` },
    { name: "Knowledge Center", path: "/knowledge" },
    { name: "Settings", path: `/profile/${user._id}` },
  ];

  let pages;

  if (user.rolename === "Admin" || user.rolename === "PM") {
    pages = adminPmPages;
  } else if (user.rolename === "Client") {
    pages = clientPages;
  } else if (user.rolename === "Transcriber" || (user.rolename === "QA")) {
    pages = transcriberPages;
  }

  return pages.map((page,i) => {
    return (
      <MenuItem
        key={i}
        onClick={() => {
          navigate(page.path);
          handleClose();
        }}
      >
        {page.name}
      </MenuItem>
    );
  });
};

export default RenderMenuItems;
