import {
  Modal,
  Box,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import "../../App.css";
import useFetch from "../../hooks/useFetch";
import Requests from "../../logic/Requests";
import { useState } from "react";
import Config from "../../config";

export default function AssignUnitModal(props) {
  const { show, close, dataUnit, handleAlert } = props;

  const [allTranscribers, setAllTranscribers] = useState(false);

  const { data, isLoading } = useFetch(
    Requests.getTranscribersForReassign,
    dataUnit.transcript,
    dataUnit._id,
    allTranscribers
  );

  const [transcriber, setTranscriber] = useState(null);

  const defaultProps = {
    options: data,
    getOptionLabel: (option) => option.firstname + " " + option.lastname,
  };

  const assign = () => {
    try {
      Requests.assign(dataUnit._id, { new_user_id: transcriber._id }).then(
        () => {
          handleAlert("success", "Unit assigned successfully", "success");
          close();
          window.location.reload(false);
        }
      );
    } catch (error) {}
  };
  return (
    <ThemeProvider>
      <Modal open={show} onClose={close}>
        <Box className="assignModal">
          <div>
            <Autocomplete
              sx={{ mb: 2 }}
              {...defaultProps}
              value={transcriber}
              onChange={(event, newValue) => {
                setTranscriber(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a transcriber"
                  variant="outlined"
                  size="small"
                />
              )}
              disabled={isLoading}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={allTranscribers}
                  onChange={(event) => setAllTranscribers(event.target.checked)}
                />
              }
              label="All Transcribers"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="red"
              sx={{ color: "white !important", mr: 2 }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ color: "white !important" }}
              onClick={() => assign()}
            >
              Assign
            </Button>
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
