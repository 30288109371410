import { useEffect, React, useState, useRef } from "react";
import {
  Alert,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
  FormControl,
  InputLabel,
  Input,
  Toolbar,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider } from "@mui/material/styles";
import logo from "../../assets/img/logo.png";
import theme from "../../theme";
import Requests from "../../logic/Requests";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import "./MyFiles.css";
import useZustant from "../../hooks/useZustant";
import useShow from "../../hooks/useShow";
import useFetch from "../../hooks/useFetch";
import RecoverPasswordModal from "../../components/RecoverPasswordModal";
import CustomSnackBarAlert from "../../components/global/CustomSnackBarAlert";
import Config from "../../config";
import { useParams } from "react-router-dom";
import Utils from "../../utils";

export default function AccountInvitation() {
  const navigate = useNavigate();
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [terms, setTerms] = useState(false);
  let { accountInvitationToken } = useParams();
  const { data, isLoading } = useFetch(
    Requests.getTeamMemberByToken,
    accountInvitationToken
  );
  const { clearUser } = useZustant()
  useEffect(() => {
    document.title = "Create Account - Focus Forward Tops";  
    if (!isLoading && data) {
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
    }
  }, [data, isLoading]);

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };
  const logout = async () => {
    try {
        document.cookie =  `comesFromTranscriberHub=true; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${Config.cookieDomain
        }; path=/;"`;
        await Requests.logout().then((res) => {
            if (res.status === 200) {
                clearUser()
                navigate("/")
            }
        });
    } catch (error) {
        console.error(error)
    }
}
  const handleSubmit = async (event) => {
    if (
      first_name === "" ||
      first_name === null ||
      last_name === "" ||
      last_name === null ||
      email === "" ||
      email === null ||
      password === "" ||
      password === null
    ) {
      handleAlert("error", "Please, fill all the required fields");
      return;
    } else {
      if (password.length < 8) {
        handleAlert("error", "Password must have minimum 8 characters");
        return;
      }
      if (!terms) {
        handleAlert("error", "You must accept the terms and privacy policy");
        return;
      }
      try {
        if (accountInvitationToken){
          await Requests.acceptTeamMemberInvite({
            first_name,
            last_name,
            email,
            password,
          });
            await logout();
            await Requests.login({password,username:email});
            window.location.reload();
        }else{
          try{
            const newUserDate = await Requests.createClientUser({
              first_name,
              last_name,
              email,
              password,
              companyName
            });
            await logout();
            await Requests.login({password,username:email});
            window.location.reload();
          } catch (error) {
            handleAlert("error", error.response.data.error.error ? error.response.data.error.error : error.message);
          }
        }
      } catch (error) {
        handleAlert("error", error.response.data);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        show={showAlert}
        close={closeAlert}
        type={currentAlertState.type}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>

      <div
        className="App"
        style={{
          backgroundColor: "white",
          margin: "1rem 4rem",
          justifyContent: "flex-start",
        }}
      >
        <Toolbar sx={{ backgroundColor: "white", width: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <CardMedia
              component="img"
              style={{ width: "15rem" }}
              image={logo}
            />
          </Box>
        </Toolbar>
        <div
          id="register"
          className="mw6 mw7-l ph3 pb4 center flex-column items-center"
          style={{ margin: "0 18rem" }}
        >
          <h1 className="home-title navy">Create Account</h1>
          <div className="mv2 w-60-m w-40-l">
            Already have an account?{" "}
            <a href="/" className="sign-in-link">
              Sign In
            </a>
          </div>
          { !!accountInvitationToken && 
            <Chip
            className="notice"
            label="You are joining a shared account."
            color="primary"
          />
          }
          

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label className="label-required" htmlFor="FirstName">
                First name
              </label>
              <TextField
                variant="outlined"
                className="input-block-level v-text-input"
                size="small"
                required
                value={first_name}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <label className="label-required" htmlFor="FirstName">
                Last name
              </label>
              <TextField
                variant="outlined"
                required
                className="input-block-level v-text-input"
                size="small"
                value={last_name}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <label className="label-required" htmlFor="Email">
                Email address
              </label>
              <TextField
                variant="outlined"
                required
                className="input-block-level v-text-input"
                size="small"
                readOnly = {accountInvitationToken?true:false}
                value={email}
                onChange={ (event) => !accountInvitationToken && setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <label className="label-required" htmlFor="Password">
                Password
              </label>
              <TextField
                variant="outlined"
                type="password"
                required
                autoComplete="current-password"
                className="input-block-level v-text-input"
                size="small"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <span style={{ fontSize: "10px" }}>Minimum 8 characters</span>
            </Grid>
            {!accountInvitationToken && <Grid item xs={6} style={{paddingTop: "0"}}>
              <label className="label-required" htmlFor="FirstName">
                company
              </label>
              <TextField
                variant="outlined"
                className="input-block-level v-text-input"
                style={{}}
                size="small"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
            </Grid>}
            <Grid item xs={12}>
              <FormControlLabel
                required
                control={
                  <Checkbox checked={terms} onChange={() => setTerms(!terms)} />
                }
                label={
                  <span>
                    By creating an account, you agree to our{" "}
                    <Link href="/clients/legal/terms" style={{textDecoration: "none"}}>terms of service</Link> and 
                    <Link href="/clients/legal/privacy" style={{textDecoration: "none"}}> privacy policy</Link>.
                  </span>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{ marginTop: "0.2rem", padding: "1rem 5rem" }}
                onClick={handleSubmit}
              >
                CREATE ACCOUNT
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}
