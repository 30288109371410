import { React, useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import {
  Container,
  Divider,
  Button,
  Typography,
  Box,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Menu,
} from "@mui/material";
import FormUser from "../components/FormUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faReceipt,
  faPen,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Utils from "../utils";
import SearchBar from "../components/SearchBar";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import CustomAlertDialogSlide from "../components/global/CustomAlertDialogSlide";
import useShow from "../hooks/useShow";
import Requests from "../logic/Requests";
import useFetch from "../hooks/useFetch";

const headCells = [
  { id: "nameHead", label: "Name" },
  { id: "emailHead", label: "Email" },
  { id: "joinedHead", label: "Joined On" },
  { id: "roleHead", label: "Role" },
  { id: "actionsHead", label: "Actions" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InviteTeammates() {
  const { setCurrentClientPage, changeIsInClientsSettings, user } = useZustant();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [pendingInvites, setPendingInvites] = useState([]);
  const { data: dataPendingInvites, isLoading: isLoadingPendingInvites , setData: setDataPendingInvites} =
    useFetch(Requests.getPendingTeamMembersInvitation);
    const [filteredDataPendingInvites,setFilteredDataPendingInvites]=useState([]);

    // get active team member
    const { data: dataActiveTeamMember, isLoading: isLoadingActiveTeamMember , setData: setDataActiveTeamMember  } = useFetch(Requests.getActiveTeamMember);
    const [filteredDataActiveTeamMember,setFilteredDataActiveTeamMember]=useState([]);

    useEffect(()=>{
      setFilteredDataActiveTeamMember(dataActiveTeamMember)
    },[isLoadingActiveTeamMember,dataActiveTeamMember])
  
    // sort data   // handle update active teamMember and pending teamMember list display
    const revokedSorter = (data)=>{
      return data?.sort(function(a, b) {
        if (a.revoked === b.revoked) {
          return 0; 
        } else if (a.revoked) {
          return 1; 
        } else {
          return -1; 
        }
      })
        }
        
    useEffect(()=>{
      setFilteredDataPendingInvites(revokedSorter(dataPendingInvites))
    },[isLoadingPendingInvites,dataPendingInvites])

  
  useEffect(() => {
    setFilteredDataPendingInvites(dataPendingInvites)
    document.title = "Invite Teammates - Focus Forward";
    setCurrentClientPage("Invite Teammates");
    changeIsInClientsSettings(false);
    if (!isLoadingPendingInvites && dataPendingInvites) {
      setPendingInvites([...dataPendingInvites]);
    }
  }, [dataPendingInvites, isLoadingPendingInvites]);

  const [invitationsNumber, setInvitationsNumber] = useState([
    { id: 0, first_name: "", last_name: "", email: "" },
  ]);

  const removeItem = (id) => {
    const newItems = invitationsNumber.filter((item) => item.id !== id);
    setInvitationsNumber(newItems);
  };

  const handleChange = (id, field, value) => {
    setInvitationsNumber((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });
    });
  };

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setRevokedUsersCheckMarck(true);
    setTabValue(newValue);
  };

  const inviteTeamMembers = async () => {
    if (validInvitations()) {
      try {
        const alreadyInvited = dataPendingInvites.filter(e=> invitationsNumber.map(j => j.email == e.email).includes(true));
        if(dataPendingInvites.filter(e=> invitationsNumber.map(j => j.email == e.email).includes(true)).length > 0){
          setCurrentAlertState({
            type: "error",
            message: `you have already invited the user(s) ${alreadyInvited.map(e=>e.email)+" "} is already invited.`,
          });
          openAlert();
          handleClose();
          throw {
            error: 
              {
                data: `The user(s) ${alreadyInvited.map(e=>e.email+", ")} is already invited`
              }
          }
        }
        const res = await Requests.inviteTeamMembers(invitationsNumber);
        setInvitationsNumber([
          { id: 0, first_name: "", last_name: "", email: "" },
        ]);
        setFilteredDataPendingInvites(res.data.pendingTeamMembers);
        setDataPendingInvites(res.data.pendingTeamMembers);
        setCurrentAlertState({
          type: "success",
          message: "Invitation(s) sent",
        });
        openAlert();
      } catch (error) {
        setCurrentAlertState({
          type: "error",
          message: error.response.data,
        });
        openAlert();
      }
    }
  };


  const validInvitations = () => {
    const invalidInvitations = invitationsNumber.filter((invitation) => {
      return (
        !invitation.first_name ||
        !invitation.email ||
        !Utils.validateEmail(invitation.email)
      );
    });
    if (invalidInvitations.length > 0) {
      setCurrentAlertState({
        type: "error",
        message:
          "Make sure that the name and email are not empty and that the email is in a valid format.",
      });
      openAlert();
      return false;
    }
    return true;
  };

  // transform date
  function formattedDate(dateStr){
    const dateObj = new Date(dateStr);
    const formattedDate = dateObj.toLocaleDateString('en-US'); // Adjust the locale if needed
    return formattedDate;
  }

  // handle search of active users
  const [searchActiveTeamMember,setSearchActiveTeamMember]=useState("");
  const [searchPendingTeamMember,setSearchPendingTeamMember]=useState("");
  const [revokedUsersCheckMarck,setRevokedUsersCheckMarck]=useState(true);


  // make the list respect the search and revoke feature
  const filterList = (newTeamMembersData,setNewTeamMembersData,typeOfTeamMember) => {
    let filterResult=newTeamMembersData;
    if(!revokedUsersCheckMarck){
      filterResult= filterResult?.filter(TeamMember=>!!TeamMember.revoked==revokedUsersCheckMarck)
    }
    if (typeOfTeamMember=="activeTeamMember"){
      filterResult =filterResult?.filter(TeamMember=> !!TeamMember.first_name.toLowerCase().includes(searchActiveTeamMember.toLowerCase()) ||  TeamMember.email.toLowerCase().includes(searchActiveTeamMember.toLowerCase()));
    }
    if(typeOfTeamMember=="pendingTeamMember"){
      filterResult =filterResult?.filter(TeamMember=> !!TeamMember.first_name.toLowerCase().includes(searchPendingTeamMember.toLowerCase()) ||  TeamMember.email.toLowerCase().includes(searchPendingTeamMember.toLowerCase()));
    }
    setNewTeamMembersData(revokedSorter(filterResult));
  }

  useEffect(()=>{
    filterList(dataActiveTeamMember, setFilteredDataActiveTeamMember,"activeTeamMember");
  },[searchActiveTeamMember,revokedUsersCheckMarck])

  useEffect(()=>{
    filterList(dataPendingInvites, setFilteredDataPendingInvites,"pendingTeamMember");
  },[searchPendingTeamMember,revokedUsersCheckMarck])
  
  // handle revoke teammember
  const revokeTeamMember = async (teamMemberId,setFilteredDataPendingInvites, setFilteredDataActiveTeamMember,setDataPendingInvites,setDataActiveTeamMember) => {
    try {
      const res = await Requests.revokeTeamMember(teamMemberId);
      setCurrentAlertState({
        type: "success",
        message: "Revoked Teammate",
      });
      setDataPendingInvites(res.data.pendingTeamMembers);
      setDataActiveTeamMember(res.data.activeTeamMembers);
      //setFilteredDataPendingInvites(res.data.pendingTeamMembers);
      filterList(res.data.pendingTeamMembers,setFilteredDataPendingInvites,"pendingTeamMember")
      //setFilteredDataActiveTeamMember(res.data.activeTeamMembers);
      filterList(res.data.activeTeamMembers,setFilteredDataActiveTeamMember,"activeTeamMember")

      openAlert();
    
    } catch (error) {
      setCurrentAlertState({
        type: "error",
        message: error.message,
      });
      openAlert();
    }
  };
  async function handleResendClick(){
    try{
      let teamMember = filteredDataPendingInvites.filter(e=>e._id==selectedTeammember)
    teamMember=teamMember[0];
    if(!teamMember){
      throw new Error("This user is not pending.")
    }else{
      const res = await Requests.inviteTeamMembers([teamMember]);
      setCurrentAlertState({
        type: "success",
        message: "Invitation resended",
      });
      openAlert();
      handleClose();
    }
    }catch (error) {
      setCurrentAlertState({
        type: "error",
        message: error.message,
      });
      openAlert();
    }
    
  }
  function handleRevokeClick(){
    
    let selectedData = filteredDataActiveTeamMember.filter(teamMember=>teamMember._id===selectedTeammember);
    if(selectedData.length==0){
      selectedData=filteredDataPendingInvites.filter(teamMember=>teamMember._id===selectedTeammember);
    }
    if(selectedData[0].revoked){
      setCurrentAlertState({
        type: "error",
        message: "Cannot change the revoked Team Member",
      });
      openAlert();
      handleClose();
    }else if(selectedData[0].email == user.mail){
      setCurrentAlertState({
        type: "error",
        message: "Cannot revoke your self.",
      });
      openAlert();
      handleClose();
    }else{
     revokeTeamMember(
      selectedTeammember,
       setFilteredDataPendingInvites,
       setFilteredDataActiveTeamMember,
       setDataPendingInvites,
       setDataActiveTeamMember
     )
     handleClose();
    }
  }
  // handle change of client user role
  
  const handleChangeClientUserRole = async (event,email) => {
    try{
      
      const resChangeClientUserRole = await Requests.changeClientUserRole(event.target.value.toLowerCase(), email);
      setFilteredDataActiveTeamMember(resChangeClientUserRole.data.activeTeamMembers);
      setDataActiveTeamMember(resChangeClientUserRole.data.activeTeamMembers);
      setCurrentAlertState({
        type: "success",
        message: "Role changed successfully ",
      });
      openAlert();
    } catch (error) {
      setCurrentAlertState({
        type: "error",
        message: error.message,
      });
      openAlert();
    }
    
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTeammember,setSelectedTeammember]=useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedTeammember(event.currentTarget.id)
  };
  const handleClose = () => {
    setSelectedTeammember("")
    setAnchorEl(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container style={{ width: "100%", paddingTop: "50px" }}>
        <CustomSnackBarAlert
          type={currentAlertState.type}
          show={showAlert}
          close={closeAlert}
        >
          {currentAlertState.message}
        </CustomSnackBarAlert>
        <Typography variant="h1">Invite Teammates</Typography>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="informationDiv">
            <FontAwesomeIcon
              icon={faUsers}
              style={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            />
            <Typography variant="p">Divide and conquer</Typography>
            <Divider className="invisibleDivider" />
            <Typography variant="p" className="informationBody">
              Allow multiple team members to order through one billing account
            </Typography>
          </div>

          <div className="informationDiv">
            <FontAwesomeIcon
              icon={faPen}
              style={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            />
            <Typography variant="p">Create a content hub</Typography>
            <Divider className="invisibleDivider" />

            <Typography variant="p" className="informationBody">
              Access and edit files across your entire team
            </Typography>
          </div>

          <div className="informationDiv">
            {/* <ReceiptIcon
              sx={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            /> */}

            <FontAwesomeIcon
              icon={faReceipt}
              style={{ color: theme.palette.fourth.main }}
              className="informationIcon"
            />
            <Typography variant="p">Centralize billing control</Typography>
            <Divider className="invisibleDivider" />

            <Typography variant="p" className="informationBody">
              Review transaction history, manage payments and invoices, and
              update billing settings for your entire team
            </Typography>
          </div>
        </div>
        {user.client_user_role === "admin" ? (
          <div className="formsContainer">
            <Typography variant="h2">Invite a User</Typography>

            <ul style={{ padding: 0 }}>
              {invitationsNumber.map((invitation) => (
                <li
                  key={invitation.id}
                  index={invitation.id}
                  style={{ listStyle: "none", marginBottom: "15px" }}
                >
                  <FormUser
                    index={invitation.id}
                    data={invitation}
                    handleChange={handleChange}
                    removeItem={removeItem}
                  />
                </li>
              ))}
            </ul>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Button
                onClick={() => {
                  if (validInvitations()) {
                    setInvitationsNumber([
                      ...invitationsNumber,
                      { id: invitationsNumber.length + 1 },
                    ]);
                  }
                }}
                startIcon={
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: "14px" }} />
                }
                sx={{ textTransform: "none" }}
              >
                Invite Another User
              </Button>
              <Divider sx={{ my: 1, borderColor: "transparent" }} />

              <Button variant="contained" onClick={inviteTeamMembers}>
                Send Invite(s)
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Divider sx={{ my: 2 }} />

        <Box sx={{ borderBottom: 1 }}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Active Users" {...a11yProps(0)} />
            {isLoadingPendingInvites ? (
              <Tab label="Pending Invites (LOADING...)" {...a11yProps(1)} />
            ) : (
              <Tab
                label={`Pending Invites (${dataPendingInvites?.length})`}
                {...a11yProps(1)}
              />
            )}
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <div className="teamMates">
            {isLoadingActiveTeamMember ? (
              <div>Loading Active Teammates...</div>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Toolbar className="usersToolbar">
                  <Typography variant="h5" className="toolbarLabel">
                    {filteredDataActiveTeamMember?.length} teammate(s)
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(event) =>
                            setRevokedUsersCheckMarck(event.target.checked)
                          }
                        />
                      }
                      label="Revoked users"
                      variant="h5"
                    />
                    <SearchBar
                      placeholder="Search name or email"
                      onChange={(event) =>
                        setSearchActiveTeamMember(event.target.value)
                      }
                    />
                  </div>
                </Toolbar>

                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) =>
                            headCell.id === "actionsHead" ? (
                              user.client_user_role === "admin" && (
                                <TableCell
                                  key={headCell.id}
                                  align={"left"}
                                  sx={{
                                    minWidth: "75px !important",
                                  }}
                                >
                                  {headCell.label}
                                </TableCell>
                              )
                            ) : (
                              <TableCell
                                key={headCell.id}
                                align={"left"}
                                sx={{
                                  minWidth: "75px !important",
                                }}
                              >
                                {headCell.label}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredDataActiveTeamMember?.length !== 0 ? (
                          <>
                            {filteredDataActiveTeamMember?.map(
                              (teamMemberData, i) => {
                                return (
                                  <TableRow
                                    key={i}
                                    id={teamMemberData._id}
                                    className={
                                      teamMemberData?.revoked
                                        ? "revokedTeamMember"
                                        : ""
                                    }
                                  >
                                    <TableCell
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {teamMemberData.first_name}
                                    </TableCell>
                                    <TableCell>
                                      {teamMemberData.email}
                                    </TableCell>
                                    <TableCell>
                                      {formattedDate(teamMemberData.createdAt)}
                                    </TableCell>
                                    {
                                       teamMemberData?.revoked ? 
                                      <TableCell>
                                       REVOKED
                                     </TableCell>
                                     :
                                    <>
                                    {user.client_user_role === "admin" ? (
                                      <TableCell>
                                        <FormControl>
                                          <Select
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            value={
                                              teamMemberData.client_user_role
                                            }
                                            onChange={(event) =>
                                              handleChangeClientUserRole(
                                                event,
                                                teamMemberData.email
                                              )
                                            }
                                            sx={{ maxHeight: "40px" }}
                                          >
                                            <MenuItem value="admin">
                                              Manager
                                            </MenuItem>
                                            <MenuItem value="user">
                                              User
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {teamMemberData?.client_user_role}
                                      </TableCell>
                                    )}
                                  </>
                                    }


                                    {user.client_user_role === "admin" && (
                                      <TableCell>
                                        <Box title="Revoke">
                                          <Button
                                            id={teamMemberData._id}
                                            aria-controls={
                                              open ? "basic-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              open ? "true" : undefined
                                            }
                                            onClick={handleClick}
                                          >
                                            <MoreHorizIcon
                                              style={{
                                                color:
                                                  theme.palette.gray.strong,
                                              }}
                                            />
                                          </Button>
                                          <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                              "aria-labelledby": "basic-button",
                                            }}
                                          >
                                            <MenuItem
                                              style={{ boxShadow: "none" }}
                                              onClick={handleRevokeClick}
                                            >
                                              Revoke
                                            </MenuItem>
                                            <MenuItem onClick={handleResendClick}>Resend</MenuItem>
                                          </Menu>
                                        </Box>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <>
                            {searchActiveTeamMember?.length !== 0 ? (
                              <div>There are no matches with your search</div>
                            ) : (
                              <div>There are no Active Teammates yet</div>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="teamMates">
            {isLoadingPendingInvites ? (
              <div>Loading Pending Teammates...</div>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Toolbar className="usersToolbar">
                  <Typography variant="h5" className="toolbarLabel">
                    {filteredDataPendingInvites?.length} teammate(s)
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(event) =>
                            setRevokedUsersCheckMarck(event.target.checked)
                          }
                        />
                      }
                      label="Revoked users"
                      variant="h5"
                    />
                    <SearchBar
                      placeholder="Search name or email"
                      onChange={(event) =>
                        setSearchPendingTeamMember(event.target.value)
                      }
                    />
                  </div>
                </Toolbar>

                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {headCells.map((headCell) =>
                            headCell.id === "actionsHead" ? (
                              user.client_user_role === "admin" && (
                                <TableCell
                                  key={headCell.id}
                                  align={"left"}
                                  sx={{
                                    minWidth: "75px !important",
                                  }}
                                >
                                  {headCell.label}
                                </TableCell>
                              )
                            ) : (
                              <TableCell
                                key={headCell.id}
                                align={"left"}
                                sx={{
                                  minWidth: "75px !important",
                                }}
                              >
                                {headCell.label}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoadingPendingInvites ? (
                          <Typography variant="h2" sx={{ my: 4 }}>
                            Loading Pending Teammates...
                          </Typography>
                        ) : (
                          <>
                            {filteredDataPendingInvites?.length !== 0 ? (
                              <>
                                {filteredDataPendingInvites?.map(
                                  (pendingTeamMemberData, i) => {
                                    return (
                                      <TableRow
                                        key={i}
                                        id={pendingTeamMemberData._id}
                                        className={
                                          pendingTeamMemberData?.revoked
                                            ? "revokedTeamMember"
                                            : ""
                                        }
                                      >
                                        <TableCell>
                                          {pendingTeamMemberData.first_name}
                                        </TableCell>
                                        <TableCell>
                                          {pendingTeamMemberData.email}
                                        </TableCell>
                                        <TableCell>
                                          {formattedDate(
                                            pendingTeamMemberData.createdAt
                                          )}
                                        </TableCell>

                                        <TableCell>{pendingTeamMemberData.revoked?"REVOKED":"User"}</TableCell>

                                        {user.client_user_role === "admin" && (
                                          <TableCell>
                                            <Box title="Revoke">
                                              <Button
                                                id={pendingTeamMemberData._id}
                                                aria-controls={
                                                  open
                                                    ? "basic-menu"
                                                    : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                  open ? "true" : undefined
                                                }
                                                onClick={handleClick}
                                              >
                                                <MoreHorizIcon
                                                  style={{
                                                    color:
                                                      theme.palette.gray.strong,
                                                  }}
                                                />
                                              </Button>
                                            </Box>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                  }
                                )}
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  <MenuItem onClick={handleRevokeClick}>
                                    Revoke
                                  </MenuItem>
                                  <MenuItem onClick={handleResendClick}>Resend</MenuItem>
                                </Menu>
                              </>
                            ) : (
                              <>
                                {searchPendingTeamMember?.length !== 0 ? (
                                  <Typography variant="h2" sx={{ my: 4 }}>
                                    There are no matches with your search
                                  </Typography>
                                ) : (
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Typography variant="h2" sx={{ my: 4 }}>
                                      Invite Sent
                                    </Typography>
                                    <Typography
                                      variant="h4"
                                      className="body"
                                      sx={{
                                        fontWeight: "300 !important",
                                        mb: 4,
                                      }}
                                    >
                                      Pending invitations will be shown here
                                      when available.
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            )}
          </div>
        </TabPanel>
      </Container>
    </ThemeProvider>
  );
}
