import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Slider,
  DialogActions,
} from "@mui/material";
import useShow from "../../hooks/useShow";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import useFetch from "../../hooks/useFetch";
import useZustant from "../../hooks/useZustant";
import Requests from "../../logic/Requests";

const DEFAULT_SETTINGS = {
  playback: true,
  autoRewindTime: 3,
  playback_speed: 1,
};

function AutoRewind(props) {
  const { show, open, close } = useShow();
  const { handleRewind, handlePlayBack, currentTimeToDoRewind } = props;
  const [playback, setPlayback] = React.useState(true);
  const [playback_speed, setPlaybackSpeed] = React.useState(1);
  const [autoRewindTime, setAutoRewindTime] = React.useState(3);
  const { user } = useZustant();
  const { data, isLoading } = useFetch(Requests.getFFUser, user.id);

  useEffect(() => {
    if (!isLoading) {
      setPlaybackSpeed(
        data.playback_speed
          ? data.playback_speed
          : DEFAULT_SETTINGS.playback_speed
      );
      setPlayback(data.playback ? data.playback : DEFAULT_SETTINGS.playback);
      currentTimeToDoRewind.current = typeof data.autoRewindTime === "number"? data.autoRewindTime : DEFAULT_SETTINGS.autoRewindTime
      setAutoRewindTime(
        typeof data.autoRewindTime === "number"
          ? data.autoRewindTime
          : DEFAULT_SETTINGS.autoRewindTime
      );
    }
  }, [data]);

  const onChangeAutoRewindTime = (e) => {
    currentTimeToDoRewind.current = e.target.value.toString()
    setAutoRewindTime(e.target.value.toString());
  };

  const handleSave = () => {
    if (user) {
      currentTimeToDoRewind.current = autoRewindTime
      let data = {};
      data.playback = playback;
      data.autoRewindTime = autoRewindTime;
      data.playback_speed = playback_speed;
      Requests.saveUser(user._id, data).then(() => {
        close();
        setPlaybackSpeed(data.playback_speed);
        currentTimeToDoRewind.current = data.autoRewindTime
        setAutoRewindTime(data.autoRewindTime);
        currentTimeToDoRewind.current = data.autoRewindTime
      });
    }
  };

  useEffect(() => {
    const audio = document.getElementById("audio");

    if (audio) {
      audio.playbackRate = playback_speed;
    }
  }, [playback_speed]);

  useEffect(() => {
    handleRewind(autoRewindTime);
    handlePlayBack(playback);
  }, [autoRewindTime, playback]);

  return (
    <>
      <IconButton onClick={open} style={{ color: "black", marginLeft: 10 }}>
        <FontAwesomeIcon icon={faRepeat} />
      </IconButton>
      <Dialog open={show} onClose={close}>
        <DialogTitle style={{ fontWeight: "bold" }}>Auto-Rewind</DialogTitle>
        <Box
          style={{
            maxWidth: 250,
            minWidth: 250,
            padding: 30,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={playback ? true : false}
                onChange={() => setPlayback(!playback)}
                name="playback"
              />
            }
            label="Rewind"
          />
          <br />
          {playback ? (
            <TextField
              label="Auto-rewind time (in secs)"
              type="number"
              style={{ marginTop: 15, marginBottom: 20, width: "100%" }}
              onChange={onChangeAutoRewindTime}
              variant="standard"
              value={autoRewindTime}
            />
          ) : (
            <></>
          )}
          <Typography color="primary">
            Playback Speed - {Math.round(playback_speed * 100)}%
          </Typography>
          <Slider
            aria-labelledby="input-slider"
            id="playback_speed"
            min={50}
            max={225}
            onChange={(e) => {
              setPlaybackSpeed(eval((e.target.value / 100).toFixed(2)));
            }}
            value={playback_speed * 100}
          />
        </Box>
        <DialogActions>
          <Button onClick={close} style={{ marginTop: 20 }}>
            Close
          </Button>
          <Button onClick={handleSave} style={{ marginTop: 20 }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AutoRewind;
