import EditorUtils from "../../../editorUtils";

// key handlers
const handleEnter = (e, { AddSpeaker }) => {
    e.preventDefault();
    const parentNode = e.target.classList.contains("SpeakerInput")
        ? e.target.parentNode.parentNode.parentNode
        : e.target.parentNode.parentNode
    parentNode.insertAdjacentElement("afterend", AddSpeaker())
    window.tinymce.activeEditor.selection.setCursorLocation(parentNode.nextElementSibling.querySelector(".EditorContent"), true)
}

const handleBackspace = (e, { editor }) => {
    const parentNode = e.target.classList.contains("SpeakerInput")
        ? e.target.parentNode.parentNode.parentNode
        : e.target.parentNode.parentNode
    // detecting if it is the last row
    if (editor.getBody().querySelectorAll(".RowSpeaker").length <= 1) {
        const firstRow = editor.getBody().querySelectorAll(".RowSpeaker")[0]
        if(firstRow && firstRow.querySelector(".EditorContent").innerHTML.replace(/&nbsp;/g, '').length === 0) {
            e.preventDefault()
            return
        } else {
            if(!firstRow) {
                e.preventDefault()
                return
            }
        }
    }
    if (e.target.innerHTML.replace(/&nbsp;/g, '').length === 0 || e.target.innerHTML.replace(/&nbsp;/g, '') === '<br data-mce-bogus="1">') {
        if (parentNode.previousElementSibling) window.tinymce.activeEditor.selection.setCursorLocation(parentNode.previousElementSibling.querySelector(".EditorContent"), true)
        parentNode.remove()
    }
}

// events
const handleClick = (e, { setEditorFocus, user, content, WordAlignment, editor }) => {
    let targetNode = e.target
    if (e.target.classList[0]) {
        setEditorFocus(true);
    } else {
        setEditorFocus(false);
    }
    if (targetNode.nodeName === 'DIV'){
        const editorContentNode = targetNode.querySelector('.EditorContent')
        const range = document.createRange() 
        range.selectNodeContents(editorContentNode) 
        const maxOffset = range.endOffset
        editor.selection.setCursorLocation(editorContentNode, maxOffset)
    }   
   /* if (
        (user.rolename === "QA" ||
            user.rolename === "Admin" ||
            user.rolename === "PM") &&
        content.json
    ) {
        WordAlignment(editor);
    }*/
}

const handleKeyDown = (e, { editor, AddSpeaker, hasSpeakersColumns = true }) => {
    if (e.target.classList[0] === "SpeakerInput") {
        let inputContent = e.target.innerHTML;
        if (inputContent === "Add speaker" && e.key !== "Backspace") {
            e.target.innerHTML = "";
        } else if (
            inputContent === "Add speaker" &&
            e.key === "Backspace"
        ) {
            e.preventDefault();
        }
    }
    if (e.key === "Enter") {
        handleEnter(e, { AddSpeaker, hasSpeakersColumns })
        EditorUtils.cleanInputs();
        window.tinymce.activeEditor.dom.remove(
            window.tinymce.activeEditor.dom.get("SpeakerList")
        );
    }
    if (e.key === "Backspace") {
        handleBackspace(e, { editor })
    }
}

const handleKeyUp = (e, { lastSetTimeOutId, handleSave, editor }) => {
    if (e.keyCode === 32 || e.key === "Enter") {
        editor.undoManager.add()
    }
    clearTimeout(lastSetTimeOutId.current)
    lastSetTimeOutId.current = setTimeout(() => handleSave("In Progress", true), 60000)
}

const handleUndo = (e, { editor }) => {
    const lastRow = editor.contentDocument.body.lastChild
    const editorContentNode = lastRow.querySelector('.EditorContent')
    const range = document.createRange()
    range.selectNodeContents(editorContentNode) 
    const maxOffset = range.endOffset
    editor.selection.setCursorLocation(editorContentNode, maxOffset)
}

export {
    handleClick,
    handleKeyDown,
    handleKeyUp,
    handleUndo
}
