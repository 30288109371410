import useShow from "./useShow";
import { useState } from "react";
const useAlert = () => {
  const { show, open, close } = useShow();

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };

  return { show, close, currentAlertState, handleAlert };
};

export default useAlert;