import { Modal, Box, Button, TextField, Input } from "@mui/material";
import "../../App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import {
  LocalizationProvider,
  TimeField,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Requests from "../../logic/Requests";
import { useState } from "react";
import moment from "moment";
import Config from "../../config";
import { useParams } from "react-router-dom";

export default function SendBackModal(props) {
  const { show, close, dataUnit, handleAlert } = props;
  const [disabled, setDisabled] = useState(false)
  const [note, setNote] = useState(null);
  const [due, setDue] = useState(null);
  const [dueTime, setDueTime] = useState(null);
  let { idProject,idTranscript } = useParams();

  const sendBack = () => {
    try {
      setDisabled(true)
      let tomorrow = moment().add(1, "days");

      const trueDue = due ? due : tomorrow.format("YYYY-MM-DD");
      const trueDueTime = dueTime ? dueTime : tomorrow.format("HH:mm:ss");

      const dueDate = dueTime
        ? moment(`${trueDue} ${`${dueTime.$H}:${dueTime.$m}:${dueTime.$s} `}`)
        : moment(`${trueDue} ${trueDueTime}`).utc().format();

      let doc = {
        due: dueDate,
        reassign_note: note == null ? "" : note ,
      };

      Requests.sendBack(dataUnit._id, doc).then((res) => {
        handleAlert("success", "Sent back successfully");
        close()
        window.location.replace(`${Config.oldUI}/projects/${idProject}/transcript/${idTranscript}`);
      });
    } catch (err) {
      handleAlert("error", "Error sending back");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Modal open={show} onClose={close}>
          <Box className="sendBackModal">
            <div style={{ marginBottom: "10px" }}>
              <span >
                Any notes or feedback to send back to the transcriber?
              </span>
              <TextField
                fullWidth
                multiline
                rows={4}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                style={{ marginTop: "10px" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span style={{ marginRight: "5px" }}>Due Date:</span>
              <Input
                type="date"
                sx={{ borderBottom: "1px solid black" }}
                onChange={(e) => setDue(e.target.value)}
                value={due}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "5px" }}>Due Time:</span>
              <TimeField
                size="small"
                onChange={(newValue) => setDueTime(newValue)}
                value={dueTime}
              />
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="red"
                onClick={close}
                sx={{ mr: 2, color: "white !important" }}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={sendBack}
                sx={{ mr: 2, color: "white !important" }}
                variant="contained"
                color="success"
                disabled={disabled}
              >
                Send back
              </Button>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
