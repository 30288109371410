import { Modal, Box, Grid, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "../../App.css";
import useZustant from "../../hooks/useZustant";
import { isMacOs } from "react-device-detect";
import InputhotKey from "./InputHotkey.js";
import Requests from "../../logic/Requests";
import { Container, ThemeProvider } from "@mui/system";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAlert from "../../hooks/useAlert";
import {
  faBackward,
  faForwardFast,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import CustomSnackBarAlert from "../global/CustomSnackBarAlert";

const DEFAULT_KEYS = {
  play: {
    keyCode: 120,
    key: "F9",
    ctrlKey: false,
    label: (
      <label>
        Play Media <FontAwesomeIcon icon={faPlay} />
      </label>
    ),
    cmd: "play",
  },
  stop: {
    keyCode: 115,
    key: "F4",
    ctrlKey: false,
    label: (
      <label>
        Stop Media <FontAwesomeIcon icon={faStop} />
      </label>
    ),
    cmd: "stop",
  },
  rwd: {
    keyCode: 118,
    key: "F7",
    ctrlKey: false,
    label: (
      <label>
        Rewind Media <FontAwesomeIcon icon={faBackward} />
      </label>
    ),
    cmd: "rwd",
  },
  fwd: {
    keyCode: 119,
    key: "F8",
    ctrlKey: false,
    label: (
      <label>
        Fast Forward Media <FontAwesomeIcon icon={faForwardFast} />
      </label>
    ),
    cmd: "fwd",
  },
  cross: {
    keyCode: 82,
    key: "r",
    ctrlKey: true,
    label: "CROSSTALK Tag",
    cmd: "cross",
  },
  inaud: {
    keyCode: 68,
    key: "d",
    ctrlKey: true,
    label: "INAUDIBLE Tag",
    cmd: "inaud",
  },
  sync: {
    keyCode: 72,
    key: "h",
    ctrlKey: true,
    label: "SYNC Timestamp Tag",
    cmd: "sync",
  },
  moderator: {
    keyCode: 77,
    key: "m",
    ctrlKey: true,
    label: "MODERATOR:",
    cmd: "moderator",
  },
  respondant: {
    keyCode: 69,
    key: "e",
    ctrlKey: true,
    label: "RESPONDENT:",
    cmd: "respondant",
  },
};

export default function TranscriberCustomHotKeysModal(props) {
  const { show, close } = props;

  const {
    show: showAlert,
    close: closeAlert,
    currentAlertState,
    handleAlert,
  } = useAlert();
  const { user, getUser } = useZustant();

  const [hotKeys, setHotKeys] = useState(null);

  useEffect(() => {
    setHotKeys(() => {
      if (user.scribeKeys) {
        return Object.entries(user.scribeKeys).map(([key, value]) => {
          return {
            ...DEFAULT_KEYS[key],
            ...value,
          };
        });
      } else {
        return DEFAULT_KEYS;
      }
    });
  }, [show]);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const object = {};
      Object.entries(hotKeys).forEach(([key, value]) => {
        object[value.cmd] = {
          ctrlKey: value.ctrlKey,
          key: value.key,
          keyCode: value.keyCode,
        };
      });

      await Requests.saveUser(user._id, { scribeKeys: object });

      close();
      getUser();
    } catch (e) {
      handleAlert("error", "There was an error while updating the hotKeys");
    }
  };

  function handleHotKeys(key, updatedKeyCode) {
    setHotKeys((prevHotKeys) => {
      return prevHotKeys.map((hotKey) => {
        if (hotKey.cmd === key) {
          return { ...hotKey, ...updatedKeyCode };
        }
        return hotKey;
      });
    });
  }

  return hotKeys ? (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        open={showAlert}
        close={closeAlert}
        type={currentAlertState}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Modal open={show} onClose={close}>
        <Container
          className="transcriberHotkeysModal"
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,

            px: 4,
            pt: 2,
          }}
        >
          <Typography variant="h3" sx={{ ml: 3, mb: 3, fontWeight: 400 }}>
            Keyboard Shortcuts
          </Typography>
          <form onSubmit={onSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "red",
              }}
            >
              {Object.entries(hotKeys).map(([key, value]) => {
                return hotKeys[key].cmd === "play" ||
                  value.cmd === "stop" ||
                  value.cmd === "rwd" ||
                  value.cmd === "fwd" ||
                  value.cmd === "cross" ||
                  value.cmd === "inaud" ? (
                  <Grid item className="gridItem">
                    <InputhotKey
                      isMacOs={isMacOs}
                      value={value}
                      name={value.cmd}
                      handleHotKeys={handleHotKeys}
                      hotKeys={hotKeys}
                      key={key}
                      handleAlert={handleAlert}
                    />
                  </Grid>
                ) : null;
              })}
            </Grid>
            <div className="hotKeysButtonsCont">
              <Button
                onClick={() => close()}
                color="secondary"
                sx={{ marginRight: 2, color: theme.palette.red.main }}
              >
                close
              </Button>
              <Button type="submit" sx={{ color: theme.palette.primary.main }}>
                save
              </Button>
            </div>
          </form>
        </Container>
      </Modal>
    </ThemeProvider>
  ) : null;
}
