import { Button, IconButton, Menu, Divider, MenuItem } from "@mui/material"
import { ArrowDropDown, Menu as MenuIcon } from "@mui/icons-material"
import { useState } from "react"
import useZustant from "../hooks/useZustant"
import { useNavigate } from "react-router-dom"
import Requests from "../logic/Requests"
import RenderMenuItems from "./navbar/RenderMenuItems"
import Config from "../config"

export default function MenuDropDown() {
    const { user, clearUser, changeSidebarValue } = useZustant()
    const [anchorEl, setAnchorEl] = useState(null)
    const navigate = useNavigate()
    //const location = useLocation()
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        try {
            document.cookie =  `comesFromTranscriberHub=true; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${Config.cookieDomain
            }; path=/;"`;
            Requests.logout().then((res) => {
                if (res.status === 200) {
                    clearUser()
                    navigate("/")
                }
            });
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            {user.rolename === "Client" && (
                <IconButton
                    size="large"
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Button
                color="white"
                sx={{ textTransform: "capitalize", fontSize: "18px" }}
                onClick={handleClick}
            >
                Hi, {user.firstname} <ArrowDropDown />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <RenderMenuItems user={user} handleClose={handleClose} />
                <Divider sx={{ borderColor: "white !important" }} />
                <MenuItem onClick={() => logout()}>Log Out</MenuItem>
            </Menu>
        </>
    )
}
