import "../../styles/TranscriberQueue.css";
import { TableRow, TableCell } from "@mui/material";
import Utils from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeHigh,
  faFilm,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import ActionsByUnitState from "../ActionsByUnitState";
import useZustant from "../../hooks/useZustant";

export default function TranscriberHubRow(props) {
  const { decimalDateConverter } = Utils;
  const { user } = useZustant();
  const {
    row,
    transcriberHasUnit,
    changeUnitState,
    handleChangeTranscriberState,
    openUnitError,
    showMediaModal,
    unitToShow,
    location,
  } = props;

  const { transformPayRate } = Utils;

  const calculateTranscriberPayRate = (
    transcript_transcriber_rate,
    project_transcriber_rate,
    assignment_transcriber_rate,
    transform
  ) => {
    let payRate;
    if (assignment_transcriber_rate && assignment_transcriber_rate > 0) {
      payRate = assignment_transcriber_rate;
    } else if (transcript_transcriber_rate && transcript_transcriber_rate > 0) {
      payRate = transcript_transcriber_rate;
    } else if (project_transcriber_rate && project_transcriber_rate > 0) {
      payRate = project_transcriber_rate;
    } else {
      payRate = user.rate ? user.rate.amount : 0;
    }

    if (transform) {
      return transformPayRate(payRate, row.unit_length);
    } else {
      return "$" + payRate.toFixed(2);
    }
  };

  const renderSepakers = (speakers) => {
    let speakersNumber = parseInt(speakers);
    if (speakersNumber <= 3 || !speakersNumber) {
      return speakers;
    } else {
      return "3+";
    }
  };

  return (
    <TableRow key={row.unit_id}>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {`${row.unit_id.substr(row.unit_id.length - 8)}`}
        </p>
      </TableCell>
      {user.rolename == "Transcriber" && <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {calculateTranscriberPayRate(
            row.transcript_transcriber_rate,
            row.project_transcriber_rate,
            row.assignment_transcriber_rate,
            false
          )}
        </p>
      </TableCell>}
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {/^([0-9]{2}):([0-9]{2}):([0-9]{2})$/.test(row.duration)
            ? row.duration[1] === "0"
              ? row.duration.substring(3, row.duration.length)
              : row.duration
            : Utils.toHHMMSS(row.duration)}
        </p>
      </TableCell>
      {user.rolename == "Transcriber" && <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {calculateTranscriberPayRate(
            row.transcript_transcriber_rate,
            row.project_transcriber_rate,
            row.assignment_transcriber_rate,
            true
          )}
        </p>
      </TableCell>}
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {row.language}
        </p>
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {row.timestamps}
        </p>
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
        }}
      >
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {user.rolename == "Transcriber" ? decimalDateConverter(row.assignment_due) : decimalDateConverter(row.transcript_due)}
        </p>
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {renderSepakers(row.speakers)}
        </p>
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: 500,
            textAlign: "start",
            wordSpacing: "normal",
          }}
        >
          {row.text_format}
        </p>
      </TableCell>
      <TableCell style={{
            fontSize: 16,
            fontWeight: 500,
            textAlign: "center",
            wordSpacing: "normal",
            justifyContent: "center",
          }}>
        {unitToShow !== row.unit_id ? (
          row.media === "audio" ? (
            <IconButton
              onClick={() => showMediaModal(row.unit_id, row.media)}
              style={{ marginBottom: 2, textAlign: "center" }}
            >
              <FontAwesomeIcon
                icon={faVolumeHigh}
                color={"#37474F"}
                style={{ fontSize: 18, textAlign: "center" }}
              />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => showMediaModal(row.unit_id, row.media)}
              style={{ marginBottom: 2, textAlign: "center" }}
            >
              <FontAwesomeIcon
                icon={faFilm}
                style={{ fontSize: 18, textAlign: "center" }}
                color={"#004D40"}
              />
            </IconButton>
          )
        ) : (
          <IconButton
            onClick={() => showMediaModal(row.unit_id, row.media, true)}
          >
            <div
              style={{
                height: 30,
                display: "flex",
                flexDirection: "column",
                padding: 0,
                margin: 0,
              }}
            >
              <FontAwesomeIcon
                icon={faPause}
                style={{ marginLeft: 5 }}
              ></FontAwesomeIcon>
              <div id="bars">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </div>
          </IconButton>
        )}
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {row.rejected_count}
        </p>
      </TableCell>
      <TableCell>
        <ActionsByUnitState
          assigned_to={row.assigned_to}
          reviewed_by={row.reviewed_by}
          new_editor={row.new_editor}
          unit_content={row.unit_content}
          unitId={row.unit_id}
          project_id={row.project ?? row.project_id}
          transcript_id={row.transcript ?? row.transcript_id}
          state={row.state}
          transcriberHasUnit={transcriberHasUnit}
          changeUnitState={changeUnitState}
          handleChangeTranscriberState={handleChangeTranscriberState}
          openUnitError={openUnitError}
          manual_assigned={row.manual_assigned}
        />
      </TableCell>
    </TableRow>
  );
}