import React from "react";
import { makeStyles } from "@material-ui/core";
import { TextField, InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

const useStyles = makeStyles({
  input: {
    height: "2rem",
  },
});

function SearchBar(props) {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      variant="outlined"
      InputProps={{
        className: classes.input,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
