import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  TextareaAutosize,
  DialogActions,
  RadioGroup,
  TextField,
  Radio,
} from "@mui/material";
import useShow from "../../hooks/useShow";
import CustomSnackBarAlert from "../global/CustomSnackBarAlert";
import useZustant from "../../hooks/useZustant";
import { useNavigate } from "react-router-dom";
import Config from "../../config/index";
function SubmitUnit(props) {
  const { saveUnit } = props;
  const { show, open, close } = useShow();
  const { show: showAlert, open: openAlert, close: closeAlert } = useShow();
  const [checked, setChecked] = React.useState(true);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("error");
  const [feedback, setFeedback] = React.useState("");
  const [textAreaLabel, setTextAreaLabel] = React.useState(
    "Please describe the problems or reasons you are submitting the assignment in 'incomplete' state"
  );
  const { user } = useZustant();
  const navigate = useNavigate();

  const options = [
    {
      value: "unsupportedLanguage",
      label: "Unsupported Language",
    },
    {
      value: "noSpeech",
      label: "No Speech Detected",
    },
    {
      value: "fileLong",
      label: "File is too long",
    },
    {
      value: "accent",
      label: "Difficult Accent",
    },
    {
      value: "poorQuality",
      label: "Poor Audio Quality",
    },
    {
      value: "difficultTopic",
      label: "Difficult topic to transcribe",
    },
    {
      value: "time",
      label: "Do not have enough time to complete",
    },
    {
      value: "alignmentIssue",
      label: "Alignment Issue",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  const [currentSelect, setCurrentSelect] = useState(null);
  const handleSubmit = async () => {
    try {
      if (!checked) {
        if (feedback === "" || feedback=="other") {
          setAlertType("error");
          setAlertContent(
            "Please describe the reason why you want to submit the task in 'incomplete' status."
          );
          openAlert();
        } else {
          await saveUnit("Incomplete", { comments: feedback });
          close();
          if(user.rolename === "Transcriber"){
            navigate(`/projects/queue/${user._id}`);
          }else{
            window.location.replace(`${Config.oldUI}`+ window.location.pathname.split("unit")[0]);
          }
        }
      } else {
        await saveUnit("Ready for Review", { comments: feedback });
        close();
        navigate(`/projects/queue/${user._id}`);
      }
    } catch (error) {
      console.error(error);
      setAlertType("error");
      setAlertContent("An error occurred while submitting the unit");
      openAlert();
    }
  };

  useEffect(() => {
    if (checked) {
      const text =
        (user.rolename === "Transcriber" || user.rolename === "QA")
          ? "Optional: Any comments or feedback for our Project Managers about this transcript?"
          : "Optional: Any comments or feedback for the transcriber about this transcript?";
      setTextAreaLabel(text);
    } else {
      setTextAreaLabel(
        "Please describe the problems or reasons you are submitting the assignment in 'incomplete' state"
      );
    }
  }, [checked]);
  return (
    <>
      <CustomSnackBarAlert show={showAlert} close={closeAlert} type={alertType}>
        {alertContent}
      </CustomSnackBarAlert>
      <Button
        variant="contained"
        color="success"
        style={{ color: "white" }}
        onClick={open}
      >
        Submit
      </Button>
      <Dialog open={show} maxWidth="lg" onClose={close}>
        <DialogTitle sx={{ textAlign: "center" }}>Submit</DialogTitle>
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            minWidth: "46rem",
          }}
        >
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label="Has this assignment been transcribed in its entirety?"
              labelPlacement="start"
              style={{
                minWidth: 400,
                margin: 0,
                alignItems: "center",
                justifyContent: "start",
              }}
            />          
            <Typography variant="body1" sx={{ my: 1, fontWeight: "bold" }}>
              {textAreaLabel}
            </Typography>
            {
              !checked?
              <div>
                <RadioGroup
                  onChange={(e) => {
                    setCurrentSelect(e.target.value);
                    setFeedback(e.target.value)
                  }}
                >
                  {options.map(((option, key) => {
                    return (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio size="small" />}
                        label={option.label}
                        key={key}
                      />
                    );
                  }))}
                </RadioGroup>
               { currentSelect=="other"&&
                <TextareaAutosize
                  aria-label="empty textarea"
                  style={{"width":"100%"}}
                  minRows={5}
                  onChange={(e) => setFeedback(e.target.value)}
                />}
              </div> 
              : 
              <TextareaAutosize
                aria-label="empty textarea"
                minRows={5}
                onChange={(e) => setFeedback(e.target.value)}
              />
              }
              
          </div>
          <DialogActions>
            <Button
              onClick={close}
              color="red"
              sx={{ color: "white !important", mr: 2 }}
              variant="contained"
            >
              {" "}
              Cancel
            </Button>
            <Button onClick={handleSubmit} autoFocus variant="contained">
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default SubmitUnit;
