import React, { useEffect, useRef, useMemo } from "react";

import { FormControl, InputBase, InputLabel } from "@mui/material";

function LastNameInput(props) {
  const input2Ref = useRef(null);
  const { last_name, handleChange, index , inputStyle} = props;

  const memoValue = useMemo(() => {
    return last_name;
  }, [last_name]);

  useEffect(() => {
    if (memoValue !== "") {
      input2Ref.current.focus();
    }
  }, [memoValue]);

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{ marginRight: "15px !important", width: "500px"  }}
    >
      <InputLabel shrink htmlFor="email">
        LAST NAME
      </InputLabel>
      <InputBase
        sx={inputStyle}
        id="last_name"
        name="last_name"
        onChange={(e) => handleChange(index, "last_name", e.target.value)}
        value={memoValue}
        inputRef={input2Ref}
      />
    </FormControl>
  );
}

export default React.memo(LastNameInput);
