import Requests from "../logic/Requests";
import { React, useEffect, useState } from "react";

export default function MediaModal(props) {
  const { showMedia, mediaUrl, mediaType, finish, mediaModalRef } = props;
  const [start, setStart] = useState(true);
  const updateBar = (e) => {
    if (start) {
      setStart(false);
    }
    if (
      e.nativeEvent.srcElement.currentTime === e.nativeEvent.srcElement.duration
    ) {
      setStart(true);
      finish();
    }
  };

  const setCurrentTime = async (e) => {
    e.nativeEvent.srcElement.currentTime =
    e.nativeEvent.srcElement.duration / 2;
    await mediaModalRef.current.play()
  };



  useEffect(() => {
    if (showMedia === false) {
      setStart(true);
    }
  }, [showMedia]);

  return (
    <>
      <div className="mediaModal-Background" style={{ display: "none" }}>
        {mediaType === "video" ? (
          <video
            controls
            autoPlay
            id="MediaFileContent"
            onTimeUpdate={updateBar}
            onEnded={finish}
            onPlay={setCurrentTime}
            style={{ display: "none" }}
            src={`${Requests.getEndpoint()}/unit/download/${mediaUrl}${".mp4"}`}
          ></video>
        ) : (
          <audio
            controls
            autoPlay
            id="MediaFileContent"
            onTimeUpdate={updateBar}
            onEnded={finish}
            onLoadedMetadata={setCurrentTime}
            style={{ display: "none" }}
            src={mediaUrl? `${Requests.getEndpoint()}/unit/download/${mediaUrl}${".mp3"}`: ""}
            ref={mediaModalRef}
          ></audio>
        )}
      </div>
    </>
  )
}
