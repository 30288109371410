import { React, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Card, Box } from "@mui/material";

export default function People() {
  useEffect(() => {
    document.title = "Users List - Focus Forward Tops";
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
        <Card component="main">
          <h1>People - ADMIN - PM</h1>
        </Card>
      </Box>
    </ThemeProvider>
  );
}
