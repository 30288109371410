import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import React from "react"

function ReassignReasonsForm({ reassignReasons, handleCheckboxChange }) {
  return (
    <Grid container spacing={0}>
      {reassignReasons.map((reason, index) => (
        <Grid item xs={6} sx={{ marginBottom: "1px !important" }} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={reason.checked}
                onChange={() => handleCheckboxChange(reason.text)}
                size="small"
              />
            }
            label={reason.text}
            sx={{
              fontSize: "9px !important",
              padding: "0 !important",
              margin: "0 !important",
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default React.memo(ReassignReasonsForm);
