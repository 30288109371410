import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  Grid,
  TableCell,
  TableBody,
  Button,
  Paper,
  TextField,
  InputLabel,
  IconButton,
  Divider,
} from "@mui/material";
import "../App.css";
import Config from "../config";
import awsUtils from "../awsUtils";
import FileItem from "../components/FileItem";
import useZustant from "../hooks/useZustant";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const headCell = [
  {
    id: "filename",
    label: "Unit ID",
  },
  {
    id: "estimate",
    label: "Est. Delivery",
  },
  {
    id: "cost",
    label: "Cost",
  },
];
  
function ListOfFiles(props) {
  const {
    files,
    getRootProps,
    isDragHover,
    DropInput,
    folder,
    folders,
    s3,
    deleteFile,
    fetchS3Folders,
    handleAlert,
    goToCheckout,
    handleFiles,
    filesObject,
    basicRates,
    estimatedDeliveryTime
  } = props;
  const [filesUpload, setFilesUpload] = useState([]);
  const { currentFolderPrefix, newOrderType, changeNewOrderType } = useZustant();
  const [total, setTotal] = useState(0);

  const [trueTotal, setTrueTotal] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [referenceName,setReferenceName]=useState("");
// declare the checkbox data
  const [checkBoxData, setCheckBoxData] = useState({
    check1: {
      checked: false,
      value: 0,
      cost: 1.25,
      id: "check1",
      label: "RUSH",
    },
    check2: {
      checked: false,
      value: 0,
      cost: 0.3,
      id: "check2",
      label: "VERBATIM",
    },
    check3: {
      checked: false,
      value: 0,
      cost: 0.5,
      id: "check3",
      label: "TIMESTAMPING",
    },
    check4: {
      checked: false,
      value: 0,
      cost: 0.1,
      id: "check4",
      label: "INSTANT FIRST DRAFT",
    },
  });
// handel checkbox marck
  const handleChange = (event) => {
    const { name } = event.target;
    setCheckBoxData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        checked: !prevState[name].checked,
      },
    }));
  };

  const updateCheckboxValue = (checkboxName, newValue) => {
    setCheckBoxData((prevState) => ({
      ...prevState,
      [checkboxName]: {
        ...prevState[checkboxName],
        value: newValue,
      },
    }));
  };
  // check the files that are loaded.
  useEffect(() => {
    const newFiles = files.filter((file) => {
      return !filesUpload.some(
        (uploadedFile) => uploadedFile.id === `${file.name}_${file.size}`
      );
    });
    const newFilesUpload = newFiles.map((file) => ({
      id: `${file.name}_${file.size}`,
      isUploaded: false,
    }));
    const newArr = [
      ...filesUpload.filter((file) =>
        files.some((f) => f.name === file.id.split("_")[0])
      ),
      ...newFilesUpload,
    ];

    setFilesUpload(newArr);
  }, [files]);

  // update the filesUpload array
  const handleFilesSetTrue = (id, key, fileName, cost, isAudioOrVideo, durationInMinutes, size) => {
    setFilesUpload([
      ...filesUpload,
      { id, isUploaded: true, key, fileName, cost, isAudioOrVideo, durationInMinutes, size },
    ]);
  };

  const { moveItem } = awsUtils;
// here comes the subtotal
  useEffect(() => {
    const completedUploads = filesUpload.filter(
      (file) => file.isUploaded === true
    );

    const isAudioOrVideo = completedUploads.some(
      (file) => file.isAudioOrVideo === true
    );

    let aux = 0;

    completedUploads.forEach((upload) => {
      aux = aux + parseFloat(upload.cost);
    });

    setTotal(aux);
    setTrueTotal(aux);

    const checkedOptions = Object.values(checkBoxData).filter(
      (checkbox) => checkbox.checked
    );

    if (completedUploads.length === files.length && isAudioOrVideo) {
      handleFiles({
        completedUploads,
        subtotal: total,
        total: trueTotal,
        checkedOptions,
        newOrderType,
        referenceName,
      });
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [filesUpload, files, checkBoxData]);

  useEffect(()=>{
    handleFiles(
     {...filesObject,referenceName}
    );
  },[referenceName])

  useEffect(() => {
    if (checkBoxData) {
      Object.values(checkBoxData).forEach((checkbox) => {
        updateCheckboxValue(checkbox.id, (total/basicRates[newOrderType]) * checkbox.cost);
      });
    }
  }, [total]);

// Calculte the total.
  useEffect(() => {
    let subTotal = total;

    Object.values(checkBoxData).forEach((checkbox) => {
      if (checkbox.checked) {
        subTotal = subTotal + checkbox.value;
      }
    });

    setTrueTotal(subTotal);
  }, [checkBoxData, filesUpload, total]);
  return (
    <Box container="true" mt={2} width={"100%"} style={{display:"flex"}}>
      <Box width={"72%"}>
        <Box className="checkoutDescription" sx={{px:5}} >
        <Button
          variant="outlined"
          sx={{
            marginBottom: "20px",
            padding: "10px 20px",
            fontWeight: "bold",
          }}
          {...getRootProps()}
        >
          Upload More Files
        </Button>
        <div
          className={isDragHover ? "dragAndDropUpload" : "listOfFiles"}
          {...(isDragHover ? { ...getRootProps() } : null)}
        >
          <DropInput />

          <Box
            sx={
              ({ width: "100%" },
              isDragHover ? { display: "none" } : { display: "inherit" })
            }
          >
            <Paper sx={{ width: "100%" }} className="filesOptions">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCell.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.id !== "filename" ? "center" : "left"}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files.map((file, index) => (
                      <FileItem
                        file={file}
                        index={index}
                        key={file.name}
                        folder={folder}
                        folders={folders}
                        Config={Config}
                        s3={s3}
                        deleteFile={deleteFile}
                        fetchS3Folders={fetchS3Folders}
                        handleFilesSetTrue={handleFilesSetTrue}
                        filesUpload={filesUpload}
                        handleAlert={handleAlert}
                        estimatedDeliveryTime={estimatedDeliveryTime}
                        basicRates={basicRates}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography
                              variant="p"
                              sx={{
                                letterSpacing: "1.5px",
                                fontWeight: 300,
                              }}
                            >
                              SUBTOTAL
                            </Typography>{" "}
                            ($1.50/min)
                          </div>
                          <Typography variant="h3" sx={{ fontWeight: 400 }}>
                            ${total.toFixed(2)}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "40px",
                }}
              >
                {newOrderType === "human" && (
                  <div className="optionContainer">
                    <div className="infBody">
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={checkBoxData.check1.checked}
                            onChange={handleChange}
                            name="check1"
                          />
                        }
                        label="Rush my order (+ $1.25/min)"
                        className="options"
                      />
                      <Typography variant="h5" className="optionInf">
                        All files will be expedited and delivered up to 5x
                        faster.
                      </Typography>
                    </div>
                    <span className="optionCost">
                      $
                      {checkBoxData.check1.checked
                        ? checkBoxData.check1.value.toFixed(2)
                        : "0.00"}
                    </span>
                  </div>
                )}
                {newOrderType === "human" && (
                  <div className="optionContainer">
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={checkBoxData.check2.checked}
                            onChange={handleChange}
                            name="check2"
                          />
                        }
                        label="Timestamping (+ $0.30/min)"
                        className="options"
                      />
                      <Typography variant="h5" className="optionInf">
                        Transcript synced to audio with timestamps on every
                        paragraph.
                      </Typography>
                    </div>
                    <span className="optionCost">
                      $
                      {checkBoxData.check2.checked
                        ? checkBoxData.check2.value.toFixed(2)
                        : "0.00"}
                    </span>
                  </div>
                )}
                <div className="optionContainer">
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={checkBoxData.check3.checked}
                          onChange={handleChange}
                          name="check3"
                        />
                      }
                      label="Verbatim (+ $0.50/min)"
                      className="options"
                    />
                    <Typography variant="h5" className="optionInf">
                      Include filler words (um, ah), nonverbal communication,
                      and false starts.
                    </Typography>
                  </div>
                  <span className="optionCost">
                    $
                    {checkBoxData.check3.checked
                      ? checkBoxData.check3.value.toFixed(2)
                      : "0.00"}
                  </span>
                </div>

                <div className="optionContainer">
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={checkBoxData.check4.checked}
                          onChange={handleChange}
                          name="check4"
                        />
                      }
                      label="Instant First Draft (+ $0.10/min)"
                      className="options"
                    />
                    <Typography variant="h5" className="optionInf">
                      Machine-generated transcript delivered in minutes.
                    </Typography>
                  </div>
                  <span className="optionCost">
                    $
                    {checkBoxData.check4.checked
                      ? checkBoxData.check4.value.toFixed(2)
                      : "0.00"}
                  </span>
                </div>
                <Divider sx={{ marginTop: "15px" }} />
                <Box
                  sx={{
                    py: 5,
                    px: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h2" sx={{ fontWeight: "300" }}>
                    Order Total
                  </Typography>

                  <span style={{ fontSize: "18px" }}>
                    ${trueTotal.toFixed(2)}
                  </span>
                </Box>
              </Box>
            </Paper>
            <Box sx={{pb: 3}}>
              <InputLabel  sx={{fontSize: 14}} >
                REFERENCE NAME (OPTIONAL)
                <IconButton variant="outlined">
                  <InfoOutlinedIcon height={14} width={14}/>
                </IconButton>
              </InputLabel>
              <TextField fullWidth id="fullWidth"  sx={{ boxShadow: -5 }} onChange={(e)=>{setReferenceName(e.target.value)}}/>
            </Box>
          </Box>
        </div>
        </Box>
        <Box className="checkoutContainer" width={300} sx={{mx:5}}>
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          Order Total &nbsp; ${trueTotal.toFixed(2)}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: "80%",
            color: "white !important",
            letterSpacing: "2px !important",
            padding: "10px !important",
          }}
          color="primary"
          onClick={() => goToCheckout()}
          disabled={!isReady}
        >
          Checkout
        </Button>
        </Box>
      </Box>
      <Box className="checkoutContainer" width={"28%"} sx={{pl:5}}>
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          Order Total &nbsp; ${trueTotal.toFixed(2)}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: "80%",
            color: "white !important",
            letterSpacing: "2px !important",
            padding: "10px !important",
          }}
          color="primary"
          onClick={() => goToCheckout()}
          disabled={!isReady}
        >
          Checkout
        </Button>
      </Box>
    </Box>
  );
}
export default React.memo(ListOfFiles);
