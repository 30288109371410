import { React, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import {
  Box,
  Typography,
  Button,
  Radio,
  FormControl,
  InputLabel,
  InputBase,
  styled,
  Select,
  FormLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CardMedia,
  IconButton,
  Stack,
  Grid,
  Divider,
  RadioGroup,
  Link,
} from "@mui/material";
import mainTheme from "../theme.js";
import logo from "../assets/img/logo.png";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ComputerIcon from "@mui/icons-material/Computer";
import "../App.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LockIcon from '@mui/icons-material/Lock';
import CloudIcon from "@mui/icons-material/Cloud";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { useDropzone } from "react-dropzone";
import Config from "../config";
import AWS from "aws-sdk";
import Utils from "../utils";
import { useNavigate } from "react-router-dom";
import useZustant from "../hooks/useZustant";
import Requests from "../logic/Requests";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import useShow from "../hooks/useShow";
import awsUtils from "../awsUtils";
import ListOfFiles from "../components/ListOfFiles";
import SquarePayment from "../components/SquarePayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { monthsList, yearList } from "../lists";
import { ContrastTwoTone } from "@mui/icons-material";

AWS.config.update({
  region: Config.awsRegion,
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey,
});
const s3 = new AWS.S3();

export default function UploadFiles() {
  const { baseNameFolder } = Utils;
  const { emptyS3Directory, moveItem } = awsUtils;
  const { user, currentFolderPrefix,clientPrepaidBalance } = useZustant();
  const [isDragHover, setIsDragHover] = useState(false);
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [fileInfo, setFileInfo] = useState({});
  const [currentState, setCurrentState] = useState("addFiles")
  const { show, open, close } = useShow();
  
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const [filesObject, setFilesObject] = useState({});

  const handleFiles = (files) => {
    setFilesObject(files);
  };
  const CustomInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      marginTop: theme.spacing(3),
      position: "relative",
      borderRadius: "4px",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      borderBottom: "none",
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "5px 6px",
      height: "36px",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const BootstrapInput = styled(InputBase)(() => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      height: "25px",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };
  const [folders, setFolders] = useState([]);

  const fetchS3Folders = async (prefix) => {
    const response = await s3
      .listObjectsV2({
        Bucket: Config.awsBucketName,
        Delimiter: "/",
        Prefix: prefix !== "/" ? prefix : "",
      })
      .promise();

    const folders = [];

    const s3Prefixes = response.CommonPrefixes;

    if (s3Prefixes) {
      folders.push(
        ...s3Prefixes.map((prefix) => ({
          id: prefix.Prefix,
          name: baseNameFolder(prefix.Prefix),
          isDir: true,
        }))
      );
    }
    return folders;
  };

  const [isInCheckout, setIsInCheckout] = useState(false);

  const goToCheckout = () => {
    setIsInCheckout(true);
  };
  const exitCheckout = () => {
    setIsInCheckout(false);
  };

  useEffect(() => {
    document.title = "Files Upload";

    try {
      let data = [];

      Requests.getUserFolders(user.id).then((res) => {
        res.data.forEach((folder) => {
          let object = {};
          object.isFile = false;
          object.name = folder.name;
          object.public = false;
          object.url = `https://${Config.awsBucketName}.s3.amazonaws.com/${folder.path}`;
          object.key = folder.path;
          data.push({
            id: object.key,
            name: object.name,
            isDir: true,
          });
        });

        setFolder(data[0]);

        const lo = fetchS3Folders(data[0].id).then((res) => {
          setFolders(res);

          emptyS3Directory(
            Config.awsBucketName,
            data[0].id + user.id + "_Temp/"
          );
        });
      });
    } catch (error) {
      setFolder(null);
    }
  }, []);

  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles.map((file) => file)]);
      setIsDragHover(false);
    },
  });

  const deleteFile = (index) => {
    const newArr = files.filter((file, i) => i !== index);
    setFiles(newArr);
  };

  const onChangeFileButtonInput = (e) => {
    const file = e.target.files[0];
    setFiles([...files, file]);
  };
 
  const uploadFiles = async () => {
    await Promise.all(
      filesObject.completedUploads.map((file) => {
        return moveItem(
          { id: file.key, isDir: false, name: file.fileName },
          currentFolderPrefix[currentFolderPrefix.length - 1].id
        );
      })
    );
    navigate("/filemanagement");
  };

  
  // transcription price in dollar/min

  const basicRates = { automated: 0.25, human: 1.5 };

  // estimated DeliveryTime

  const estimatedDeliveryTime = [
    {
      min: 0,
      max: 10,
      medianTurnaroundTime: "2 hours",
      maxTurnaroundTime: "24 hours",
      rushMedianTurnaroundTime: "1 hour",
      rushMaxTurnaroundTime: "12 hours",
    },
    {
      min: 10,
      max: 30,
      medianTurnaroundTime: "8 hours",
      maxTurnaroundTime: "24 hours",
      rushMedianTurnaroundTime: "2 hours",
      rushMaxTurnaroundTime: "12 hours",
    },
    {
      min: 30,
      max: 60,
      medianTurnaroundTime: "16 hours",
      maxTurnaroundTime: "48 hours",
      rushMedianTurnaroundTime: "5 hours",
      rushMaxTurnaroundTime: "24 hours",
    },
    {
      min: 60,
      max: 120,
      medianTurnaroundTime: "24 hours",
      maxTurnaroundTime: "72 hours",
      rushMedianTurnaroundTime: "5 hours",
      rushMaxTurnaroundTime: "24 hours",
    },
    { min: 120,
      max: 9999,
      medianTurnaroundTime: "48 hours",
      maxTurnaroundTime: "72 hours",
      rushMedianTurnaroundTime: "5 hours",
      rushMaxTurnaroundTime: "24 hours",
    },
  ];

  // handle afterpayment - Create project

  const handleAfterpayment = () => {
    setIsInCheckout(false);
    navigate("/filemanagement");
  } 

  // OrderStateDisplay
  function OrderStateDisplay(props){
    const {isInCheckout,setIsInCheckout}=props;
    return (
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          style={{
            height: "1px",
            backgroundColor: "gray",
            position: "absolute",
            top:"8px",
            left:"42px",
            right: "30px",
            zIndex: "0"
          }}
          sx={{
            pr:8,
            pl:10
          }}
        />
        <Box display={"flex"} zIndex={1} flexDirection={"column"} alignItems={"center"} onChange={()=>setIsInCheckout(false)}>
          <Box
            borderRadius="100%"
            width="1rem"
            height="1rem"
            style={{backgroundColor: isInCheckout ? "black":mainTheme.palette.primary.main}}
          ></Box>
          <Button style={{color: isInCheckout ? "black":mainTheme.palette.primary.main}}>Add Files</Button>
        </Box>
        {!user && (
          <Box display={"flex"} zIndex={1} flexDirection={"column"} alignItems={"center"}>
            <Box
              borderRadius="100%"
              width="1rem"
              height="1rem"
              backgroundColor={mainTheme.palette.primary.main}
            ></Box>
            <Button>Sign In</Button>
          </Box>
        )}
        <Box display={"flex"} zIndex={1} flexDirection={"column"} alignItems={"center"}>
          <Box
            borderRadius="100%"
            width="1rem"
            height="1rem"
            backgroundColor={mainTheme.palette.primary.main}
          ></Box>
          <Button>Pay</Button>
        </Box>
      </Stack>
    );
  };
 
  const FAQSection = ()=>{
    return (
      <Box style={{width: "100%"}}>
        <Grid item>          
          <ul className="list">
            <li >What's the difference between transcription and captions?</li>
            <li >Tell me about your confidentiality policy.</li>
            <li >How do I apply for sales tax exemption on my orders?</li>
          </ul>
        </Grid>
      </Box>
    );
  }
  const PaymentOptions = (props) =>{
    const {paymentMethod, setPaymentMethod } = props;
    const handleRadioChange = (event) => {
      setPaymentMethod(event.target.value);
    };
  const [canPay,setCanPay] = useState(false);
    return (
      <FormControl style={{ width: "100%", marginTop: "12px" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="prepay"
        value={paymentMethod}
        name="radio-buttons-group"
        onChange={handleRadioChange}
      >
        {clientPrepaidBalance>0 && 
          <FormControlLabel
            value="prepay"
            control={<Radio />}
            label={
              <Typography variant="p">
                Prepaid balance, ${clientPrepaidBalance} remaining
              </Typography>
            }
          /> 
        }
        <FormControlLabel
          value="savedCard"
          control={<Radio />}
          label={
            <Typography variant="p">
              Pay with saved Visa ending in 6702
            </Typography>
          }
        />
        <FormControlLabel
          value="newCard"
          control={<Radio />}
          label={
            <Typography variant="p" style={{ whiteSpace: "nowrap" }}>
              Use a different card{" "}
              <img
                style={{ height: "24px" }}
                src={"/cardsLogos/Accepted-Cards-US.png"}
                alt="(Visa, Mastercard, American Express, Discover, JCB, or UnionPay International )"
              />
            </Typography>
          }
        />
        <Box
          style={{ display: paymentMethod === "newCard" ? "block" : "none" }}
          maxWidth={450}
        >
          <SquarePayment
            amount={filesObject.total}
            currency={"USD"}
            afterPayment={handleAfterpayment}
            filesObject={filesObject}
            typeOfPay="square"
          />
        </Box>
        <FormControlLabel
          value="payPal"
          control={<Radio />}
          label={<img src={"/cardsLogos/cc-paypal.svg"} alt="PayPal" />}
        />
        {/* {paymentMethod !== "2" && (
          <Button
            sx={{ mt: 1, mx: 4 }}
            type="submit"
            variant="outlined"
            style={{backgroundColor: canPay?"#1A336E" : "#ccc" , cursor: !canPay && "not-allowed",  color:  "white",  fontWeight: 700 , border:"none"}}
          >
            ADD TO BALANCE
          </Button>
        )} */}
      </RadioGroup>
    </FormControl>
    )
  }

  const CheckoutSection = ()=>{
    const [paymentMethod, setPaymentMethod] = useState("prepay");

    const handlePrepaidPayment = async () => {
      console.log("selected:",paymentMethod)
      if(clientPrepaidBalance>filesObject.total){
          switch (paymentMethod) {
            case "prepay":
              console.log("it's in prepay");
              const typeOfPay = "usePrepay";
              try {
                await Requests.squarePayment({amount: filesObject.total }, filesObject, typeOfPay);
                handleAlert("success", "Prepay Blance payment done.");
                navigate("/filemanagement");
              }catch(error){
                console.log(error);
                handleAlert("error", "Error using prepaid balance.")
              }
              ;
              break;
            case "savedCard":
                handleAlert("error", "Feature on development.")
              break;
            case "newCard":
              
              break;
            case "payPal":
              handleAlert("error", "Feature on development.");
              break;
            default:
              console.log("no option selected")
          }
        }
    }
    return (
      <Box sx={{ display: 'flex' }} width={"100%"} mt={2} >
        <Box width={"71%"} sx={{pl:5}} >
            <div style={{ marginBottom: "10px" }}>
              <span
                variant="h3"
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Payment Information for My Transcript Order
              </span>
            </div>
            <PaymentOptions 
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
            {paymentMethod !== "newCard" && (
              <Box sx={{display: "flex", justifyContent: "space-around"}}>
                <Box>
                  By continuing, you agree to the <Link>terms of service</Link>.
                </Box>
                <Button variant="contained" onClick={handlePrepaidPayment}>order transcript</Button>
              </Box>
            )}
        </Box>

        <Box style={{ width: "29%" }}>
            <div style={{ marginBottom: "10px" }}>
              <span
                style={{
                  fontSize: "20px",
                  color: "transparent",
                }}
              >
                P
              </span>
            </div>
            <Box className="filesDetails">
              <div className="item">
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    color: theme.palette.primary.main,
                    fontSize: "17px",
                  }}
                >
                  My Transcript Order
                </Typography>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => exitCheckout()}
                >
                  EDIT &gt;
                </span>
              </div>
              <Divider />

              {filesObject.completedUploads
                ? filesObject.completedUploads.map((file, index) => (
                    <div key={index}>
                      <div className="item file">
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 300,
                            fontSize: "17px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                            color: theme.palette.fourth.main,
                          }}
                        >
                          {file.fileName}
                        </Typography>
                        <span className="value">${file.cost} </span>
                      </div>
                      <Divider />
                    </div>
                  ))
                : null}

              <div className="item" style={{ paddingBottom: "10-px" }}>
                <Typography variant="43" sx={{ fontWeight: 300 }}>
                  SUBTOTAL <b style={{ fontSize: "11px" }}>($1.50/MIN)</b>
                </Typography>
                <span className="value">
                  $
                  {filesObject.subtotal
                    ? filesObject.subtotal.toFixed(2)
                    : null}
                </span>
              </div>

              {filesObject.checkedOptions
                ? filesObject.checkedOptions.map((option, index) => (
                    <div className="item check" key={index}>
                      <Typography variant="43" sx={{ fontWeight: 300 }}>
                        {option.label}
                      </Typography>
                      <span className="value">${option.value.toFixed(2)}</span>
                    </div>
                  ))
                : null}
              <Divider sx={{ mt: 2 }} />

              <div className="item">
                <Typography variant="43" sx={{ fontWeight: 300 }}>
                  SALES TAX
                </Typography>

                <span className="value">$0.0</span>
              </div>
              <div className="item">
                <Typography variant="43" sx={{ fontWeight: 300 }}>
                  TOTAL
                </Typography>

                <Typography variant="h3">
                  ${filesObject.total ? filesObject.total.toFixed(2) : 0.0}
                </Typography>
              </div>
            </Box>
        </Box>
      </Box>
    );
  }

  const BasicInputFile = () => {
    return (
      <div className="insideFile">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <ComputerIcon fontSize="large" color="primary" />
            <ArrowForwardIcon fontSize="large" color="primary" />
            <CloudIcon fontSize="large" color="primary" />
          </div>

          <Typography style={{ color: "black" }} variant="p">
            Upload files from your computer or drag and drop here
          </Typography>
        </div>

        <Button
          variant="contained"
          color="primary"
          component="label"
          className="uploadButton"
        >
          Upload
        </Button>
      </div>
    );
  };

  const DropInput = () => {
    return (
      <div
        style={
          isDragHover
            ? {
                display: "flex",
                flexDirection: "column",
                alingItems: "center",
                pointerEvents: "none",
              }
            : {
                display: "none",
              }
        }
      >
        <CopyAllIcon
          fontSize="inherit"
          sx={{
            fontSize: "70px",
            pointerEvents: "none",
            margin: "auto",
            marginBottom: "30px",
          }}
        />
        <Typography
          variant="h2"
          sx={{ fontWeight: "200", pointerEvents: "none" }}
        >
          Drop files here
        </Typography>
        <input hidden multiple type="file" {...getInputProps()} />
      </div>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box sx={{pl: 6}}>
        <img src={logo} alt={"focuslogo"} style={{height: "100px"}}/>
      </Box>
      <Box  sx={{maxWidth: 1024, mx: "auto" }} >
        <Box sx={{ display: 'flex' }} width={"100%"} mt={2}>
          <Box width={"70%"} sx={{px:5}}>
            <Typography variant="h1" sx={{ fontWeight: 'bold'  }}>
              {isInCheckout
                ? "Paymet Details"
                : "Order English Transcripts by a Professional Transcriber"}
            </Typography>
            <Typography vatiant={"h3"}>
              Professionals transcribe your files to 99% accuracy
            </Typography>
            <Button
              sx={{ mt: 2 }}
              onClick={() => navigate("/filemanagement")}
            >
              BACK TO MY FILES
            </Button>

          </Box>
          <Box width={"30%"}>
            <OrderStateDisplay isInCheckout={isInCheckout} setIsInCheckout={setIsInCheckout}/>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }} width={"100%"} mt={2} >
          <Box width={"71%"} sx={{pl:5}} >
            <Typography variant="h3">
                Add English Files for Price and Turnaround
                <IconButton variant="outlined">
                  <InfoOutlinedIcon />
                </IconButton>
              </Typography>
            <Box
              {...(files.length === 0 ? { ...getRootProps() } : null)}
              className={
                files.length > 0
                  ? "filesToUpload"
                  : isDragHover
                  ? "dragAndDrop"
                  : "uploadFilesInputContainer"
              }
              sx={isInCheckout ? { display: "none" } : null}
              onDragOver={() => setIsDragHover(true)}
              onDragLeave={() => setIsDragHover(false)}
            >
              {!files.length > 0 &&
                (isDragHover ? <DropInput /> : BasicInputFile())}
            </Box>
          </Box>
          <Box width={"29%"}>
          {!files?.length > 0 && <FAQSection />}
          </Box>
        </Box>
        <Box
              sx={
                isInCheckout
                  ? { display: "none" }
                  : {
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                    }
              }
            >
              {files?.length > 0 && (
                <ListOfFiles
                  basicRates={basicRates}
                  estimatedDeliveryTime={estimatedDeliveryTime}
                  files={files}
                  getRootProps={getRootProps}
                  isDragHover={isDragHover}
                  DropInput={DropInput}
                  folder={folder}
                  folders={folders}
                  s3={s3}
                  deleteFile={deleteFile}
                  fetchS3Folders={fetchS3Folders}
                  isLoading={isLoading}
                  handleAlert={handleAlert}
                  goToCheckout={goToCheckout}
                  handleFiles={handleFiles}
                  filesObject={filesObject}
                />
              )}
        </Box>
        { isInCheckout && <CheckoutSection/>}
        <Box style={{display: "flex", alignItems: 'center',gap: "8px"}} sx={{ml:5, mt:2}}>
          <LockIcon style={{ color: theme.palette.primary.main }}/>
          <span>Secure and Encrypted</span>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
