import { useEffect, useState, useCallback } from "react";

const useFetch = (request, ...params) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = () => {
    let response;
    let isParamNull = false;
    if (!params) {
      response = request();
    } else {
      params.forEach((param) => {
        if (param === null || param === undefined) {
          isParamNull = true;
        }
      });
      if (!isParamNull) {
        response = request(...params);
      }
    }
    if (!isParamNull) {
      setIsLoading(true);
      response
        .then((data) => {
          setData(data.data);
          setIsLoading(false);
          setError(null);
        })
        .catch((err) => {
          if (err.response) {
            setError(err.response.data);
          } else {
            setError(err.message);
          }
          setIsLoading(false);
        });
    }
  };

  const doFetch = useCallback(fetchData, [...params]);

  useEffect(() => {
    if (params) {
      doFetch();
    }
  }, [doFetch]);

  useEffect(() => {
    fetchData();
  }, []);

  return { data, isLoading, error, reFetch: fetchData , setData  };
};

export default useFetch;
