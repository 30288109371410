import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import "../../App.css";
import { IconButton, Box, Container, Link } from "@mui/material";
import SettingsDialogComponent from './SettingsDialogComponent'
import EditDialogComponent from './EditDialogComponent'
import {
  faEnvelope,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const actions = [
  { color: "secondary", icon: faEnvelope },
  { color: "third", icon: faCopy },
  { color: "fourth", icon: faCloudArrowUp }
];

export default function ProjectSummaryComponent() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          padding: 0
        }}
        style={{padding: 0}}
      >
        <Box>
          <h2 style={{ fontWeight: 500, marginBottom: 0 }}>
            TPS2202112 - Weekly Church Video 2022 - August [F-8.10]
          </h2>
          <Link>Ron Snipes</Link>
          <p style={{ marginTop: 0 }}>Chris Rosentreter</p>
        </Box>
        <Box sx={{ display: { xs: "flex" } }}>
          <EditDialogComponent/>
          {actions.map((action, key) => (
            <IconButton key={key} color={action.color} size="large">
              <FontAwesomeIcon icon={action.icon}/>
            </IconButton>
          ))}
            <SettingsDialogComponent/>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
