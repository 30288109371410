import { useEffect } from "react";
import useZustant from "../hooks/useZustant";
import {
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import PlaceNewOrderButton from "../components/PlaceNewOrderButton";

export default function SearchFiles() {
  const { setCurrentClientPage, changeIsInClientsSettings } = useZustant();
  const navigate = useNavigate();

  const mobile = useMediaQuery("(max-width:400px)");

  useEffect(() => {
    document.title = "Search Files - Focus Forward";
    setCurrentClientPage("Search Files");
    changeIsInClientsSettings(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ width: "100%", paddingTop: 4 }}>
        <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center"}} height={100}>
          <Typography variant="h1">
            Search Files
          </Typography>
          <PlaceNewOrderButton />
        </Box>
        <div
          style={{
            display: "flex ",
            alignItems: "center",
            width: "100%",
            
          }}
        >
          <TextField
            type="text"
            placeholder="Keywords, Order Number, Reference Names, File Names..."
            style={{
              flexGrow: 10,
              maxWidth: "600px",
              marginRight: "10px",
            }}
          />
          {mobile ? (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                flexGrow: 1,
                height: "56px !important",
                maxWidth: "160px",
              }}
              startIcon={<SearchIcon />}
              fontSize={mobile ? "12px" : "16px"}
            >
              Search
            </Button>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
}
