import React, { useEffect, useRef, useMemo } from "react";

import { FormControl, InputBase, InputLabel } from "@mui/material";



function EmailInput(props) {
  const input2Ref = useRef(null);
  const { email, handleChange, index, inputStyle } = props;

  const memoValue = useMemo(() => {
    return email;
  }, [email]);

  useEffect(() => {
    if (memoValue !== "") {
      input2Ref.current.focus();
    }
  }, [memoValue]);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink htmlFor="email">
        EMAIL
      </InputLabel>
      <InputBase
        sx={inputStyle}
        id="email"
        name="email"
        onChange={(e) => handleChange(index, "email", e.target.value)}
        value={memoValue}
        inputRef={input2Ref}
      />
    </FormControl>
  );
}

export default React.memo(EmailInput);
