import { React, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { useState } from "react";
import { Card, Box, IconButton } from "@mui/material";
import ChangePasswordModal from "../components/ChangePassword";
import GeneralProfile from "../components/profile/GeneralProfile";

import { StateList, CountryList } from "../lists";
import Utils from "../utils";
import useFetch from "../hooks/useFetch";
import Requests from "../logic/Requests";
import { useParams } from "react-router-dom";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import useShow from "../hooks/useShow";
import TranscriberCustomHotKeysModal from "../components/profile/TranscriberCustomHotKeys";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

export default function Profile() {
  let { idUser } = useParams();
  const navigate = useNavigate();

  const {
    data: currentUser,
    isLoading: isLoadingUser,
    reFetch,
  } = useFetch(Requests.getSpecificUser, idUser);

  const verifyField = (value) => (value && value !== "undefined" ? value : "");

  const { qualitativeGrade, validateEmail, validateZipCode , isValidPhoneNumber } = Utils;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const {
    show: showHotkeysModal,
    close: closeHotkeysModal,
    open: openHotkeysModal,
  } = useShow();

  const { show: showModal, open: openModal, close: closeModal } = useShow();

  const { data, isLoading } = useFetch(
    Requests.getUserPay,
    isLoadingUser ? null : currentUser.id,
    startDate,
    endDate
  );

  const [isTheFormChange, setIsTheFormChange] = useState(false);

  const handleFormChange = () => setIsTheFormChange(true);

  const resetChanges = () => {
    document.getElementById("firstName").value = currentUser.firstname
      ? currentUser.firstname
      : "";
    document.getElementById("lastName").value = currentUser.lastname
      ? currentUser.lastname
      : "";
    document.getElementById("email").value = currentUser.mail
      ? currentUser.mail
      : "";
    document.getElementById("phone").value = currentUser.phone
      ? currentUser.phone
      : "";
    document.getElementById("address1").value = currentUser.address1
      ? currentUser.address1
      : "";
    document.getElementById("address2").value = currentUser.address2
      ? currentUser.address2
      : "";
    document.getElementById("city").value = currentUser.city
      ? currentUser.city
      : "";
    document.getElementById("state").value = currentUser.state
      ? currentUser.state
      : "";
    document.getElementById("country").value = currentUser.country
      ? currentUser.country
      : "";
    document.getElementById("zip").value = currentUser.zip
      ? currentUser.zip
      : "";
    setIsTheFormChange(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
    
      if (!validateEmail(data.get("mail"))) {
        handleAlert("error", "Please enter a valid email");
        return;
      }

      if (
        data.get("payoneerMail") &&
        !validateEmail(data.get("payoneerMail"))
      ) {
        handleAlert("error", "Please enter a valid email");
        return;
      }

      if (data.get("second_email") && !validateEmail(data.get("second_email"))) {
        handleAlert("error", "Please enter a valid email");
        return;
      }

      await Requests.saveUser(currentUser.id, data).then((res) => {
        if (res.status === 200) {
          handleAlert("success", "Information updated successfully");
          setIsTheFormChange(false);
        }
      });
    } catch (error) {
      handleAlert("error", "There was an error updating the information");
      reFetch();
    }
  };

  const downloadPayHistory = () => {
    try {
      if (!startDate || !endDate || startDate === "" || endDate === "") {
        handleAlert("error", "Please select a start and end date");
        return;
      }
      const url = Requests.exportTranscriberPaySummary(
        currentUser.id,
        startDate,
        endDate
      );
      window.location = url;
    } catch (error) {
      handleAlert("error", "There was an error downloading the file");
    }
  };

  useEffect(() => {
    document.title = "User Details - Focus Forward Tops";
  }, []);

  if (currentUser || (isLoadingUser && currentUser)) {
    return (
      <ThemeProvider theme={theme}>
        <CustomSnackBarAlert
          show={showAlert}
          close={closeAlert}
          type={currentAlertState.type}
        >
          {currentAlertState.message}
        </CustomSnackBarAlert>

        <ChangePasswordModal
          handleAlert={handleAlert}
          currentUser={currentUser}
          closeModal={closeModal}
          showModal={showModal}
        />

        <TranscriberCustomHotKeysModal
          show={showHotkeysModal}
          close={closeHotkeysModal}
        />

        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            px: 2,
            mb:10
          }}
        >

          <div className="viewContainer">

          <IconButton sx={{marginLeft:"97%", marginRight:"auto"}} onClick={() => {
            navigate("/")
          }}>
            <CloseIcon/>
          </IconButton>

            <h1 className="tittle">Profile</h1>

            <GeneralProfile
              handleSubmit={handleSubmit}
              handleFormChange={handleFormChange}
              currentUser={currentUser}
              verifyField={verifyField}
              CountryList={CountryList}
              StateList={StateList}
              isTheFormChange={isTheFormChange}
              resetChanges={resetChanges}
              openModal={openModal}
              qualitativeGrade={qualitativeGrade}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              downloadPayHistory={downloadPayHistory}
              data={data}
              isLoading={isLoading}
              startDate={startDate}
              endDate={endDate}
              openHotkeysModal={openHotkeysModal}
            />
          </div>
        </Box>
      </ThemeProvider>
    );
  }
}
