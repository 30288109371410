import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReassignReasonsForm from "./ReassignReasonsForm";
import React from "react";

const grades = ["A", "B", "C", "D", "F"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FirstStep(props) {
  const {
    unitTime,
    reassignReasons,
    handleUniTime,
    handleGrade,
    handlePayAdjust,
    handleCheckboxChange,
    grade,
    payAdjust,
    comments,
    handleComments,
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="reassignFormItem">
        <span>1. Audio Length Transcribed:</span>
        <TimeField
          format="HH:mm:ss"
          value={unitTime}
          onChange={(newValue) => handleUniTime(newValue)}
          size="small"
          variant="standard"
          sx={{ borderBottom: "1px solid #000000" }}
        />
      </div>

      <div className="reassignFormItem">
        <span>2. Grade:</span>
        <FormControl sx={{ width: "240px !important" }} size="small">
          <InputLabel>Grade</InputLabel>
          <Select
            fullWidth
            MenuProps={MenuProps}
            label="Grade"
            name="grade"
            onChange={(e) => handleGrade(e.target.value)}
            value={grade}
          >
            {grades.map((grade) => (
              <MenuItem key={grade} value={grade}>
                {grade}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="reassignFormItem">
        <span>3. Pay Adjust (%):</span>
        <TextField
          size="small"
          type="number"
          value={payAdjust}
          onChange={(e) => handlePayAdjust(e.target.value)}
          variant="standard"
          sx={{ borderBottom: "1px solid #000000" }}
        />
      </div>

      <ReassignReasonsForm
        reassignReasons={reassignReasons}
        handleCheckboxChange={handleCheckboxChange}
      />

      <div style={{ marginBottom: "10px" }}>
        <span>
          4. OPTIONAL: Any comments or feedback about this transcriber?
        </span>

        <TextField
          fullWidth
          multiline
          rows={2}
          maxRows={4}
          style={{ marginTop: "10px" }}
          value={comments}
          onChange={(e) => handleComments(e.target.value)}
        />
      </div>
    </LocalizationProvider>
  );
}

export default React.memo(FirstStep);
