const Config = {
    api: "https://staging.focusfwd.com/apistaging",
    oldUI: "https://staging-legacy.focusfwd.com",
    awsBucketName: "topsstorage",
    awsRegion: "us-east-1",
    accessKey: "AKIAQDR2ULSLODZ23OTE",
    secretAccessKey: "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",
    cookieDomain: ".focusfwd.com",
    editorApiKey: "va5lv81lxs6gsgcw76p4475k503pr0t074g2uc2lymo3vxaj"
  };
  
  export default Config;
  