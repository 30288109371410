import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import useShow from "../../hooks/useShow";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faStop,
  faBackward,
  faForwardFast,
  faKeyboard,
} from "@fortawesome/free-solid-svg-icons";
import { isMacOs } from "react-device-detect";
import InputhotKey from "../profile/InputHotkey";
import { Container } from "@mui/system";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";
import theme from "../../theme";
import KeyboardIcon from "@mui/icons-material/Keyboard";

function KeyboardShortCuts(props) {
  const { show, open, close } = useShow();
  const { userHotKeys, unitHotKeys, useUnitHotkeys, saveHotKeys, handleAlert } =
    props;

  const renders = {
    cross: {
      render: (
        <div className="CrossTalkTag Tags" id="CrossTalkTag">
          [CROSSTALK]
        </div>
      ),
    },
    inaud: {
      render: (
        <div
          className="InaudibleTag Tags"
          id="InaudibleTag"
        >
          [INAUDIBLE]
        </div>
      ),
    },
    play: {
      render: (
        <Chip
          label="Play"
          icon={<FontAwesomeIcon icon={faPlay} className="iconAudioHotKeys" />}
          sx={{ pa: 2 }}
          className="audioHotKeys"
        />
      ),
    },
    stop: {
      render: (
        <Chip
          label="Stop"
          icon={<FontAwesomeIcon icon={faStop} className="iconAudioHotKeys" />}
          sx={{ pa: 2 }}
          className="audioHotKeys"
        />
      ),
    },
    rwd: {
      render: (
        <Chip
          label="Rewind"
          icon={
            <FontAwesomeIcon icon={faBackward} className="iconAudioHotKeys" />
          }
          sx={{ pa: 2 }}
          className="audioHotKeys"
        />
      ),
    },
    fwd: {
      render: (
        <Chip
          label="Fast Forward"
          icon={
            <FontAwesomeIcon
              icon={faForwardFast}
              className="iconAudioHotKeys"
            />
          }
          sx={{ pa: 2 }}
          className="audioHotKeys"
        />
      ),
    },
  };

  const [currentHotkeys, setCurrentHotkeys] = useState();

  const handleCurrentHotkeys = (hotKeys) => {
    setCurrentHotkeys(() => {
      return Object.entries(hotKeys).map(([key, value]) => {
        return {
          ...renders[value.cmd],
          ...value,
        };
      });
    });
  };

  function handleHotKeys(keyboardKey, updatedKeyCode) {
    setCurrentHotkeys((prevHotKeys) => {
      return Object.entries(prevHotKeys).map(([key, value]) => {
        if (value.cmd === keyboardKey) {
          return { ...value, ...updatedKeyCode };
        }
        return value;
      });
    });
  }

  const handleSave = () => {};

  useEffect(() => {
    if (userHotKeys && unitHotKeys) {
      if (useUnitHotkeys) {
        handleCurrentHotkeys(unitHotKeys);
      } else {
        handleCurrentHotkeys(userHotKeys);
      }
    }
  }, [unitHotKeys, useUnitHotkeys, userHotKeys, show]);

  return (
    <>
      <Button
        endIcon={<KeyboardAltIcon />}
        variant="outlined"
        onClick={open}
        style={{ borderColor: "#48494B", color: "#48494B", marginRight: 10 }}
      >
        <span>Shortcuts</span>
      </Button>
      {currentHotkeys ? (
        <Dialog open={show} onClose={close} sx={{ p: 3 }}>
          <Typography
            variant="h3"
            sx={{ ml: 3, mb: 3, fontWeight: 400, mt: 3 }}
          >
            Keyboard Shortcuts
          </Typography>

          <Box className="unitHotkeys">
            {Object.entries(currentHotkeys).map(([key, value]) => {
              return currentHotkeys[key].cmd === "play" ||
                value.cmd === "stop" ||
                value.cmd === "rwd" ||
                value.cmd === "fwd" ||
                value.cmd === "cross" ||
                value.cmd === "inaud" ? (
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <InputhotKey
                    isMacOs={isMacOs}
                    value={value}
                    name={value.cmd}
                    handleHotKeys={handleHotKeys}
                    hotKeys={currentHotkeys}
                    userHotKeys={unitHotKeys}
                    key={key}
                    handleAlert={handleAlert}
                    disableLabel
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    {value.render}
                  </div>
                </div>
              ) : null;
            })}
          </Box>
          <Container
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}
          >
            <Button onClick={close} color="red">
              Close
            </Button>
            <Button
              onClick={() => saveHotKeys(useUnitHotkeys, currentHotkeys, close)}
              color="primary"
            >
              Save
            </Button>
          </Container>
        </Dialog>
      ) : null}
    </>
  );
}

export default KeyboardShortCuts;
