import { useState } from "react";

const useShow = () => {
  const [show, setShow] = useState(false);

  const open = () => setShow(true);

  const close = () => setShow(false);

  const toggle = () => setShow(!show);

  return { show, open, close, toggle };
};

export default useShow;
