import {
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  TextField,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Requests from "../../../logic/Requests";
import useFetch from "../../../hooks/useFetch";
import ReassignReasonsForm from "./ReassignReasonsForm";

function SecondStep(props) {
  const {
    allTranscribers,
    handleAllTranscribers,
    dataUnit,
    reassignReasons,
    handleCheckboxChange,
    handleStep,
    transcriber,
    handleTranscriber,
  } = props;

  const { data } = useFetch(
    Requests.getTranscribersForReassign,
    dataUnit.transcript,
    dataUnit._id,
    allTranscribers
  );
  return (
    <div>
      <div style={{ marginBottom: "5px" }}>
        <IconButton onClick={handleStep}>
          <ArrowBackIcon />
        </IconButton>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Autocomplete
          sx={{ mb: 2 }}
          options={data}
          getOptionLabel={(option) => option.firstname + " " + option.lastname}
          value={transcriber}
          onChange={(event, newValue) => {
            handleTranscriber(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a transcriber"
              variant="outlined"
              size="small"
            />
          )}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={allTranscribers}
              onChange={handleAllTranscribers}
            />
          }
          label="All Transcribers"
        />
      </div>

      <ReassignReasonsForm
        reassignReasons={reassignReasons}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  );
}

export default React.memo(SecondStep);
