import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import "../../App.css";
import {
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProjectInvoicePreviewComponent() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          padding: 0,
        }}
        style={{ padding: 0, marginTop: 10 }}
      >
        <Accordion
          style={{
            width: "100%",
            border: "0.5px solid #1A336E",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{minHeight: 40, height: 40, margin: 0}} className="InvoicePreview">
            <Typography style={{margin: 0}}>Invoice Preview</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <h5 style={{margin: 0}}>Invoice Information</h5>
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
              style={{ paddingRight: 30, paddingLeft: 30,  marginTop: 10 }}
            >
              <Box>
                <Typography variant="caption">Cost To Bill Client:</Typography>
                <Typography variant="body2">$213.33</Typography>
                <Typography variant="body2">1 @ $110.00</Typography>
                <Typography variant="body2">1 @ $103.33</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Subtotal:</Typography>
                <Typography variant="body2">$213.33</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Discount:</Typography>
                <Typography variant="body2">No discount</Typography>
              </Box>
              <Box>
                <Button variant="contained">
              <FontAwesomeIcon icon={faDownload} style={{marginRight: 10}}/>
                   Download
                </Button>
              </Box>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Container>
    </ThemeProvider>
  );
}
