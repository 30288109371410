import * as React from "react";
import {
  IconButton,
  DialogTitle,
  Dialog,
  Container,
  Button,
  DialogContent,
  Box,
  TextField,
  Alert,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

function createData(find, replace, actions) {
  return { find, replace, actions };
}

export default function AddMacrosDialogComponent() {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] =  React.useState([]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addMacro = () => {
    setRows(oldArray => [...oldArray, createData('d','d', '')])
  }

  const [errorFields, setErrorFields] = React.useState(false);
  const [error, setError] = React.useState(false);
  let alertError;
  const handleSubmit = (event) => {
    const data = new FormData(event.currentTarget);
    const user = {
      username: data.get("email"),
      password: data.get("password"),
    };
    event.preventDefault();
    if (user.username === "" || user.password === "") {
      setErrorFields(true);
    } else {
    }
  };

  if (error) {
    alertError = (
      <Alert variant="outlined" severity="error">
        We were unable to log you in. Please verify your username and password.
      </Alert>
    );
  } else {
    alertError = <div />;
  }

  return (
    <div>
      <Button onClick={handleClickOpen}>+ Add Macro</Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" width="xl">
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Add New Macro
          <IconButton color="red" size="large" onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "50rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              onSubmit={handleSubmit}
              noValidate
              style={{ width: "100%" }}
              sx={{ mt: 1 }}
            >
              {alertError}
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                variant="standard"
                name="name"
                autoComplete="name"
                error={errorFields}
                autoFocus
                helperText={errorFields ? "Incorrect entry." : ""}
              />
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Find</TableCell>
                      <TableCell align="right">Replace</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from(rows).map((row, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.find}
                        </TableCell>
                        <TableCell align="right">{row.replace}</TableCell>
                        <TableCell align="right">
                          <FontAwesomeIcon icon={faPen} color="primary" style={{marginRight: 10}}></FontAwesomeIcon>
                          <FontAwesomeIcon icon={faTrash} color="red"></FontAwesomeIcon>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                  marginTop: 100
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="find"
                  label="Find"
                  name="find"
                  autoComplete="find"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="replace"
                  label="Replace"
                  name="replace"
                  autoComplete="find"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                 <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={addMacro}
              >
                ADD
              </Button>
              </Container>
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleClose}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
