import "../../App.css";
import theme from "../../theme";
import {
  FormControl,
  InputLabel,
  Button,
  InputBase,
  Chip,
  Input,
  Select,
  MenuItem,
  Toolbar,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import UserPayTable from "../userPayTable";
import RenderPaymentInfo from "./RenderPayentInfo";
import { useState, useEffect } from "react";
import KeyboardAltIcon from "@mui/icons-material/KeyboardAlt";

const paymentOptions = ["", "PayPal", "Payoneer"];

export default function GeneralProfile(props) {
  const {
    handleSubmit,
    handleFormChange,
    currentUser,
    verifyField,
    CountryList,
    StateList,
    isTheFormChange,
    resetChanges,
    openModal,
    qualitativeGrade,
    handleStartDate,
    handleEndDate,
    downloadPayHistory,
    data,
    isLoading,
    startDate,
    endDate,
    openHotkeysModal,
  } = props;

  const [paymentMethod, setPaymentMethod] = useState(null);
  useEffect(() => {
    if (currentUser.paymentMethod && currentUser.paymentMethod !== "") {
      setPaymentMethod(currentUser.paymentMethod);
    } else {
      setPaymentMethod(null);
    }
  }, [currentUser]);

  const inputStyle = {
    marginTop: theme.spacing(3),
    position: "relative",
    borderRadius: "4px",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    borderBottom: "none",
    border: "1px solid black",
    fontSize: 18,
    padding: "5px 6px",
    height: "46px",

    "&::before": {
      display: "none",
    },
  };

  const selectStyle = {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: "4px",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid black",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "& div::before": {
        borderBottom: "none",
      },
    },

    "& .MuiInputBase-root::before": {
      borderBottom: "none !important",
    },
  };

  return (
    <div className="body">
      <form
        className="profileForm"
        onSubmit={(e) => handleSubmit(e)}
        onChange={(e) => handleFormChange(e)}
      >
        <div className="formContainer">
          <FormControl variant="standard" fullWidth className="formControl">
            <InputLabel shrink htmlFor="firstname">
              First Name
            </InputLabel>

            <InputBase
              className="input"
              sx={inputStyle}
              defaultValue={currentUser.firstname ? currentUser.firstname : ""}
              id="firstName"
              name="firstname"
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" className="formControl" fullWidth>
            <InputLabel shrink htmlFor="lastName">
              Last Name
            </InputLabel>
            <InputBase
              className="input"
              sx={inputStyle}
              defaultValue={verifyField(currentUser.lastname)}
              id="lastName"
              name="lastname"
              readOnly
            />
          </FormControl>
        </div>

        <FormControl
          variant="standard"
          className="formControl"
          fullWidth
          sx={{ marginBottom: "10px" }}
        >
          <InputLabel shrink htmlFor="email">
            Email
          </InputLabel>
          <InputBase
            sx={inputStyle}
            defaultValue={verifyField(currentUser.mail)}
            id="email"
            name="mail"
            required
            type="email"
          />
        </FormControl>

        <FormControl
          variant="standard"
          className="formControl"
          fullWidth
          sx={{ marginBottom: "10px" }}
        >
          <InputLabel shrink htmlFor="country">
            Country
          </InputLabel>
          <Select
            sx={selectStyle}
            defaultValue={verifyField(currentUser.country)}
            id="country"
            name="country"
            className="input"
            onChange={() => handleFormChange()}
          >
            {CountryList.map((country) => (
              <MenuItem key={country.value} value={country.value}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="formContainer">
          <FormControl
            className="formControl"
            variant="standard"
            id={paymentMethod ? "payonnerForm" : null}
            sx={
              paymentMethod === "Payoneer" && paymentMethod !== ""
                ? { width: "25%" }
                : { width: "100%" }
            }
          >
            <InputLabel shrink htmlFor="paymentMethod">
              Payment method
            </InputLabel>
            <Select
              sx={selectStyle}
              id="paymentMethod"
              name="paymentMethod"
              required
              className="input"
              defaultValue={currentUser.paymentMethod}
              onChange={(e) => {
                handleFormChange();
                setPaymentMethod(e.target.value);
              }}
            >
              {paymentOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {paymentMethod && paymentMethod !== "" ? (
            <RenderPaymentInfo
              inputStyle={inputStyle}
              paymentMethod={paymentMethod}
              verifyField={verifyField}
              currentUser={currentUser}
            />
          ) : null}
        </div>

        <FormControl
          variant="standard"
          className="formControl"
          fullWidth
          sx={{ marginBottom: "10px" }}
        >
          <InputLabel shrink htmlFor="address">
            Address 1
          </InputLabel>
          <InputBase
            sx={inputStyle}
            defaultValue={verifyField(currentUser.address1)}
            id="address1"
            name="address1"
            className="input"
          />
        </FormControl>
        <FormControl
          className="formControl"
          variant="standard"
          fullWidth
          sx={{ marginBottom: "10px" }}
        >
          <InputLabel shrink htmlFor="address2">
            Address 2
          </InputLabel>
          <InputBase
            sx={inputStyle}
            defaultValue={verifyField(currentUser.address2)}
            id="address2"
            name="address2"
            className="input"
          />
        </FormControl>

        <div className="formContainer">
          <FormControl className="formControl" variant="standard" fullWidth>
            <label shrink htmlFor="state" id="selectLabel">
              State
            </label>
            <Select
              sx={selectStyle}
              defaultValue={currentUser.state}
              name="state"
              onChange={() => handleFormChange()}
              id="state"
            >
              {StateList.map((state) => (
                <MenuItem key={state.label} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" className="formControl" fullWidth>
            <InputLabel shrink htmlFor="city">
              City
            </InputLabel>
            <InputBase
              sx={inputStyle}
              defaultValue={verifyField(currentUser.city)}
              id="city"
              name="city"
              className="input"
            />
          </FormControl>
        </div>
        <div className="formContainer">
          <FormControl variant="standard" className="formControl" fullWidth>
            <InputLabel shrink htmlFor="phone">
              Phone Number
            </InputLabel>
            <InputBase
              sx={inputStyle}
              defaultValue={verifyField(currentUser.phone)}
              id="phone"
              name="phone"
              className="input"
            />
          </FormControl>
          <FormControl variant="standard" className="formControl" fullWidth>
            <InputLabel shrink htmlFor="zip">
              Zip
            </InputLabel>
            <InputBase
              sx={inputStyle}
              defaultValue={verifyField(currentUser.zip)}
              id="zip"
              name="zip"
              className="input"
            />
          </FormControl>
        </div>

        <div className="formContainer">
          <Button
            sx={{
              color: theme.palette.white.main,
            }}
            className="formButton"
            variant="contained"
            disabled={!isTheFormChange}
            color="success"
            type="submit"
          >
            Save Changes
          </Button>

          <Button
            sx={{
              color: theme.palette.white.main,
            }}
            className="formButton"
            variant="contained"
            disabled={!isTheFormChange}
            color="red"
            onClick={() => resetChanges()}
          >
            Reset changes
          </Button>
        </div>

        <div className="formContainer">
          <Button
            fullWidth
            sx={{
              color: theme.palette.white.main,
            }}
            className="formButton"
            variant="contained"
            color="fourth"
            onClick={() => {
              openModal();
            }}
          >
            Change Password
          </Button>

          <Button
            fullWidth
            endIcon={<KeyboardAltIcon />}
            className="formButton"
            variant="contained"
            onClick={() => openHotkeysModal()}
          >
            My Hotkeys
          </Button>
        </div>
      </form>

      <div className="noForm">
        <div className="noFormBody">
          <div className="informationContainer">
            {/* HIDDING SECTION
            <div className="dateInput">
              <div className="sectionDiv" style={{ marginRight: "17%" }}>
                <label>Pay Rate</label>
                <p>
                  <Chip
                    label={"$" + currentUser.rate.amount + "/sound minute"}
                    color="success"
                    style={{ color: "white" }}
                  />
                </p>
              </div>
              <div className="sectionDiv">
                <label>Grade</label>
                <p>
                  <Chip
                    label={qualitativeGrade(currentUser.grade)}
                    color="primary"
                  />
                </p>
              </div>
            </div> */}

            <div className="dateInput" id="dates">
              <div className="sectionDiv">
                <label>Start Date</label>
                <Input
                  type="date"
                  onChange={handleStartDate}
                  id="startDate"
                  value={startDate}
                />
              </div>
              <div className="sectionDiv">
                <label>End Date</label>
                <Input
                  type="date"
                  id="startDate"
                  onChange={handleEndDate}
                  value={endDate}
                />
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <div
              style={{
                backgroundColor: "",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                endIcon={<DownloadIcon className="downloadIcon" />}
                onClick={() => {
                  downloadPayHistory();
                }}
                sx={{
                  fontSize: "1rem",
                  color:"white !important"
                }}
                color="neutral"
              >
                Pay History
              </Button>
            </div>

            <Button
              variant="contained"
              color="red"
              sx={{
                color: theme.palette.white.main,
              }}
            >
              <p>DEACTIVATE MY ACCOUNT</p>
            </Button>
          </div>
        </div>
        <UserPayTable data={data} isLoading={isLoading} />
        <Toolbar />
      </div>
    </div>
  );
}
