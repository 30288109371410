import "../styles/TranscriberQueue.css";
import logo from "../assets/img/logo.png";

import { React, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import {
  Card,
  Box,
  Typography,
  Container,
  List,
  Toolbar,
  CardMedia,
  ListItemText,
  ListItem,
} from "@mui/material";
import HtmlComponent from "../components/HtmlComponent";
export default function TermsOfService() {
  useEffect(() => {
    document.title = "Privacy Policy - Focus Forward Tops";
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box
        className="App"
        style={{
          backgroundColor: "white",
 
          justifyContent: "flex-start",
        }}
      >
        <Toolbar sx={{ backgroundColor: "white", width: "100%" , marginTop: "1rem"}}>
          <Box sx={{ flexGrow: 1 }}>
            <CardMedia
              component="img"
              style={{ width: "15rem" , margin: "1rem 4rem", }}
              image={logo}
            />
          </Box>
        </Toolbar>
        <Box
          sx={{
            maxWidth: 1024,
            margin: "0 auto",
            marginTop: "3rem",
            padding: "16px",
          }}
        >
          <HtmlComponent htmlPath={"/legal/wordiblyTermsOfService.html"} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
