import { React, useEffect, useState, useMemo } from "react";
import useZustant from "../hooks/useZustant";
import { ThemeProvider } from "@emotion/react";
import useShow from "../hooks/useShow";
import theme from "../theme";
import {
  Container,
  Toolbar,
  Typography,
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  //Checkbox,
  TableSortLabel,
  TableCell,
  TableBody,
  TablePagination,
  Link,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../App.css";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Requests from "../logic/Requests";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import PlaceNewOrderButton from "../components/PlaceNewOrderButton"
const headCells = [
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "Order #",
  },
  {
    id: "placed",
    numeric: false,
    disablePadding: false,
    label: "Placed",
  },
  {
    id: "placed_by",
    numeric: false,
    disablePadding: false,
    label: "Placed By",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "service",
    numeric: false,
    disablePadding: false,
    label: "Service",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: false,
    label: "Size",
  },
  {
    id: "cost",
    numeric: false,
    disablePadding: false,
    label: "Cost",
  },
];

export default function OrderHistory() {
  const { setCurrentClientPage, changeIsInClientsSettings, user } = useZustant();
  const navigate = useNavigate();
  const { show, open, close } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
});
  useEffect(() => {
    document.title = "Order History - Focus Forward";
    setCurrentClientPage("Order History");
    changeIsInClientsSettings(false);
  }, []);
  const { data: dataOrderHistory, isLoading: isLoadingOrderHistory , setData: setDataOrderHistory} =  useFetch(Requests.getOrderHistory);

  const [limitDateMin,setLimitDateMin]=useState(null);
  const [limitDateMax,setLimitDateMax]=useState(null);
  const [filteredDataOrderHistory,setFilteredDataOrderHistory]=useState([]);

  useEffect(()=>{  
    setFilteredDataOrderHistory(dataOrderHistory);
  },[dataOrderHistory,isLoadingOrderHistory])

useEffect(()=>{
    setFilteredDataOrderHistory(
      dataOrderHistory?.filter((order) =>
      (limitDateMin ? new Date(order.placed) >= limitDateMin : true) &&
      (limitDateMax ? new Date(order.placed) <= limitDateMax : true)
    )
    );
},[limitDateMin,limitDateMax])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredDataOrderHistory?.length) : 0;

    // transform date
    function formattedDate(dateStr) {
      const dateObj = new Date(dateStr);
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      
      return `${month}-${day}-${year}`;
    }
  
  // handle export file: 

  const handleDownloadExcel = async () => {

    try {
      const ids = filteredDataOrderHistory?.map(element => element._id);
      const response = await Requests.exportOrderHistory(ids);

      // Create a Blob with the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'order_history.xlsx';
      a.click();

      // Clean up the URL and Blob
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
      handleAlert("error", error.message)
    } finally {
      handleAlert("success", "order history downloading")
    }
  };

  const handleAlert = (type, message) => {
    setCurrentAlertState({
    type,
    message,
    });
    open();
};
// table properties
const [selected, setSelected] = useState([]);
const [order, setOrder] = useState('asc');
const [orderBy, setOrderBy] = useState('placed');
const isSelected = (name) => selected.indexOf(name) !== -1;
const handleSelectAllClick = (event) => {
  if (event.target.checked) {
    const newSelected = filteredDataOrderHistory.map((n) => n.name);
    setSelected(newSelected);
    return;
  }
  setSelected([]);
};
const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
         {/*  <TableCell padding="checkbox">
        <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> 
        </TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
// sort
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, comparator) {

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// pagination
const visibleRows = useMemo(() =>{
  if(filteredDataOrderHistory?.length>0){
    return stableSort(filteredDataOrderHistory, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )
  }
}
,
[order, orderBy, page, rowsPerPage,filteredDataOrderHistory],


);

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container
          style={{
            width: "100%",
            paddingTop: 4,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Typography variant="h1" sx={{ mb: 5, mt: 5 }}>
              Order History
            </Typography>
            <PlaceNewOrderButton />
          </Box>

          <Box sx={{ width: "100%" }}>
            <Toolbar>
              <Typography variant="h5" className="toolbarLabel">
                FROM
              </Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => <TextField {...params} />}
                className="datePicker"
                onChange={(event) => setLimitDateMin(event.$d)}
              />
              <Typography variant="h5" className="toolbarLabel">
                TO
              </Typography>

              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ padding: 0 }}
                className="datePicker"
                onChange={(event) => setLimitDateMax(event.$d)}
              />
              <Button
                className="toolbarButton"
                onClick={() => {
                  setLimitDateMin(null);
                  setLimitDateMax(null);
                }}
              >
                Reset
              </Button>
              <Button
                color="fourth"
                sx={{ fontWeight: "bold" }}
                endIcon={<LaunchIcon />}
                className="toolbarButton"
                onClick={handleDownloadExcel}
              >
                Export
              </Button>
            </Toolbar>

            <Paper sx={{ width: "100%", mb: 2, minWidth: "900px", scroll: "auto" }}>
              <TableContainer >
                <Table aria-label="simple table" size="large">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={filteredDataOrderHistory?.length}
                  />
                  <TableBody>
                    {isLoadingOrderHistory ? (
                      <TableRow>
                        <TableCell colSpan={5}>loading...</TableCell>
                      </TableRow>
                    ) : (
                        visibleRows?.map((data, i) => {
                        const isItemSelected = isSelected(data.id);
                        const labelId = `enhanced-table-checkbox-${i}`;

                        return (
                        <TableRow key={i}>
                         {/* <TableCell padding="checkbox">
                             <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            /> 
                          </TableCell>*/}
                          <TableCell component="th" scope="row">
                            <Tooltip title={data.project_id} arrow>
                              <Button
                                sx={{
                                  textDecoration: "none",
                                  color: theme.palette.purple.main,
                                  cursor: "pointer",
                                }}
                              >
                                {data.project_id.slice(-6)}
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{formattedDate(data.placed)}</TableCell>
                          <TableCell>{data.placed_by}</TableCell>
                          <TableCell><Typography style={{textTransform: "capitalize"}} className={ data.status==="completed" ? "completedOrderStatus" : ""}>{data.status}</Typography></TableCell>
                          <TableCell>{data.service}</TableCell>
                          <TableCell>{data.size}</TableCell>
                          <TableCell>$ {data.cost.toFixed(2)}</TableCell>
                        </TableRow>);
})
                    )}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 1 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={filteredDataOrderHistory?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
