import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import theme from "../theme";
import Utils from "../utils";
import { useState, useMemo, useEffect } from "react";

export default function UserPayTable({ data, isLoading }) {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (data) {
      let total = 0;
      data.forEach((index) => {
        total += index.amount;
      });
      setTotal(total);
    }
  }, [data]);

  const styleHeadRow = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    fontSize: 12,
    py: 1,
  };

  if (isLoading) {
    return (
      <div className="tableContainer">
        <CircularProgress style={{ marginTop: "auto", marginBottom: "auto" }} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="tableContainer" style={{ height: "100%" }}>
        <h2 style={{ marginTop: "auto", marginBottom: "auto" }}>No Results</h2>
      </div>
    );
  }

  return (
    <Paper
      sx={{ width: "100%", mb: 2, mt: 5, mx: 1 }}
      className="tableContainer"
    >
      <TableContainer
        sx={{
          overflowX: "auto",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            border: "2px solid transparent",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={styleHeadRow}>Unit ID</TableCell>
              <TableCell sx={styleHeadRow}>Rate/Score</TableCell>
              <TableCell sx={styleHeadRow}>Submission Date</TableCell>
              <TableCell sx={styleHeadRow}>Pay/Min</TableCell>
              <TableCell sx={styleHeadRow}>Length</TableCell>
              <TableCell sx={styleHeadRow}>Total Earned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={row._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                align="center"
              >
                <TableCell align="center">
                  {row.unit.substr(row.unit.length - 8)}
                </TableCell>
                <TableCell align="center">{row.grade}</TableCell>
                <TableCell align="center">{row.completed}</TableCell>
                <TableCell align="center">
                  ${parseFloat(row.transcriber_rate).toFixed(2)}
                </TableCell>
                <TableCell align="center">{Utils.toMMSS(row.length)}</TableCell>
                <TableCell align="center">
                  ${parseFloat(row.amount).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                backgroundColor: "#f4f4f4",
              }}
            >
              <TableCell align="center">Total:</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center">
                ${parseFloat(total).toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
