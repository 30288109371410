import { Alert, Snackbar } from "@mui/material";

export default function CustomSnackBarAlert(props) {
  const { show, close, children, type } = props;

  return (
    <Snackbar
      open={show}
      onClose={close}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      key={children}
      autoHideDuration={5000}
    >
      <Alert severity={type}>{children}</Alert>
    </Snackbar>
  );
}
