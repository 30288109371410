import * as React from "react";
import {
  IconButton,
  DialogTitle,
  Dialog,
  Container,
  Button,
  DialogContent,
  Box,
  TextField,
  Alert,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faClose } from "@fortawesome/free-solid-svg-icons";

export default function EditDialogComponent() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [errorFields, setErrorFields] = React.useState(false);
  const [error, setError] = React.useState(false);
  let alertError;
  const handleSubmit = (event) => {
    const data = new FormData(event.currentTarget);
    const user = {
      username: data.get("email"),
      password: data.get("password"),
    };
    event.preventDefault();
    if (user.username === "" || user.password === "") {
      setErrorFields(true);
    } else {
    }
  };

  if (error) {
    alertError = (
      <Alert variant="outlined" severity="error">
        We were unable to log you in. Please verify your username and password.
      </Alert>
    );
  } else {
    alertError = <div />;
  }

  return (
    <div>
      <IconButton color="primary" size="large" onClick={handleClickOpen}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" width="xl">
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit Project Details
          <IconButton color="red" size="large" onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "50rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              style={{ width: "100%" }}
              sx={{ mt: 1 }}
            >
              {alertError}
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                variant="standard"
                name="name"
                autoComplete="name"
                error={errorFields}
                autoFocus
                helperText={errorFields ? "Incorrect entry." : ""}
              />
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                  marginTop: 10
                }}
              >
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  labelPlacement="top"
                  label="Split Audio"
                  style={{ minWidth: 400, margin: 0, alignItems: "flex-start"}}
                />
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Change Project Manager
                  </InputLabel>
                  <Select
                  style={{maxWidth: 400}}
                    labelId="demo-simple-select-label"
                    variant="standard"
                    id="demo-simple-select"
                    label="Select"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="Primary Contact"
                  name="primaryContact"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400}}
                  id="transcriberRateMinute"
                  label="Transcriber rate/minute"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginTop: 20
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="End Client Contact"
                  name="primaryContact"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400, marginTop: 0 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <FormControl  style={{ minWidth: 400, margin: 0, alignItems: "flex-start"}}>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Billing rounding
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Nearest minute"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Nearest 15 minute"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="Primary Contact"
                  name="End Clien Email(s)"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400}}
                  id="transcriberRateMinute"
                  label="Billable Hours"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="Primary Contact"
                  name="Send invoices to"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400}}
                  id="transcriberRateMinute"
                  label="Send transcripts to"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="CC"
                  name="Send invoices to"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400}}
                  id="transcriberRateMinute"
                  label="Date"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="End Client"
                  name="End Client"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400}}
                  id="transcriberRateMinute"
                  label="Location"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="Job Number"
                  name="Job Number"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
               <FormControlLabel
                  control={<Switch defaultChecked />}
                  labelPlacement="top"
                  label="Automatically Reassign"
                  style={{ minWidth: 400, margin: 0, alignItems: "flex-start"}}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  id="primaryContact"
                  label="PO Numbe"
                  name="End Client"
                  autoComplete="primaryContact"
                  style={{ marginRight: 15, maxWidth: 400 }}
                  variant="standard"
                  error={errorFields}
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400}}
                  id="transcriberRateMinute"
                  label="Reassign After (hours)"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
              </Container>
              <Container
                style={{
                  display: "flex",
                  padding: 0,
                  justifyContent: "space-between",
                  marginTop: 10
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  style={{maxWidth: 400, marginTop: 0}}
                  id="transcriberRateMinute"
                  label="Client PM (For ONE Board)"
                  name="transcriberRateMinute"
                  autoComplete="transcriberRateMinute"
                  error={errorFields}
                  variant="standard"
                  autoFocus
                  helperText={errorFields ? "Incorrect entry." : ""}
                />
                <FormControl sx={{ m: 1, minWidth: 400 }}>
                  <InputLabel id="demo-simple-select-label">
                  Change client
                  </InputLabel>
                  <Select
                  style={{maxWidth: 400}}
                    labelId="demo-simple-select-label"
                    variant="standard"
                    id="demo-simple-select"
                    label="Select"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Container>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Job ID"
                variant="standard"
                name="name"
                autoComplete="name"
                error={errorFields}
                autoFocus
                helperText={errorFields ? "Incorrect entry." : ""}
              />
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleClose}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
