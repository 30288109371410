import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import useFetch from "../hooks/useFetch";
import useShow from "../hooks/useShow";
import {
  Box,
  Container,
  MenuItem,
  ThemeProvider,
  Typography,
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import theme from "../theme";
import { monthsList } from "../lists";
import "../App.css";
import { useNavigate } from "react-router-dom";
import Requests from "../logic/Requests";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";

const headCells = [
  { id: "recorder", label: "Recorder On" },
  { id: "placedon", label: "Placed On" },
  { id: "activity", label: "Activity" },
  { id: "usd", label: "USD" },
  { id: "balance", label: "Balance" },
];

export default function TransactionHistory() {
  const {
    setCurrentClientPage,
    changeIsInClientsSettings,
    isOpenSidebar,
    user,
    changeClientPrepaidBalance,
    clientPrepaidBalance,
  } = useZustant();
  const navigate = useNavigate();
  const { show, open, close } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  useEffect(() => {
    document.title = "Transaction History - Focus Forward";
    setCurrentClientPage("Transaction History");
    changeIsInClientsSettings(false);
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { data: clientInformation, isLoading } = useFetch(
    Requests.getClient,
    user.client
  );

  useEffect(() => {
    changeClientPrepaidBalance(
      clientInformation?.prepaidBalance ? clientInformation.prepaidBalance : 0
    );
  }, [isLoading]);

  // get Transaction history
  const staticData = [
    {
      order: "TC0396915073",
      placed: "2021-08-10",
      placedBy: "John Doe",
      status: "Completed",
      service: "Transcript",
      size: "2h",
      cost: "$100.00",
    },
    {
      order: "TC0255336679",
      placed: "2022-04-14",
      placedBy: "Silvie Conor",
      status: "Completed",
      service: "Transcript",
      size: "3m",
      cost: "$3.00",
    },
    {
      order: "TC0335733365",
      placed: "2021-08-10",
      placedBy: "John Doe",
      status: "Cancelled",
      service: "Auto Transcript",
      size: "1m",
      cost: "$0.27",
    },
  ];
  const { data: dataTransactionHistory, isLoading: isLoadingTransactionHistory , setData: setDataTransactionHistory} =  useFetch(Requests.getTransactionHistory);

  
  function stableSort(array) {
    if(array && array.length!=0){
      let stabilized = array.map((el, index) => [el, index]);  
      stabilized.sort((a, b) => {
        const dateA = new Date(a[0].recorder_on);
        const dateB = new Date(b[0].recorder_on);
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      return stabilized.map((el) => el[0]);
    }
  }
  

  // export to excel file
  const handleDownloadExcel = async () => {
    try {
      const ids = filteredDataTransactionHistory?.map(element => element._id);
      const response = await Requests.exportTransactionHistory(ids);

      // Create a Blob with the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "transaction_history.xlsx";
      a.click();

      // Clean up the URL and Blob
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      handleAlert("error", error.message);
    } finally {
      handleAlert("success", "order history downloading");
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - staticData.length) : 0;

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };

  function formattedDate(dateStr) {
    const dateObj = new Date(dateStr);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();

    return `${month}-${day}-${year}`;
  }

  // Filter data function
  const [monthFrom, setMonthFrom] = useState(
    String(new Date().getMonth()).padStart(2, "0")
  );
  const [yearFrom, setYearFrom] = useState(
    String(new Date().getMonth()).padStart(2, "0") === "12"
      ? String(new Date().getFullYear() - 1)
      : String(new Date().getFullYear())
  );
  const [monthTo, setMonthTo] = useState(
    String(new Date().getMonth() + 1).padStart(2, "0")
  );
  const [yearTo, setYearTo] = useState(String(new Date().getFullYear()));
  const [filteredDataTransactionHistory, setFilteredDataTransactionHistory] =
    useState(null);

  useEffect(() => {
    handleChange({ target: { name: "yearTo", value: yearTo } });
  }, [
    dataTransactionHistory,
    isLoadingTransactionHistory,
    monthFrom,
    yearFrom,
    monthTo,
    yearTo,
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const dateStates = {
      monthFrom: setMonthFrom,
      yearFrom: setYearFrom,
      monthTo: setMonthTo,
      yearTo: setYearTo,
    };

    const dateFrom = new Date(
      name === "yearFrom" ? value : yearFrom,
      name === "monthFrom" ? value : monthFrom,
      1
    );
    const dateTo = new Date(new Date(
      name === "yearTo" ? value : yearTo,
      name === "monthTo" ? value : +monthTo+1,
      1
    ).getTime() - 1 * 24 * 60 * 60 * 1000);
    
    if (dateFrom <= dateTo) {
      dateStates[name](value);
      setFilteredDataTransactionHistory(stableSort(
        dataTransactionHistory?.filter(
          (transaction) =>
            (new Date(transaction.recorder_on) >= dateFrom  ) &&
            (new Date(transaction.recorder_on) <= dateTo  )
        )
      ));
    } else {
      //warning
      handleAlert(
        "error",
        "Invalid Date Selection - the starting date could not be lower than the end date."
      );
    }
  };

  const handleChangeRefesh = () => {
    setMonthFrom(String(new Date().getMonth()).padStart(2, "0"));
    setYearFrom(
      String(new Date().getMonth()).padStart(2, "0") === "12"
        ? String(new Date().getFullYear() - 1)
        : String(new Date().getFullYear())
    );
    setMonthTo(String(new Date().getMonth() + 1).padStart(2, "0"));
    setYearTo(String(new Date().getFullYear()));
  };
  
  function ShowMonth(props) {
    const {data0,data1}=props;
    if(!data1 && data0){
      const data1={recorder_on: new Date(yearTo,monthTo-1,1)};  
      if (new Date(data0.recorder_on).getFullYear() === new Date(data1.recorder_on).getFullYear() && new Date(data0.recorder_on).getMonth() === new Date(data1.recorder_on).getMonth()){
        return 
      }else{
        const yearDiff = new Date(data1?.recorder_on).getFullYear() -  new Date(data0?.recorder_on).getFullYear();
        let monthDiff = new Date(data1?.recorder_on).getMonth() -  new Date(data0?.recorder_on).getMonth() +  yearDiff * 12;
    
        let monthIndex = new Date(data0.recorder_on).getMonth()+1;
        let monthArray = [];
        let year= new Date(data0?.recorder_on).getFullYear();
        for (let c = 0; c < monthDiff; c++) {
          if (monthIndex + c < 12){
            monthArray.push(`${monthsList[c+monthIndex]?.fullName} ${year}`);
          }else{
            year=year+1;
            monthDiff=monthDiff-c;
            monthIndex = 0;
            c = 0;
            
            monthArray.push(`${monthsList[c+monthIndex]?.fullName} ${year}`);
          }
        }
        
        return  monthArray.map((month,i)=><TableRow key={i} style={{ height: 1 * emptyRows }}>
          <TableCell colSpan={5} style={{ width: "100%", fontWeight: "bold" }}>
            {`${month}`}
          </TableCell>
        </TableRow>)
      }
    }

    const yearDiff = new Date(data1?.recorder_on).getFullYear() -  new Date(data0?.recorder_on).getFullYear();
    let monthDiff = new Date(data1?.recorder_on).getMonth() -  new Date(data0?.recorder_on).getMonth() +  yearDiff * 12;

    try{
    if(monthDiff===0){
      return
    }
    let monthIndex = new Date(data0.recorder_on).getMonth()+1;
    let monthArray = [];
    let year= new Date(data0?.recorder_on).getFullYear();
    for (let c = 0; c < monthDiff; c++) {
      if (monthIndex + c < 12){
        monthArray.push(`${monthsList[c+monthIndex]?.fullName} ${year}`);
      }else{
        year=year+1;
        monthDiff=monthDiff-c;
        monthIndex = 0;
        c = 0;
        
        monthArray.push(`${monthsList[c+monthIndex]?.fullName} ${year}`);
      }
    }
    return  monthArray.map((month,i)=><TableRow key={i} style={{ height: 1 * emptyRows }}>
      <TableCell colSpan={5} style={{ width: "100%", fontWeight: "bold" }}>
        {`${month}`}
      </TableCell>
    </TableRow>)
    }catch(error){
      if (data1 && !data0){
        const data0={recorder_on: new Date(yearFrom,monthFrom-1,1)};
        
        if (new Date(data0.recorder_on).getFullYear() === new Date(data1.recorder_on).getFullYear() && new Date(data0.recorder_on).getMonth() === new Date(data1.recorder_on).getMonth()){
          return <TableRow style={{ height: 1 * emptyRows }}>
            <TableCell colSpan={5} style={{ width: "100%", fontWeight: "bold" }}>
              {`${monthsList[new Date(data0.recorder_on).getMonth()]?.fullName} ${new Date(data0.recorder_on).getFullYear()}`}
            </TableCell>
          </TableRow>
        }else{
          const data0={recorder_on: new Date(yearFrom,monthFrom-1,1)};
          const yearDiff = new Date(data1?.recorder_on).getFullYear() -  new Date(data0?.recorder_on).getFullYear();
          let monthDiff = new Date(data1?.recorder_on).getMonth()+1 -  new Date(data0?.recorder_on).getMonth() +  yearDiff * 12;
          let monthIndex = new Date(data0.recorder_on).getMonth();
          let monthArray = [];
          let year= new Date(data0?.recorder_on).getFullYear();
          for (let c = 0; c < monthDiff; c++) {
            if (monthIndex + c < 12){
              monthArray.push(`${monthsList[c+monthIndex]?.fullName} ${year}`);
            }else{
              year=year+1;
              monthDiff=monthDiff-c;
              monthIndex = 0;
              c = 0;
              
              monthArray.push(`${monthsList[c+monthIndex]?.fullName} ${year}`);
            }
          }
          
          return  monthArray.map((month,i)=><TableRow key={i} style={{ height: 1 * emptyRows }}>
            <TableCell colSpan={5} style={{ width: "100%", fontWeight: "bold" }}>
              {`${month}`}
            </TableCell>
          </TableRow>)
        }

      }else{
        console.error(error)
      }
      return
    }
  };
  // Format Activity
  function FormatActivity(props){
    const {activity}=props;
    const regex1 = RegExp('[0-9a-f]{24}', 'g');
    let array1;
    let indexs = [];
    let text = [];
    let c=0;
    while ((array1 = regex1.exec(activity)) !== null) {
      indexs.push(array1.index);
      text.push(activity.slice(c,array1.index));
      c=array1.index;
      text.push(activity.slice(c,array1.index+24));
    }
    if(c!=0 && c+24!=activity.length){
      text.push(activity.slice(c+24,activity.length-1));
    }
    return (
      <Box>
        {text.length > 0
          ? text.map((e, i) => (
              <>
                {i % 2 == 0 ? (
                  <>{e}</>
                ) : (
                  <Tooltip title={e} arrow>
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(e);
                        handleAlert("success", "Full ID copied");
                      }}
                    >
                      {e.slice(-6)}
                    </Button>
                  </Tooltip>
                )}
              </>
            ))
          : activity}
      </Box>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box sx={{ width: "100%" }}>
        <Container sx={{ width: "100%", pt: 3 }}>
          <div style={{ marginBottom: "30px" }}>
            <Typography variant="h1">Transaction History</Typography>
          </div>

          <div>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{ borderRight: "1px solid black", paddingRight: "5px" }}
              >
                Remaining prepaid balance:{" "}
                <b>${isLoading ? "loading..." : clientPrepaidBalance}</b>
              </span>

              <Button color="fourth" onClick={() => navigate("/paymentdetails")}>
                Prepay Online
              </Button>
            </div>

            <Box sx={{ width: "100%", mb: 2 }}>
              <Toolbar
                className={
                  isOpenSidebar
                    ? "transactionToolbarSidebar"
                    : "transactionToolbar"
                }
              >
                <Button
                  variant="outlined"
                  className="export"
                  onClick={handleDownloadExcel}
                >
                  export as .csv
                </Button>

                <Box
                  className="timeForm"
                  style={{ display: "flex", alignItem: "center" }}
                >
                  <Box>
                    <InputLabel style={{ marginRight: "5px" }}>
                      Show from:
                    </InputLabel>
                    <FormControl style={{ marginRight: "5px" }}>
                      <Select
                        value={monthFrom}
                        className="transactionSelect"
                        name="monthFrom"
                        onChange={handleChange}
                      >
                        {monthsList.map((month, index) => (
                          <MenuItem key={index} value={month.value}>
                            {month.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        value={yearFrom}
                        className="transactionSelect"
                        name="yearFrom"
                        onChange={handleChange}
                      >
                        
                        <MenuItem value="2021">2021</MenuItem>
                        <MenuItem value="2022">2022</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                        
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <InputLabel style={{ marginRight: "5px" }}>To:</InputLabel>
                    <FormControl style={{ marginRight: "5px" }}>
                      <Select
                        value={monthTo}
                        className="transactionSelect"
                        name="monthTo"
                        onChange={handleChange}
                      >
                        {monthsList.map((month, index) => (
                          <MenuItem key={index} value={month.value}>
                            {month.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        value={yearTo}
                        className="transactionSelect"
                        name="yearTo"
                        onChange={handleChange}
                      >
                        <MenuItem value="2021">2021</MenuItem>
                        <MenuItem value="2022">2022</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      sx={{ height: "30px" }}
                      onClick={handleChangeRefesh}
                    >
                      Refresh
                    </Button>
                  </Box>
                </Box>
              </Toolbar>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sx={{
                            minWidth: "75px !important",
                          }}
                          style={{ width: headCell.width }}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableHead>

                    <TableBody>
                      {isLoadingTransactionHistory ? (
                        <TableRow>
                          <TableCell>loading...</TableCell>
                        </TableRow>
                      ) : (
                        <>
                        {filteredDataTransactionHistory?.map((data, i) =>{
                          return (
                              <>
                                <ShowMonth data0={filteredDataTransactionHistory[i - 1]} data1={data} />
                                <TableRow key={i}>
                                  <TableCell style={{ width: "15%" }}>
                                    {formattedDate(data.recorder_on)}
                                  </TableCell>
                                  <TableCell style={{ width: "15%" }}>
                                    {formattedDate(data.recorder_on)}
                                  </TableCell>
                                  <TableCell>
                                    <FormatActivity activity={data.activity}/>
                                  </TableCell>
                                  <TableCell style={{ width: "15%" }}>
                                    $ {parseFloat(data.usd).toFixed(2)}
                                  </TableCell>
                                  <TableCell style={{ width: "15%" }}>
                                    $ {data.balance ? data.balance.toFixed(2) : "0.00"}
                                  </TableCell>
                                </TableRow>
                              </>
                          )
                        })}
                        {filteredDataTransactionHistory && <ShowMonth data0={filteredDataTransactionHistory[filteredDataTransactionHistory.length-1]}/>}
                        </>
                      )
                      }
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 1 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
