import { React, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import {
  Box,
  Toolbar,
  Button,
  TablePagination,
  Typography,
  Container,
  Link,
} from "@mui/material";
import Requests from "../logic/Requests";
import useZustant from "../hooks/useZustant";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/TranscriberQueue.css";
import useShow from "../hooks/useShow";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import TranscriberHub from "../components/TranscriberWork/TranscriberHub";
import { useLocation } from "react-router-dom";

export default function TranscriberQueue() {
  const [sortedUnits, setSortedUnits] = useState([]);
  const location = useLocation();

  const {
    show: showUnitError,
    open: openUnitError,
    close: closeUnitError,
  } = useShow();

  const { user, transcriberHasUnit, changeTranscriberHasUnit } = useZustant();

  const navigate = useNavigate();

  let { idUser } = useParams();

  const [data, setData] = useState(null);

  const [page, setPage] = useState(1);

  const [showMedia, setShowMedia] = useState(false);
  const [unitToShow, setUnitToShow] = useState("");
  const [mediaType, setmediaType] = useState("");
  const [pageSize, setPageSize] = useState(15);

  const [isLoading, setIsLoading] = useState(false);

  const handleChangeTranscriberState = {
    setTrue: () => changeTranscriberHasUnit(true),
    setFalse: () => changeTranscriberHasUnit(false),
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const verifyuserHasUnit = () => {
    Requests.getUserUnit(idUser).then((res) => {
      if (res.status === 200) {
        if (res.data.units.length > 0) {
          changeTranscriberHasUnit(true);
        } else {
          changeTranscriberHasUnit(false);
        }
      } else {
        changeTranscriberHasUnit(false);
      }
    });
  };

  useEffect(() => {
    getUnits();
  }, [page, pageSize, location]);

  const getUnits = async(isRefresh) => {
    if (data === null || isRefresh) {
      setIsLoading(true);
    }

    if (location.pathname.split("/")[1] === "projects") {
       Requests.getAvailableUnits(idUser, page, pageSize).then((res) => {
        if (res.status === 200) {
           setData(res.data);
           setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } else if (location.pathname.split("/")[1] === "mywork") {
       Requests.getUserUnit(idUser).then((res) => {
        if (res.status === 200) {
           setData(res.data);
           setIsLoading(false);
        } else {
           setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    document.title = "Work Queue- Focus Forward Tops";
  }, []);
/* to fix
  useEffect(() => {
    if (idUser !== user.id) {
      navigate("/projects/queue/" + user.id);
    }
  }, [idUser]);*/

  useEffect(() => {
    if (data) {
      setSortedUnits(() => {
        const sortedArr = data.units.sort((a, b) => {
          if (a.assigned_to === user.id && (a.state === "Accepted" || a.state === "Ready for Review")) {
            return -1;
          } else if (b.assigned_to === user.id) {
            return 1;
          } else {
            return 0;
          }
        });
        return sortedArr;
      });

      verifyuserHasUnit();
    }
  }, [data]);

  const changeUnitState = (unitId, action) => {
    const updatedArray = sortedUnits.map((unit) => {
      if ((unit.unit_id === unitId) & (action === "accept")) {
        return {
          ...unit,
          state: "Accepted",
          assigned_to: user.id,
        };
      } else if ((unit.unit_id === unitId) & (action === "reject")) {
        return {
          ...unit,
          state: "Rejected",
          assigned_to: null,
          rejected_count: parseInt(unit.rejected_count) + 1,
        };
      } else {
        return unit;
      }
    });

    setSortedUnits(updatedArray);
  };

  const showMediaModal = (unit_id, mediaType, clear = false) => {
    setUnitToShow(unit_id);
    setmediaType(mediaType);
    setShowMedia(!showMedia);
    if (clear) {
      setUnitToShow("");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        show={showUnitError}
        close={closeUnitError}
        type="error"
      >
        You cannot accept more than one unit at a time.
      </CustomSnackBarAlert>

      {transcriberHasUnit !== null ? (
        <Box sx={{ mt: 3 }}>
          <div className="ContainerTransriberQueue" fullWidth>
            <div>
              <Toolbar sx={{ backgroundColor: "", padding: "0 !important" }}>
                {location.pathname.split("/")[1] === "mywork" ||
                (transcriberHasUnit &&
                  location.pathname.split("/")[1] === "projects") ? null : (
                  <Button
                    variant="contained"
                    color="fourth"
                    sx={{ color: "white !important" }}
                    startIcon={<FontAwesomeIcon icon={faArrowsRotate} />}
                    onClick={() => {
                      setData(null);
                      getUnits(true);
                    }}
                  >
                    Refresh
                  </Button>
                )}
              </Toolbar>
              {transcriberHasUnit &&
              location.pathname.split("/")[1] === "projects" ? (
                <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h2" sx={{ fontWeight: 500 }}>
                    Currently you already have an active job, you can see it by
                    visiting{" "}
                    <Link
                      onClick={() => {
                        navigate(`/mywork/${user.id}`);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      MY WORK
                    </Link>
                  </Typography>
                </Container>
              ) : !transcriberHasUnit &&
                location.pathname.split("/")[1] === "mywork" ? (
                <Container>
                  <Typography variant="h2" sx={{ fontWeight: 500 }}>
                  You currently have no claimed jobs. Please visit FIND WORK to review available assignments.
                  </Typography>
                </Container>
              ) : (
                <TranscriberHub
                  user={user}
                  showMediaModal={showMediaModal}
                  showMedia={showMedia}
                  unitToShow={unitToShow}
                  mediaType={mediaType}
                  isLoading={isLoading}
                  sortedUnits={sortedUnits}
                  setSortedUnits={setSortedUnits}
                  transcriberHasUnit={transcriberHasUnit}
                  handleChangeTranscriberState={handleChangeTranscriberState}
                  changeUnitState={changeUnitState}
                  openUnitError={openUnitError}
                  idUser={idUser}
                  location={location}
                />
              )}
              {location.pathname.split("/")[1] === "mywork" ||
              (transcriberHasUnit &&
                location.pathname.split("/")[1] === "projects") ? null : (
                <TablePagination
                  rowsPerPageOptions={[15, 25, 50]}
                  component="div"
                  page={page}
                  labelDisplayedRows={({ from, to, count }) => ``}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{
                    disabled: data ? !data.isMoreUnitsAvailable : true,
                  }}
                  backIconButtonProps={{ disabled: page === 1 }}
                  sx={{ marginBottom: "30px" }}
                />
              )}
            </div>
          </div>
        </Box>
      ) : null}
      <Box sx={{
        height: "150px"
      }}></Box>
    </ThemeProvider>
  );
}
