import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import useZustant from "../../hooks/useZustant";
import { useLocation } from "react-router-dom";
import MenuDropDown from "../MenuDropDown";
import "../../App.css";

function TranscriberTabs() {
  const { user } = useZustant();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[1];
  const projectLocation = location.pathname.split("/")[3];

  const [value, setValue] = useState(() => {
    if (currentLocation === "mywork") {
      return 0;
    } else if (currentLocation === "projects") {
      return 1;
    } else if (currentLocation === "knowledge") {
      return 2;
    }
  });
  const prevStateRef = useRef(null);

  useEffect(() => {
    if (currentLocation === "mywork") {
      setValue(0);
    } else if (currentLocation === "projects") {
      setValue(1);
    } else if (currentLocation === "knowledge") {
      setValue(2);
    }
  }, [location]);

  useEffect(() => {
    if (value !== null) {
      prevStateRef.current = value;
    }
  }, [value]);

  return (user.rolename === "Transcriber" || (user.rolename === "QA")) &&
    (currentLocation === "mywork" ||
      (currentLocation === "projects" && projectLocation !== "transcript") ||
      currentLocation === "knowledge") ? (<>
        <div className="transcriberTabsMainContainer" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px !important' }}>
          <Tabs
            value={value}
            textColor="white"
            indicatorColor="secondary"
            className="transcriberTabsContainer">
            <Tab
              sx={{ textTransform: 'capitalize' }}
              className="transcriberTabs"
              label="My Work"
              onClick={() => {
                navigate(`/mywork/${user._id}`);
              }}
            />
            <Tab
              sx={{ textTransform: 'capitalize' }}
              className="transcriberTabs"
              label="Find Work"
              onClick={() => {
                navigate(`/projects/queue/${user._id}`);
              }}
            />
            <Tab
              sx={{ textTransform: 'capitalize' }}
              className="transcriberTabs"
              label="Knowledge Center"
              onClick={() => {
                navigate(`/knowledge`);
              }}
            />
          </Tabs>
          <MenuDropDown />
        </div>
      </>
  ) : null;
}

export default React.memo(TranscriberTabs);
