import TextField from "@mui/material/TextField";
import { hasCommandModifier } from "draft-js/lib/KeyBindingUtil";
import "../../App.css";

const InputhotKey = (props) => {
  const {
    value,
    isMacOs,
    name,
    hotKeys,
    handleHotKeys,
    userHotKeys,
    handleAlert,
    disableLabel,
    key
  } = props;

  function evaluateSecondaryHotsKeys(event) {
    let isUsed = false;
    userHotKeys.forEach((element) => {
      if (
        element.keyCode === event.keyCode &&
        (element.ctrlKey === event.ctrlKey || element.ctrlKey === event.metaKey)
      ) {
        isUsed = true;
      }
    });
    return isUsed;
  }

  function handleKeyPressed(event) {
    event.preventDefault();
    const code = event.keyCode;
    if (
      code === 9 ||
      code === 16 ||
      code === 17 ||
      code === 8 ||
      code === 13 ||
      code === 18 ||
      code === 91 ||
      code === 27 ||
      code === 20 ||
      code === 32 ||
      code === 46
    )
      return;

    const ctrlKey = hasCommandModifier(event);

    let playOrStop = "";

    if (event.target.name === "play") {
      playOrStop = "stop";
    } else if (event.target.name === "stop") {
      playOrStop = "play";
    }
    if (
      Object.keys(hotKeys).filter(
        (e) =>
          hotKeys[e].keyCode === code &&
          hotKeys[e].ctrlKey === ctrlKey &&
          e !== event.target.name &&
          e !== playOrStop
      ).length !== 0 ||
      evaluateSecondaryHotsKeys(event)
    ) {
      handleAlert("warning", "This key combination is already in use");
      return;
    }

    if (
      ctrlKey &&
      (code === 65 || code === 90 || code === 88 || code === 67 || code === 86)
    ) {
      handleAlert("warning", "keyboard shortcut is not supported");
      return;
    }

    handleHotKeys(event.target.name, {
      key: event.key,
      keyCode: code,
      ctrlKey: ctrlKey,
    });
  }

  const controlKey = value.ctrlKey ? (isMacOs ? "⌘ +" : "<Ctrl> +") : "";
  return (
    <TextField
      value={value.key!=""?`${controlKey} ${value.key}`:null}
      onKeyDown={handleKeyPressed}
      placeholder="Shortcut"
      name={name}
      key={name+key}
      variant="outlined"
      label={disableLabel ? "" : value.label}
      size="small"
      className="gridItem"
    />
  );
};

export default InputhotKey;
