import { React, useEffect, useState } from "react";
import Requests from "../logic/Requests";
import useShow from "../hooks/useShow";
import { Checkbox } from "@mui/material";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";

import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';


function SquarePayment(props) {
    const {amount,currency,afterPayment,filesObject,typeOfPay}=props; 
    const { show, open, close } = useShow();
    const [currentAlertState, setCurrentAlertState] = useState({
        type: "error",
        message: "",
    });
    const handleAlert = (type, message) => {
        setCurrentAlertState({
        type,
        message,
        });
        open();
    };

    return (
      <div className="squarePaymentContainercontainer">
        <CustomSnackBarAlert
          type={currentAlertState.type}
          show={show}
          close={close}
        >
          {currentAlertState.message}
        </CustomSnackBarAlert>
        <div className="squarePaymentContainercontainer">
          <PaymentForm
            applicationId="sandbox-sq0idb-TgDUJSP4IOGhQMRiJWvcXA"
            cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
              if (amount != 0) {
                try {
                  const paymentDetails = {
                    currency: currency ? currency : "USD",
                    verifiedBuyer,
                    amount: amount,
                    sourceId: token.token,
                  };
                  const paymentResponce = await Requests.squarePayment(
                    paymentDetails,
                    filesObject,
                    typeOfPay
                  );
                  afterPayment();
                  handleAlert("success", "Success payment!");
                } catch (error) {
                  console.log(error);
                  handleAlert(
                    "error",
                    "An error was encountered when preparing the payment form. Please try again later"
                  );
                }
              } else {
                handleAlert(
                  "error",
                  "There seems to be an error with the price, please try again."
                );
              }
            }}
            locationId="LPCH90B1W2E3V"
          >
            <CreditCard
              buttonProps={{
                text: "hola",
                css: {
                  backgroundColor: "#1A336E",
                  fontSize: "14px",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#0A142C",
                  },
                },
              }}
            />
          </PaymentForm>
        </div>

        <u
          className="credit"
          sx={{ ml: 1, mt: 3 }}
          control={<Checkbox size="small" />}
          label="Store credit card info for future orders"
        />
      </div>
    );};


export default SquarePayment;